
/**
 * 
 * @param {*} token String hexadecimal con el token del candado 4 bytes
 * @param {*} serie String hexadecimal con la serie del candado 3 bytes
 * @param {*} password String con la contraseña del candado 6 bytes
 * @returns String hexadecimal con el comando de apertura del candado
 */
export function codigoDeApertura(token, serie, password){
    //return "050106" + "010203040303" + token + serie
    //console.log(("050106" + password + token + serie).length)
    return ("050106" + password + token + serie)
} 
/**
 * 
 * @param {*} token String hexadecimal con el token del candado 4 bytes
 * @returns String hexadecimal con el comando de consulta de estado del candado
 */
export function codigoDeConsultaEstadoBloqueo(token){
    return "050E0101" + token + "0000000000000000"
}
/**
 * 
 * @param {*} token String hexadecimal con el token del candado 4 bytes
 * @returns String hexadecimal con el comando bloqueo activo del candado
 */
export function codigoBloqueoActivo(token){
    return "050C0100" + token + "0000000000000000"
}
/**
 * 
 * @param {*} token String hexadecimal con el token del candado 4 bytes
 * @returns String hexadecimal con el comando de consulta de bateria
 */
export function codigoConsultaBateria(token){
    return "02010101" + token + "000000000000000000000000000000"
}

/**
 * 
 * @param {*} dataHex String hexadecimal desencriptado con los datos de respuesta del candado 
 * @returns String hexadecimal con el token del candado 4 bytes
 */
export function parseToken(dataHex){
    return dataHex.split("").map( (t, i) => {if(i > 5 && i < 14) return t}).filter( t => t != undefined).join("");
}

export function parseBatery(dataHex){
    return dataHex.split("").map( (t, i) => {if(i > 5 && i < 8) return t}).filter( t => t != undefined).join("");
}

/**
 * 
 * @param {*} dataHex String hexadecimal desencriptado con los datos de respuesta del candado
 * @returns Booleano que indica si la respuesta del candado es un token
 */
export function isTokenResponse(dataHex){
    return (dataHex[0] == 0 && dataHex[1] == 6 && dataHex[2] == 0 && dataHex[3] == 2) ? true : false
}

/**
 * 
 * @param {*} dataHex String hexadecimal desencriptado con los datos de respuesta del candado
 * @returns Booleano que indica si la respuesta del candado es el estado luego del comando de apertura
 */
export function isOpenResponse(dataHex){
    return (dataHex[0] == 0 && dataHex[1] == 5 && dataHex[2] == 0 && dataHex[3] == 2) ? true : false
}

/**
 * 
 * @param {*} dataHex String hexadecimal desencriptado con los datos de respuesta del candado
 * @returns Booleano que indica si la respuesta del candado es de bateria
 */
export function isBateryResponse(dataHex){
    return (dataHex[0] == 0 && dataHex[1] == 2 && dataHex[2] == 0 && dataHex[3] == 2) ? true : false
}
