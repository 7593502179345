import { PADLOCK_COMMAND_GET_TOKEN, PADLOCK_ENCRYPTION_KEY_O35D22010012, PADLOCK_READ_CHARACTERISTIC_UUID, PADLOCK_SERVICE_UUID, PADLOCK_WRITE_CHARACTERISTIC_UUID } from './padlockConstants';
import { bytesToHex, hexToUint8Array } from './util'
import { aes128Decrypt, aes128Encrypt } from './aes128'
import { codigoDeApertura, isOpenResponse, isTokenResponse, parseToken } from './padlockCommands'

export async function connect(candado) {    // Función para conectar al dispositivo

    try {

        const filters = [
            { services: [PADLOCK_SERVICE_UUID] }, // Filtro por el servicio UUID (Padlock Service)
            // Puedes agregar más filtros según tus necesidades
        ];

        const options = {
            filters: filters,
        };

        const device = await navigator.bluetooth.requestDevice(options);

        const connection = await device.gatt.connect();

        const server = await connection.getPrimaryService(PADLOCK_SERVICE_UUID);

        return server

    } catch (error) {

        console.log(error)

        return null
    }

}

// export async function open(event) {
//     const value = event.target.value;
//     const dataUint8Array = new Uint8Array(value.buffer);
//     const dataHex = bytesToHex(dataUint8Array);
//     console.log("datos nuevo candado", dataHex)
//     const decryptedValue = this.encriptar ? aes128Decrypt(dataHex, PADLOCK_ENCRYPTION_KEY_O35D22010012) : dataHex;
//     console.log("Valor desencriptado:", decryptedValue);
//     if (isTokenResponse(decryptedValue) || decryptedValue === PADLOCK_COMMAND_GET_TOKEN || decryptedValue === "06010101") {
//         const tokenRecibido = parseToken(decryptedValue)
//         console.log("token recibido", tokenRecibido);
//         console.log(tokenRecibido)
//         const codigoApertura = this.encriptar ? aes128Encrypt(codigoDeApertura(tokenRecibido, "000000"), PADLOCK_ENCRYPTION_KEY_O35D22010012) : codigoDeApertura(tokenRecibido, "000000")
//         try {
//             const dataBuffer = hexToUint8Array(codigoApertura)
//             await this.characteristicWrite.writeValue(dataBuffer)
//             console.log("enviado")
//         } catch (error) {
//             console.log(error)
//         }
//     }
// }

// Función para conectar al dispositivo
export async function conectarDispositivo(candado) {
    try {
        return await connect(candado);
    } catch (error) {
        console.log("Error al conectar al dispositivo:", error);
        throw error;
    }
};

// Función para obtener las características de escritura y lectura
export async function obtenerCaracteristicas(device) {
    try {
        const characteristicWrite = await device.getCharacteristic(PADLOCK_WRITE_CHARACTERISTIC_UUID);
        const characteristicRead = await device.getCharacteristic(PADLOCK_READ_CHARACTERISTIC_UUID);
        return { characteristicWrite, characteristicRead };
    } catch (error) {
        throw new Error("Error al obtener las características: " + error);
    }
};

// Función para manejar los datos recibidos
export async function manejarDatosRecibidos(event, encriptar, characteristicWrite, candado) {
    const value = event.target.value;
    const dataUint8Array = new Uint8Array(value.buffer);
    const dataHex = bytesToHex(dataUint8Array);
    // console.log("Datos nuevo candado", dataHex);
    const decryptedValue = encriptar
        ? aes128Decrypt(dataHex, candado.key)
        : dataHex;
    // console.log("Valor desencriptado:", decryptedValue);
    if (isTokenResponse(decryptedValue) || decryptedValue === PADLOCK_COMMAND_GET_TOKEN || decryptedValue === "06010101") {
        const tokenRecibido = parseToken(decryptedValue);
        // console.log("Token recibido", tokenRecibido);
        // console.log(tokenRecibido);
        const codigoApertura = encriptar
            ? aes128Encrypt(codigoDeApertura(tokenRecibido, candado.serial, candado.password), candado.key)
            : codigoDeApertura(tokenRecibido, candado.serial, candado.password);
        try {
            const dataBuffer = hexToUint8Array(codigoApertura);
            await characteristicWrite.writeValue(dataBuffer);
            console.log("Enviado");
        } catch (error) {
            console.log("Error al enviar datos:", error);
        }
    }
};

// Función para enviar el comando de obtención de token
export async function enviarComandoToken(characteristicWrite, encriptar, candado) {
    const tokenCifrado = encriptar ? aes128Encrypt(PADLOCK_COMMAND_GET_TOKEN, candado.key) : PADLOCK_COMMAND_GET_TOKEN;
    try {
        const dataBuffer = hexToUint8Array(tokenCifrado);
        await characteristicWrite.writeValue(dataBuffer);
        console.log("Enviado");
    } catch (error) {
        throw new Error("Error al enviar token: " + error);
    }
};