import React from 'react';
import { connect } from 'react-redux';
import {Row,Col} from 'react-bootstrap';
import {isMobile,  isBrowser} from "react-device-detect";
import {Link} from "react-router-dom";
import { Role,tengoRol,history} from '../../_helpers';
import ReactTooltip from 'react-tooltip';


class NavBarLateral extends React.Component {

    constructor(props){
       super(props);
       this.toogleClasses = this.toogleClasses.bind(this);
       this.state = {active:true};
       this.state.active =!isMobile;
       this.wrapperRef = React.createRef();
        
       this.state.pathActual=history.location.pathname;

       history.listen((location, action) => {
            this.setState({pathActual:location.pathname});
       });
    }

    state={
        pathActual:''
    }
 

    toogleClasses(event){
        this.setState((prevState) => {
            return {active: !this.state.active};
        });
    }

    miClase = (link)=>{
        if(link===this.state.pathActual) return 'iconmenu'
        else return ''
    }

    handleClickOutside=(event)=> {
        if (this.wrapperRef && this.wrapperRef.current && !this.wrapperRef.current.contains(event.target)) 
            this.setState({active: false});        
    }

    componentDidMount(){
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

     
    render(){    
        const {user} = this.props;
        const soyAdmin=tengoRol([Role.ADMIN]);
        return (
          
            <nav ref={this.wrapperRef} className={this.state.active ? 'collapsed navbar-primary': 'navbar-primary'}>
                <div className="btn-expand-collapse item" onClick={(event) => this.toogleClasses(event)}>
                    <button className={this.state.active ? 'active flash hamburger hamburger-arrow-left': 'hamburger hamburger-arrow-left'}>
                        <span className="icon"></span>
                    </button>
                    <div className="user text-left d-block d-lg-none d-xl-none">
                        {user && <Row>
                            <Col xs={1} className="text-center mt-2"> <i className="fas fa-user-circle text-white"></i></Col>
                            <Col  xs={7} className="text-white">
                                <span className="font-weight-bold txt-22">{user.username}</span>
                                <h6 className="font-weight-light text-truncate">{user.adicionales?user.adicionales.dependencia?user.adicionales.dependencia.nombre:'':''}
                                </h6>
                            </Col>
            
                        </Row>}
                    
                      
                    </div>
                </div>
             
                <ul className="navbar-primary-menu">     

                <li className="mt-4">
                        <Link  data-tip data-for='homeMenu' to='/home/buscador' className={this.miClase('/home/buscador')}  onClick={(event) => (!isBrowser && this.toogleClasses(event))}>
                           
                            <span className="icon-Icon_DataVisualization_text_RGB txt-44 text-dark"></span>
                            <ReactTooltip place="bottom" id='homeMenu' aria-haspopup='false' >
                            <span>Dashboard</span>
                            </ReactTooltip>
                             <div className="nav-label ">Dashboard</div>
                        </Link>
                    </li> 
                    <li className="mt-4">
                        <Link  data-tip data-for='otMenu' to='/ordenestrabajo/buscador' className={this.miClase('/ordenestrabajo/buscador')}  onClick={(event) => (!isBrowser && this.toogleClasses(event))}>
                           
                            <span className="icon-Icon_Installation_commissioning_text_RGB txt-44 text-dark"></span>
                            <ReactTooltip place="bottom" id='otMenu' aria-haspopup='false' >
                            <span>Permiso de trabajo</span>
                            </ReactTooltip>
                             <div className="nav-label ">Permiso de trabajo</div>
                        </Link>
                    </li>            
                    <li className="mt-4">
                        <Link  data-tip data-for='userMenu' to='/usuario/buscador' className={this.miClase('/usuario/buscador')}  onClick={(event) => (!isBrowser && this.toogleClasses(event))}>
                           
                            <span className="icon-Icon_Client_man_text_RGB txt-44 text-dark"></span>
                            <ReactTooltip place="bottom" id='userMenu' aria-haspopup='false' >
                            <span>Usuarios</span>
                            </ReactTooltip>
                             <div className="nav-label ">Usuarios</div>
                        </Link>
                    </li>   
                     
                    <li className="mt-4">
                        <Link  data-tip data-for='sitioMenu' to='/sitio/buscador' className={this.miClase('/sitio/buscador')}  onClick={(event) => (!isBrowser && this.toogleClasses(event))}>
                        <span className="icon-Icon_Location_text_RGB txt-44 text-dark "></span>
                            <ReactTooltip place="bottom" id='sitioMenu' aria-haspopup='false' >
                            <span>Sitios</span>
                            </ReactTooltip>
                             <div className="nav-label ">Sitios</div>
                        </Link>
                    </li>    

                    <li className="mt-4">
                        <Link  data-tip data-for='equipoMenu' to='/equipo/buscador' className={this.miClase('/equipo/buscador')}  onClick={(event) => (!isBrowser && this.toogleClasses(event))}>
                            <span className="icon-Icon_Data_centers_text_RGB txt-44 text-dark"></span>
                            <ReactTooltip place="bottom" id='equipoMenu' aria-haspopup='false' >
                            <span>Equipos</span>
                            </ReactTooltip>
                             <div className="nav-label ">Equipos</div>
                        </Link>
                    </li>     

                    <li className="mt-4">
                    <Link to='/buscador' className={this.miClase('/buscador')} data-tip data-for='admini' onClick={(event) => (!isBrowser && this.toogleClasses(event))}>
                            
                            <span className="icon-Icon_Hazardous_energy_control_text_RGB txt-44 text-dark"></span>
                            <ReactTooltip place="bottom" id='admini' aria-haspopup='false' >
                            <span>Candados</span>
                            </ReactTooltip>
                             <div className="nav-label ">Candados</div>
                        </Link>
                    </li>
                </ul>
          </nav>
        );
    }
}

function mapState(state) {
    const {user  } = state.authentication;
    return {user  };
}


const actionCreators = {
}

const connectedNavBarLateral = connect(mapState, actionCreators)(NavBarLateral);
export { connectedNavBarLateral as NavBarLateral };