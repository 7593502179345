import React from 'react';
import { Router, Switch, Redirect, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { history } from '../_helpers';
import { alertActions, candadosActions, userActions } from '../_actions';
import { Alerta } from '../_components/Alerta';
import { PrivateRoute, LoginPage, LogoutPage, BuscadorCandado } from '../_components'
import 'bootstrap/dist/css/bootstrap.min.css';
import { NavBarLateral, NavBar } from '../_components/includes/';
import { BuscadorUsuario } from '../_components/usuario/BuscadorUsuario';
import { BuscadorSitio } from '../_components/sitio/BuscadorSitio';
import { BuscadorEquipo } from '../_components/equipo/BuscadorEquipo';
import { BuscadorOT } from '../_components/ordenestrabajo/BuscadorOT';
import { BuscadorHome } from '../_components/home/BuscadorHome';
import { equipoActions } from '../_actions/equipo.actions';
import { sitioActions } from '../_actions/sitio.actions';
import { otActions } from '../_actions/ot.actions';
const publicURL = process.env.PUBLIC_URL;

class App extends React.Component {
    constructor(props) {
        super(props);

        history.listen((location, action) => {
            this.props.clearAlerts();
        });
    }

    state = {
    };

    componentDidMount() {
        if (this.props.user !== undefined && this.props.userCandados === undefined || this.props.equipos === undefined || this.props.sitios === undefined || this.props.candados === undefined || this.props.ot === undefined) {
            this.props.getLogueado();
            this.props.traerEquipos();
            this.props.traerSitios();
            this.props.traerUsuarios();
            this.props.traerCandados();
            this.props.traerOts();
            this.props.traerEmpresasDeMantenimiento();
        }
    }

    render() {
        const { alert, user } = this.props;
        return (
            <>
                <Router history={history} basename={publicURL}>
                    <>
                        <NavBar />
                        <>
                            {(alert && alert.message) &&
                                <Alerta data={alert} />
                            }

                            {user && <NavBarLateral />}

                            <Switch>
                                <PrivateRoute exact path="/buscador" component={BuscadorCandado} />
                                <PrivateRoute exact path="/usuario/buscador" component={BuscadorUsuario} />
                                <PrivateRoute exact path="/sitio/buscador" component={BuscadorSitio} />
                                <PrivateRoute exact path="/equipo/buscador" component={BuscadorEquipo} />
                                <PrivateRoute exact path="/ordenestrabajo/buscador" component={BuscadorOT} />
                                <PrivateRoute exact path="/home/buscador" component={BuscadorHome} />
                                <Route exact path="/login" component={LoginPage} />
                                <Route exact path="/logout" component={LogoutPage} />
                                <Redirect from="*" to="/buscador" />
                            </Switch>
                        </>
                    </>
                </Router>

            </>

        );
    }
}

function mapState(state) {
    const { alert, authentication, users } = state;
    const { user } = authentication;
    const { userCandados } = users;
    const listadoDeEquipos = state.equipos.listado;
    return { alert, user, userCandados };
}

const actionCreators = {
    getLogueado: userActions.getLogueado,
    clearAlerts: alertActions.clear,
    traerEquipos: equipoActions.traerEquipos,
    traerSitios: sitioActions.traerSitios,
    traerUsuarios: userActions.traerUsuarios,
    traerCandados: candadosActions.traerListadoGral,
    traerOts: otActions.traerOT,
    traerEmpresasDeMantenimiento: otActions.traerEmpresasDeMantenimiento
};

const connectedApp = connect(mapState, actionCreators)(App);
export { connectedApp as App };