import { userConstants } from '../_constants';

let user = JSON.parse(localStorage.getItem('user'));
const initialState = {user:user};

export function authentication(state = initialState, action) {
  switch (action.type) {
    case userConstants.LOGIN_REQUEST:
      return {
        loggingIn: true
      };
    case userConstants.LOGIN_SUCCESS:
      return {
        loggingIn: false,
        loggedIn: true,
        user: action.user
      };

      case userConstants.CARGA_FINALIZADA:
      return { 
        ...state,
        user: {...state.user,
          cargaAbierta:action.data.data} 
        
      };      
    case userConstants.LOGIN_FAILURE:
      return {
        loggingIn: false
      };
    
      case userConstants.LOGOUT:
        return {};  
    default:
      return state
  }
}