import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { ordenDeTrabajoService } from '../../_services';
import { alertActions, otActions } from '../../_actions';
import { connect } from 'react-redux';

const empresaDeMantenimientoInicial = {
    nombre: "",
    descripcion: "",
}

class EmpresaDeMantenimientoDialogs extends React.Component {

    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
    }

    state = {
        confirmDialogOpen: false,
        nuevoEmpresaDeMantenimiento: empresaDeMantenimientoInicial,
        empresaDeMantenimientoDelete: null,
        camposVacios: [],
        alertaDeError: ''
    }

    handleInputChange = (event) => {
        const { name, value } = event.target;
        this.setState(prevState => ({
            nuevoEmpresaDeMantenimiento: {
                ...prevState.nuevoEmpresaDeMantenimiento,
                [name]: value
            }
        }));
    }
    closeConfirmDialog = () => {
        this.setState({
            confirmDialogOpen: false,
        });
    }
    handleDeleteConfirmation = () => {
        console.log("Eliminando", this.state);
        // sitioService.deleteSitio(this.props.empresaDeMantenimientoEditar.id)
        //     .then(() => {
        //         this.props.mensajeExito("Sitio eliminado correctamente");
        //         setTimeout(() => {
        //             window.location.reload()
        //         }, 1000);                
        //     })
        //     .catch(error => {
        //         this.props.mensajeError(error);
        //     });
        this.closeConfirmDialog();
        this.props.handleDialogClose();
    }

    handleSubmit = () => {

        const camposVacios = [];

        if (this.state.nuevoEmpresaDeMantenimiento.nombre === "") {
            camposVacios.push("Nombre");
        }

        this.setState({ camposVacios });

        if (camposVacios.length > 0) {
            const alertaError = `El Campo ${camposVacios.join(", ")} es requerido`;
            this.setState({ alertaDeError: alertaError });
            return;
        }

        console.log("Enviando", this.state.nuevoEmpresaDeMantenimiento); //no tiene los datos por default
        const nombre = this.props.empresaDeMantenimientoEditar.nombre
        if (this.state.nuevoEmpresaDeMantenimiento.nombre === "") {
            this.props.mensajeError("El nombre es requerido");
        }else{
        const reloadWindow = this.props.reloadWindow === undefined ? true : false;
        // this.props.empresaDeMantenimientoEditar.id
        //     ? sitioService.actualizarSitio(this.state.nuevoEmpresaDeMantenimiento, this.props.empresaDeMantenimientoEditar.id)
        //         .then(() => {
        //             this.props.mensajeExito("Sitio Actualizado correctamente");
        //             sitioService.subirImagen(id, this.state.nuevoEmpresaDeMantenimiento.imagen)
        //                 .then(() => {
        //                     this.props.mensajeExito("Imagen subida correctamente");
        //                 })
        //                 .catch(error => {
        //                     this.props.mensajeError(error);
        //                 });
        //             setTimeout(() => {
        //                 window.location.reload()
        //             }, 1000);
        //         })
        //         .catch(error => {
        //             this.props.mensajeError(error);
        //         })
             ordenDeTrabajoService.crearEmpresaDeMantenimiento(this.state.nuevoEmpresaDeMantenimiento)
                .then(() => {
                    this.props.mensajeExito("Empresa creada correctamente");
                    setTimeout(() => {
                        reloadWindow ? window.location.reload() : this.props.traerEmpresasDeMantenimiento();
                    }, 1000);
                })
                .catch(error => {
                    this.props.mensajeError(error);
                });
            }
        this.props.handleDialogClose();
    }

    openConfirmDialog = (empresaDeMantenimientoNombre) => {
        this.setState({
            confirmDialogOpen: true,
            empresaDeMantenimientoDelete: empresaDeMantenimientoNombre
        });
    }

    handleSelectChange = (name, selectedOption, isMulti = false) => {
        const selectedValues = isMulti
            ? selectedOption ? selectedOption.map(option => option.value) : []
            : selectedOption ? selectedOption.value : null;
        console.log('handleSelectChange:', name, selectedValues);  // Agrega esta línea
        this.setState(prevState => ({
            nuevoEmpresaDeMantenimiento: {
                ...prevState.nuevoEmpresaDeMantenimiento,
                [name]: selectedValues
            }
        }));
    }

    componentDidUpdate(prevProps) {
        if (this.props.empresaDeMantenimientoEditar !== prevProps.empresaDeMantenimientoEditar) {
            this.setState({
                nuevoEmpresaDeMantenimiento: {
                    ...this.state.nuevoEmpresaDeMantenimiento,
                    ...this.props.empresaDeMantenimientoEditar
                },
            });
        }
    }


    render() {

        const { empresaDeMantenimientoEditar, handleDialogClose, openDialog } = this.props; // datos de la ot que estan en el listado

        return (
            <>
                <Dialog open={openDialog} onClose={handleDialogClose} PaperProps={{
                    sx: {
                    width: "100%",
                    maxWidth: "920px!important",
                    },
                }} >
                    <DialogTitle>Empresa de mantenimiento</DialogTitle>
                    <DialogContent>
                        <TextField
                            color="secondary"
                            autoFocus
                            margin="dense"
                            name="nombre"
                            label="Nombre de la empresa"
                            type="text"
                            fullWidth
                            onChange={this.handleInputChange}
                            defaultValue={empresaDeMantenimientoEditar?.nombre}
                        />
                        <TextField
                            color="secondary"
                            autoFocus
                            margin="dense"
                            name="descripcion"
                            label="Descripcion de la empresa"
                            type="text"
                            fullWidth
                            onChange={this.handleInputChange}
                            defaultValue={empresaDeMantenimientoEditar?.descripcion}
                        />
                    </DialogContent>
                    <p className="text-danger">{this.state.alertaDeError}</p>
                    <DialogActions>
                        <Button onClick={handleDialogClose} color="primary">
                        <><span className="text-muted">Cancelar</span></>
                        </Button>
                        <Button onClick={this.handleSubmit} variant="contained" color="secondary" >
                            {empresaDeMantenimientoEditar?.id === undefined ? <><span className="text-white">Agregar</span></> : <><span className="text-white">Actualizar</span></>}
                        </Button>
                        {!(empresaDeMantenimientoEditar.id === undefined) && <Button onClick={() => this.openConfirmDialog(empresaDeMantenimientoEditar.id)} color="secondary" variant="outlined">
                        <><span className="text-secondary">Eliminar</span></> 
                        </Button>}
                    </DialogActions>
                </Dialog>
                <Dialog open={this.state.confirmDialogOpen} onClose={this.closeConfirmDialog}>
                    <DialogTitle>Confirmar Eliminación</DialogTitle>
                    <DialogContent>
                        ¿Estás seguro de que deseas eliminar esta empresa?
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.closeConfirmDialog} color="primary">
                            Cancelar
                        </Button>
                        <Button onClick={this.handleDeleteConfirmation} color="secondary">
                            Eliminar
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    }
}

function mapState(state) {
    const { user } = state.authentication;
    return { user };
}

const actionCreators = {
    mensajeError: alertActions.enviarError,
    mensajeExito: alertActions.enviarExito,
    traerEmpresasDeMantenimiento: otActions.traerEmpresasDeMantenimiento,
}

const connectedEmpresaDeMantenimientoDialogs = connect(mapState, actionCreators)(EmpresaDeMantenimientoDialogs);
export { connectedEmpresaDeMantenimientoDialogs as EmpresaDeMantenimientoDialogs };