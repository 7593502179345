import React from 'react';
import { connect } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import Select from 'react-select';
import { Col, Row, Container, Modal, Card } from 'react-bootstrap';
import { CApuedeAplicarBloqueoDigital, CApuedeEliminarBloqueoDigital, alertActions, buscarMiRolEnCandado, otActions, tengoRolMaximaPrioridad, traerPalabrasTabla, utilActions } from '../../_actions';
import MaterialTable from '@material-table/core';
import { ThemeProvider, createTheme } from '@mui/material';
import { CandadoDP, exportToCSV, getToken, IconLoto, MyToolTip, prepareDataSearch, prepareValuesToSearch, Role, tengoRol, TIPO_ACCION, TIPO_EXPORT, traerMisRoles } from '../../_helpers';
import { candadoService } from '../../_services';
import { buscadorConstants } from '../../_constants';
import SweetAlert from 'react-bootstrap-sweetalert';
import ReactTooltip from 'react-tooltip';
import LockIcon from '@mui/icons-material/LockOutlined';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import NoEncryptionIcon from '@mui/icons-material/NoEncryption';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { conectarDispositivo, obtenerCaracteristicas, manejarDatosRecibidos, enviarComandoToken } from '../../_helpers';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { Menu, MenuItem, IconButton } from '@material-ui/core';
import { CandadoDialogs } from './CandadoDialogs';
import { history } from '../../_helpers';
import ConfirmacionDialog from './ConfirmacionDialog';
import CandadoTutorialGalery from './CandadoTutorialGalery';
const tableRef3 = React.createRef();
const containerRef = React.createRef();
const bagRef = React.createRef();
const _ = require('lodash');
const useMediaQuery = window.matchMedia("(max-width: 600px)");


class BuscadorCandado extends React.Component {

    constructor(props) {
        super(props);
        this.state.nuevo = _.clone(buscadorConstants.infoTablaDefault);
        // this.handleSubmit = this.handleSubmit.bind(this);
        // this.handleInputChange = this.handleInputChange.bind(this);
        // this.handleSelectChange = this.handleSelectChange.bind(this);
    }

    state = {
        loading: false,
        nuevaBusqueda: false,
        show: false,
        candado: {},
        camposBusqueda: { values: {} },
        alert: undefined,
        openDialog: false,
        candadoToDelete: null,
        confirmDialogOpen: false,
        anchorEl: null,
        // nuevoCandado: candadoInicial,
        editarCandado: null,
        openImageDialog: false,
        image: null,
        imagePreviewUrl: '',
        postValues: null,
        showModal: false,
        ordenTrabajoData: null,
        showConfirmacionDialog: false,
        candadoSeleccionado: null,
        dialogAction: null,
        textDialogConfirm: '',
        titleDialogConfirm: '',
        candadosMobile: [],
    }

    cambiarEstado = (ids, nuevoEstado, desc) => {

        let values = {
            nuevoEstado: nuevoEstado,
            ids: ids
        }
        const getAlert = () => (
            <div className="">
                <SweetAlert
                    input
                    inputType={'textarea'}
                    showCancel={true}
                    showCloseButton={true}
                    cancelBtnText="Cancelar"
                    closeOnClickOutside={false}
                    confirmBtnText={"Aceptar"}
                    required={true}
                    validationMsg={"Es requerida la observación"}
                    title={"¿Esta seguro que desea llevar a estado " + desc + "?"}
                    onConfirm={(response) => this.props.cambiarEstado(values, this.handleExito, response, this.handleClose)}
                    onCancel={() => this.handleClose()}>
                    <h5 className="mt-2">Comentario</h5>

                </SweetAlert>
            </div>
        )
        this.setState({
            alert: getAlert()
        });

    }

    actualizar = () => {
        if (tableRef3.current) {
            tableRef3.current.onQueryChange();
        }
        if (useMediaQuery.matches) {
            this.buscarData({ pageSize: 100, filter: {} }, false)
                .then(res => this.setState({ candadosMobile: res.data }))
                .catch(error => this.props.enviarError(error));
        }
    }

    handleClose = () => {
        this.setState({
            show: false,
            candado: undefined,
            alert: undefined
        });
    }

    verMasInfo = (candado) => {
        this.setState({ show: true, candado: candado });
    }

    puedeEliminarBloqueoDigital = (candado) => {
        const { userCandados } = this.props;
        return CApuedeEliminarBloqueoDigital(candado, userCandados);
    }

    //Eventualmente en la misma accion de elimianr bloqueo digital por ahora se ejecuta la accion de abrir tmb. Si el rol lo admite.
    eliminarBloqueoDigital = (candado) => {
        console.log('Iniciando eliminarBloqueoDigital');
        const correspondeAbrir = candado.bloqueos.length === 1;
        const { userCandados } = this.props;
        let postValues = {
            rol: buscarMiRolEnCandado(candado, userCandados),
            candado: {
                id: candado.id
            },
            tipoAccion: correspondeAbrir ? TIPO_ACCION.APLICAR_ABIERTO : TIPO_ACCION.ELIMINAR_BLOQUEO_DIGITAL
        };

        this.ejecutarAccion(postValues);
        this.setState({ showConfirmacionDialog: false, candadoSeleccionado: null, dialogAction: null, textDialogConfirm: "", titleDialogConfirm: "" });
    }

    puedeAplicarBloqueoDigital = (candado) => {
        const { userCandados } = this.props;

        return CApuedeAplicarBloqueoDigital(candado, userCandados);
    }

    aplicarBloqueoDigital = (candado) => {
        console.log('Iniciando bloqueo digital');
        const { userCandados } = this.props;
        console.log('userCandados', userCandados);
        let postValues = {
            rol: buscarMiRolEnCandado(candado, userCandados),
            candado: {
                id: candado.id
            },
            tipoAccion: TIPO_ACCION.APLICAR_BLOQUEO_DIGITAL
        };

        let imagenSeteada = false;
        const nada = this.props.listadoDeOrdenesDeTrabajo?.map(c => {
            c.candadosOrdenDeTrabajo.map(candadoImage => {
                if (candadoImage?.candadoId == candado.id && candadoImage?.imagenPokaYoke !== null) {
                    imagenSeteada = true;
                }
            })
        });
        imagenSeteada
            ? this.ejecutarAccion(postValues)
            : this.setState({ openImageDialog: true, candadoIdImagen: candado.id, postValues: postValues });
        this.setState({ showConfirmacionDialog: false, candadoSeleccionado: null, dialogAction: null, textDialogConfirm: "", titleDialogConfirm: "" });
    }


    puedeIniciarCerrado = (candado) => {
        if (!candado.locked) {
            if (tengoRolMaximaPrioridad()) {
                return true;
            } else return false;
        } else {
            return false;
        }
    }

    iniciarCerrado = async (candado) => {
        const { userCandados } = this.props;
        console.log('iniciar cerrado');
        let postValues = {
            rol: buscarMiRolEnCandado(candado, userCandados),
            candado: {
                id: candado.id,
                nombre: candado.nombre
            },
            tipoAccion: TIPO_ACCION.APLICAR_CERRADO
        };
        let imagenSeteada = false;
        const nada = this.props.listadoDeOrdenesDeTrabajo?.map(c => {
            c.candadosOrdenDeTrabajo.map(candadoImage => {
                if (candadoImage?.candadoId == candado.id && candadoImage?.imagenPokaYoke !== null) {
                    imagenSeteada = true;
                }
            })
        });
        imagenSeteada
            ? this.ejecutarAccion(postValues)
            : this.setState({ openImageDialog: true, candadoIdImagen: candado.id, postValues: postValues });

        this.setState({ showConfirmacionDialog: false, candadoSeleccionado: null, dialogAction: null, textDialogConfirm: "", titleDialogConfirm: "" });
    }

    ejecutarAccion = (postValues) => {
        candadoService.ejecutarAccion(postValues)
            .then(
                data => {
                    this.actualizar();
                },
                error => {
                    this.props.enviarError(error);
                }
            );

        this.handleImageDialogClose();
    }

    abrirConfirmacionDialog = (candado) => {
        this.setState({ showConfirmacionDialog: true, candadoSeleccionado: candado, dialogAction: () => this.abrirCandado(candado), textDialogConfirm: <CandadoTutorialGalery>¿Estas seguro de que quieres abrir este candado?</CandadoTutorialGalery>, titleDialogConfirm: "Abrir Candado" });
    }

    cerrarConfirmacionDialog = () => {
        this.setState({ showConfirmacionDialog: false, candadoSeleccionado: null, dialogAction: null, textDialogConfirm: "", titleDialogConfirm: "" });
    }

    eliminarBloqueoDigitalDialog = (candado) => {
        this.setState({ showConfirmacionDialog: true, candadoSeleccionado: candado, dialogAction: () => this.eliminarBloqueoDigital(candado), textDialogConfirm: "¿Estás seguro que quieres eliminar este bloqueo digital?", titleDialogConfirm: "Eliminar Bloqueo Digital" });
    }

    aplicarBloqueoDigitalDialog = (candado) => {
        this.setState({ showConfirmacionDialog: true, candadoSeleccionado: candado, dialogAction: () => this.aplicarBloqueoDigital(candado), textDialogConfirm: "¿Estás seguro que quieres aplicar este bloqueo digital?", titleDialogConfirm: "Aplicar Bloqueo Digital" });
    }

    iniciarCerradoDialog = (candado) => {
        this.setState({ showConfirmacionDialog: true, candadoSeleccionado: candado, dialogAction: () => this.iniciarCerrado(candado), textDialogConfirm: "¿Estás seguro que quieres iniciar el bloqueo digital?", titleDialogConfirm: "Iniciar bloqueo digital" });
    }

    eliminarTodosLosBloqueosDialog = (candado) => {
        this.setState({ showConfirmacionDialog: true, candadoSeleccionado: candado, dialogAction: () => this.eliminarTodosLosBloqueos(candado), textDialogConfirm: "¿Estás seguro que quieres eliminar todos los bloqueos de este candado?", titleDialogConfirm: "Eliminar todos los bloqueos" });
    }

    componentDidMount() {

        if (useMediaQuery.matches) {
            this.buscarData({ pageSize: 100 }, false)
                .then(res => this.setState({ candadosMobile: res.data }))
                .catch(error => this.props.enviarError(error));
        }

        document.addEventListener('mousedown', this.handleClickOutside);
        this.props?.user === undefined && window.location.reload();
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside = (event) => {
        if (this.containerRef && !this.containerRef.current.contains(event.target)) {
            this.setState({ isOpen: false });
        }
    }

    buscarData = (query) => {
        return new Promise((resolve, reject) => {

            let values = this.state.values ? this.state.values : { estado: { id: '' }, lugar: { id: '' }, nombre: "" }

            if (this.state.nuevaBusqueda) query.page = 0;

            candadoService.buscar(prepareDataSearch(query, 'locked', values), this.props.user.id)
                .then(
                    data => {
                        this.setState({ nuevaBusqueda: false });
                        resolve({
                            data: data.content ? data.content : [],
                            page: query.page,
                            totalCount: data ? data.totalElements : 0
                        })
                    },
                    error => {
                        this.props.enviarError(error);
                    }
                );
        })
    }

    handleExportExcel = (fileName) => {
        this.buscarData(this.state.nuevo).then(
            data => {
                exportToCSV(utilActions.listaParaExport(data, TIPO_EXPORT.CANDADO), fileName)
            },
            error => {
                console.log("Error", error)
            }
        );
    }
    abrirCandado = async (candado, encriptar = true) => {
        try {
            const device = await conectarDispositivo(candado);
            console.log("Pude conectar")
            const { characteristicWrite, characteristicRead } = await obtenerCaracteristicas(device);
            console.log("Obtener caracteristicas OK")
            characteristicRead.startNotifications();
            console.log("Start notifications OK")
            characteristicRead.addEventListener('characteristicvaluechanged', async (event) => {
                await manejarDatosRecibidos(event, encriptar, characteristicWrite, candado);
            });
            console.log("Agregado listener OK")
            await enviarComandoToken(characteristicWrite, encriptar, candado);
            console.log("Enviado comando OK")
            this.props.enviarExito("Intentando abrir candado ...");
        } catch (error) {
            console.log("Error al abrir el candado:", error);
            this.props.enviarError("Error al abrir el candado, intente nuevamente");
        }
        this.setState({ showConfirmacionDialog: false, candadoSeleccionado: null, dialogAction: null, textDialogConfirm: "", titleDialogConfirm: "" });
    };

    handleDialogOpen = () => {
        this.setState({ openDialog: true });
    }

    handleDialogClose = () => {
        this.setState({ openDialog: false });
        //this.state.nuevoCandado = candadoInicial;
    }

    agregarCandado = () => {
        this.handleDialogOpen();
    }
    editarCandado = (candado) => {
        this.setState({
            editarCandado: {
                nombre: candado.nombre,
                locked: candado.locked,
                equipoId: candado.equipo.id,
                createdById: candado.createdById,
                estadoBateria: candado.estadoBateria,
                tiempoUso: candado.tiempoUso,
                mac: candado.mac,
                key: candado.key,
                password: candado.password,
                serial: candado.serial,
                rol: candado.rol,
                id: candado.id
            }
        })
        this.handleDialogOpen();
    }

    handleClickCrudMenu = (event) => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleCloseCrudMenu = () => {
        this.setState({ anchorEl: null });
    };



    soyAdmin = () => {
        const roles = traerMisRoles();
        return roles.filter(rol => rol === "ADMIN").length > 0;
    }

    eliminarTodosLosBloqueos = (candado) => {
        const { userCandados } = this.props;
        let postValues = {
            rol: "ADMIN",
            candado: {
                id: candado.id
            },
            tipoAccion: TIPO_ACCION.ELIMINAR_TODOS_BLOQUEOS_DIGITALES
        };
        console.log("post cerrar candado", postValues)
        this.ejecutarAccion(postValues);
        this.setState({ showConfirmacionDialog: false, candadoSeleccionado: null, dialogAction: null, textDialogConfirm: "", titleDialogConfirm: "" });
    }

    handleImageDialogClose = () => {
        this.setState({ openImageDialog: false });
    }

    handleImageUpload = (event) => {
        const file = event.target.files[0];
        if (file != null) {
            this.setState({
                image: file,
                imagePreviewUrl: URL.createObjectURL(file)
            });
            
        }
    }

    subirImagen = () => {
        const { image, candadoIdImagen, postValues } = this.state;
        let imagenSeteada = false;
        const nada = this.props.listadoDeOrdenesDeTrabajo?.candadosOrdenDeTrabajo?.map(candado => {
            if (candado.candadoId == candadoIdImagen && candado.imagenPokaYoke !== null) {
                imagenSeteada = true;
            }
        });
        //console.log("imagenSeteada", imagenSeteada)
        imagenSeteada
            ? this.ejecutarAccion(postValues)
            : candadoService.subirImagen(candadoIdImagen, image)
                .then(() => {
                    this.props.enviarExito("Imagen cargada correctamente");
                    this.ejecutarAccion(postValues)
                    this.actualizar();
                    this.props.traerOts();
                })
                .catch(error => {
                    this.props.enviarError(error);
                })
        this.handleImageDialogClose();
    }

    iniciarCerradoAhora = () => {
        const { postValues } = this.state;
        this.ejecutarAccion(postValues);
    }


    verInscripcion(row) {
        const ot = this.props.listadoDeOrdenesDeTrabajo?.filter(ot => ot.candados.includes(row.id))
        this.setState({ ordenTrabajoData: ot, showModal: true });
    }
    handleCloseModal = () => {
        this.setState({ showModal: false });
    }

    render() {

        const estados = [{ id: '', descripcion: 'Todos' }, { id: 'ABIERTO', descripcion: 'ABIERTO' }, { id: 'CERRADO', descripcion: 'CERRADO' }];
        const { loading } = this.state;

        const header = [
            { title: 'Nombre', field: 'nombre' },
            {
                title: 'Estado', hidden: useMediaQuery.matches,
                render: row =>
                    <>
                        {row.locked ? 'CON BLOQUEO DIGITAL' : 'SIN BLOQUEO DIGITAL'}
                    </>
            },
            // { title: 'Equipo', field: 'equipo.nombre', hidden: useMediaQuery.matches },
            { title: 'Tiempo Uso', field: 'tiempoUso', hidden: useMediaQuery.matches },
            { title: 'Estado Bateria', field: 'estadoBateria', hidden: useMediaQuery.matches },
            {
                title: '', cellStyle: { textAlign: 'right' },
                render: row =>
                    <div className="d-iflex text-right">
                        {this.puedeIniciarCerrado(row) && <button className="btns btns-link text-muted mr-0" type="button" onClick={() => this.abrirConfirmacionDialog(row)}>
                            <a data-tip data-for='bluetooth'>
                                <span className="material-icons material-icons-outlined txt-24">bluetooth</span>
                            </a>
                            <ReactTooltip place="bottom" id='bluetooth' aria-haspopup='false' >
                                <span>Abrir candado</span>
                            </ReactTooltip>
                        </button>}
                        <button className="btns btns-link text-muted mr-0" type="button" onClick={() => this.verInscripcion(row)}>
                            <a data-tip data-for='ot'>
                                <span className="material-icons material-icons-outlined txt-24">grading</span>
                            </a>
                            <ReactTooltip place="bottom" id='ot' aria-haspopup='false' >
                                <span>Permiso de Trabajo asignada</span>
                            </ReactTooltip>
                        </button>
                        <IconLoto tipo='icon-lock' size={23} />

                        {this.puedeEliminarBloqueoDigital(row) && !this.soyAdmin() && <button className="btns btns-link text-success mr-0" type="button" onClick={() => this.eliminarBloqueoDigitalDialog(row)}>
                            <LockIcon fontSize="large" data-tip data-for='eliminarBloqueo' />
                            <MyToolTip id='eliminarBloqueo' texto={row.bloqueos.length === 1 ? 'Eliminar Bloqueo Digital y ABRIR CANDADO' : 'Eliminar Bloqueo Digital'} />
                        </button>}
                        {this.puedeAplicarBloqueoDigital(row) && !this.soyAdmin() && <button className="btns btns-link text-dark mr-0" type="button" onClick={() => this.aplicarBloqueoDigitalDialog(row)}>
                            <LockIcon fontSize="large" data-tip data-for='aplicarBloqueo' />
                            <MyToolTip id='aplicarBloqueo' texto='Aplicar Bloqueo Digital' />
                        </button>}
                        {this.puedeIniciarCerrado(row) && !this.soyAdmin() && <button className="btns btns-link  text-danger mr-0" type="button" onClick={() => this.iniciarCerradoDialog(row)}>
                            <LockOpenIcon fontSize="large" data-tip data-for='iniciarCerrado' />
                            <MyToolTip id='iniciarCerrado' texto='Iniciar Bloqueo digital' />
                        </button>}
                        {this.soyAdmin() && <button className="btns btns-link  text-dark mr-0" type="button" onClick={() => this.eliminarTodosLosBloqueosDialog(row)}>
                            <NoEncryptionIcon fontSize="large" data-tip data-for='eliminarTodosLosBloqueos' />
                            <MyToolTip id='eliminarTodosLosBloqueos' texto='Eliminar todos los bloqueos' />
                        </button>}
                        {tengoRolMaximaPrioridad() && <button className="btns btns-link text-muted mr-0" type="button" onClick={() => this.editarCandado(row)}>
                            <a data-tip data-for='Actualizar-candado'>
                                <span className="material-icons material-icons-outlined txt-24">edit</span>
                            </a>
                            <ReactTooltip place="bottom" id='Actualizar-candado' aria-haspopup='false' >
                                <span>actualizar</span>
                            </ReactTooltip>
                        </button>}
                    </div>
            }
        ];

        const options = {
            search: true,
            sorting: true,
            fontSize: 6,
            filtering: false,
            selection: false,
            pageSize: this.state.nuevo.limit ? this.state.nuevo.limit : this.state.nuevo.infoTabla.limit,
            pageSizeOptions: [5, 10, 25, 30, 50, 75, 100],
            exportAllData: true,
            overflowX: 'auto',
            tableLayout: 'auto',
        }

        let inic = {
            lugar: { id: '' },
            nombre: '',
            estado: { id: '' }
        }

        const defaultMaterialTheme = createTheme();

        const isMobile = useMediaQuery.matches;

        return (
            <div className="main-content">
                <ConfirmacionDialog
                    open={this.state.showConfirmacionDialog}
                    onClose={this.cerrarConfirmacionDialog}
                    // onConfirm={() => this.abrirCandado(this.state.candadoSeleccionado)}
                    onConfirm={this.state.dialogAction}
                    // titulo="Abrir Candado"
                    titulo={this.state.titleDialogConfirm}
                    // mensaje="¿Estás seguro de que quieres abrir este candado?"
                    mensaje={this.state.textDialogConfirm}
                />
                <Modal show={this.state.showModal} onHide={this.handleCloseModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Información de los Permisos de trabajo</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.state.ordenTrabajoData?.length > 0 ? this.state.ordenTrabajoData.map((ordenTrabajo, index) => (
                            <div key={index}>
                                <h5>Permiso de Trabajo {index + 1}</h5>
                                <p><strong>Nombre:</strong> {ordenTrabajo.nombre}</p>
                                <p><strong>Descripción:</strong> {ordenTrabajo.descripcion}</p>
                                <p><strong>Equipo Intervenido:</strong> {ordenTrabajo.equipo_intervenido}</p>
                                <p><strong>Síntoma:</strong> {ordenTrabajo.sintoma}</p>
                                <p><strong>Causa:</strong> {ordenTrabajo.causa}</p>
                                <p><strong>Empresa:</strong> {ordenTrabajo.empresa}</p>
                                <p><strong>Riesgos:</strong> {ordenTrabajo.riesgos}</p>
                                <p><strong>Puntos de Bloqueo:</strong> {ordenTrabajo.puntosDeBloqueo}</p>
                                <p><strong>Fecha de Fin:</strong> {ordenTrabajo.fechaFin.split('T')[0]}</p>
                                <p><strong>Fecha de Inicio:</strong> {ordenTrabajo.fechaInicio.split('T')[0]}</p>
                                <hr />
                            </div>
                        ))
                            : <p>No hay Permisos de trabajo abiertas</p>
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btns btns-secondary" onClick={this.handleCloseModal}>Cerrar</button>
                    </Modal.Footer>
                </Modal>
                <Dialog open={this.state.openImageDialog} onClose={this.handleImageDialogClose}>
                    <DialogTitle>Cargar Foto de la ubicacion del candado</DialogTitle>
                    <DialogContent>
                        {this.state.imagePreviewUrl && (
                            <img src={this.state.imagePreviewUrl} alt="Imagen de previsualización" style={{ width: '100%', height: 'auto' }} />
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleImageDialogClose} color="primary">
                            CANCELAR
                        </Button>
                        <Button
                            variant="contained"
                            component="label"
                        >
                            CARGAR IMAGEN
                            <input
                                type="file"
                                hidden
                                onChange={this.handleImageUpload}
                            />
                        </Button>
                        <Button
                            variant="contained"
                            component="label"
                            className='ml-3'
                            onClick={ () => this.state.imagePreviewUrl !== '' ? this.subirImagen() : this.iniciarCerradoAhora()  }
                        >
                            {this.state.imagePreviewUrl !== '' ? "CONFIRMAR" : "CARGARLA LUEGO"}
                        </Button>
                    </DialogActions>
                </Dialog>
                <CandadoDialogs
                    openDialog={this.state.openDialog}
                    handleDialogClose={this.handleDialogClose}
                    editarCandado={this.state.editarCandado}
                    handleInputChange={this.handleInputChange}
                    handleSubmit={this.handleSubmit}
                    confirmDialogOpen={this.state.confirmDialogOpen}
                    closeConfirmDialog={this.closeConfirmDialog}
                    handleDeleteConfirmation={this.handleDeleteConfirmation}
                    handleSelectChange={this.handleSelectChange}
                    openConfirmDialog={this.openConfirmDialog}
                />
                <Container fluid>
                    <Row>
                        <Col xs={12} lg={12} className="mt-4">
                            <h2><span className="icon-Icon_Hazardous_energy_control_text_RGB txt-64 text-secundario"></span> Candados</h2>
                            {/* <h5 className="text-muted ml-5"><span className="icon-Icon_Permit_to_work_text_RGB txt-44 text-dark "></span> Listado</h5>                 */}
                        </Col>
                    </Row>
                    <div className="mt-5 th_secundario">
                        <Formik
                            enableReinitialize={true}
                            innerRef={bagRef}
                            initialValues={inic}
                            onSubmit={(values) => {
                                this.setState({
                                    values: values,
                                    nuevaBusqueda: true
                                }, this.actualizar);
                            }}>
                            {({ values, setFieldValue }) => (
                                <Form>
                                    <Row className="justify-content-center">
                                        <Col lg={6} xs={12}>
                                            <label htmlFor="nombre"> Nombre
                                            </label>
                                            <Field name='nombre' />
                                        </Col>
                                        <Col lg={6} xs={12}>
                                            <label htmlFor="estado"> Estado
                                            </label>
                                            <Select
                                                isClearable
                                                value={estados ? estados.find(option => option.id === values.estado.id) : ''}
                                                getOptionLabel={option => `${option.descripcion}`}
                                                getOptionValue={option => option.id}
                                                onChange={so => {
                                                    setFieldValue(`estado`, so.id);
                                                }}
                                                isSearchable={true}
                                                placeholder="Todos"
                                                options={estados}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="justify-content-center mb-4 mt-5">
                                        <Col xs={12} lg={3}>
                                            <button className="btn-block btns-primary btns" type="submit"><i className="fas fa-search"></i> Aplicar filtros y Buscar </button>
                                        </Col>
                                        <Col xs={12} lg={3}>
                                            {tengoRolMaximaPrioridad() && <button className="btn-block btns-info btns" onClick={this.agregarCandado}><i className="fas fa-plus"></i> Agregar Nuevo Candado </button>}
                                        </Col>
                                    </Row>
                                </Form>
                            )}
                        </Formik>
                        {/* <Row className="justify-content-center">
                            <Col xs={12} lg={3}>
                                {tengoRolMaximaPrioridad() && <button className="btn-block btns-primary btns" onClick={this.agregarCandado}><i className="fas fa-plus"></i> Agregar Nuevo Candado </button>}
                            </Col>
                        </Row> */}
                    </div>
                    <hr></hr>
                    <div className="th_cielo">
                        {!isMobile ? <Row className="mt-5 th_secundario Table-column-sticky-pp">
                            <Col>
                                <ThemeProvider theme={defaultMaterialTheme}>
                                    <MaterialTable
                                        title=''
                                        tableRef={tableRef3}
                                        columns={header}
                                        options={options}
                                        style={{
                                            tableLayout: 'auto',  // Añadir esta línea
                                            width: '100%',  // Añadir esta línea
                                            maxWidth: '100%',  // Añadir esta línea
                                        }}
                                        data={query =>
                                            this.buscarData(query, false)
                                        }
                                        actions={[
                                            {
                                                icon: () => <>
                                                    <span className={loading ? 'd-none ' : 'material-icons'}>cloud_download</span>
                                                </>,
                                                onClick: () => !loading ? this.handleExportExcel("descarga.xlsx") : '',
                                                isFreeAction: true
                                            }

                                        ]}
                                        detailPanel={rowData => {
                                            return (
                                                <CandadoDP rowData={rowData} listadoDeOrdenesDeTrabajo={this.props.listadoDeOrdenesDeTrabajo}/>
                                            )
                                        }}
                                        localization={traerPalabrasTabla('Candado')}
                                        onRowClick={(event, rowData, togglePanel) => togglePanel()}
                                    />
                                </ThemeProvider>
                            </Col>
                        </Row> : <Row>
                            {
                                this.state.candadosMobile.length > 0 ? this.state.candadosMobile.map((candado, index) => (
                                    <Col xs={6} key={index} className="pt-3">
                                        <Card className="card-components-sm shadow">
                                            <Card.Body>
                                                <Card.Title><span className={this.puedeIniciarCerrado(candado) ? "ErrorCounterNo" : "ErrorCounter"}></span> <span className="">{candado.nombre}</span></Card.Title>

                                                {this.soyAdmin() && <div className='xs-candados text-center mt-4' type="button" onClick={() => this.eliminarTodosLosBloqueosDialog(candado)}>
                                                    <NoEncryptionIcon data-tip data-for='eliminarTodosLosBloqueos' />
                                                    <MyToolTip id='eliminarTodosLosBloqueos' texto='Eliminar todos los bloqueos' />
                                                </div>}
                                                {this.puedeEliminarBloqueoDigital(candado) && !this.soyAdmin() && <div className='xs-candados text-center mt-4 text-success' type="button" onClick={() => this.eliminarBloqueoDigitalDialog(candado)}>
                                                    <LockIcon fontSize="large" data-tip data-for='eliminarBloqueo' />
                                                    <MyToolTip id='eliminarBloqueo' texto={candado.bloqueos.length === 1 ? 'Eliminar Bloqueo Digital y ABRIR CANDADO' : 'Eliminar Bloqueo Digital'} />
                                                </div>}
                                                {this.puedeAplicarBloqueoDigital(candado) && !this.soyAdmin() && <div className='xs-candados text-center mt-4' type="button" onClick={() => this.aplicarBloqueoDigitalDialog(candado)}>
                                                    <LockIcon fontSize="large" data-tip data-for='aplicarBloqueo' />
                                                    <MyToolTip id='aplicarBloqueo' texto='Aplicar Bloqueo Digital' />
                                                </div>}
                                                {this.puedeIniciarCerrado(candado) && !this.soyAdmin() && <div className='xs-candados text-center mt-4 text-danger' type="button" onClick={() => this.iniciarCerradoDialog(candado)}>
                                                    <LockOpenIcon fontSize="large" data-tip data-for='iniciarCerrado' />
                                                    <MyToolTip id='iniciarCerrado' texto='Iniciar Bloqueo' />
                                                </div>}
                                                {!this.puedeEliminarBloqueoDigital(candado) && !this.puedeIniciarCerrado(candado) && !this.puedeAplicarBloqueoDigital(candado) && !this.soyAdmin() && <div className='xs-candados text-center text-muted mt-4'>
                                                    <LockIcon fontSize="large" data-tip data-for='default' />
                                                    <MyToolTip id='default' texto="No se puede iniciar cerrado" />
                                                </div>}
                                            </Card.Body>
                                            <Card.Footer className="text-muted text-center">
                                                {this.puedeIniciarCerrado(candado) && <button className="btns btns-link text-muted mr-0" type="button" onClick={() => this.abrirConfirmacionDialog(candado)}>
                                                    <a data-tip data-for='bluetooth'>
                                                        <span className="material-icons material-icons-outlined txt-24">bluetooth</span>
                                                    </a>
                                                    <ReactTooltip place="bottom" id='bluetooth' aria-haspopup='false' >
                                                        <span>Abrir candado</span>
                                                    </ReactTooltip>
                                                </button>}
                                                <button className="btns btns-link text-muted mr-0" type="button" onClick={() => this.verInscripcion(candado)}>
                                                    <a data-tip data-for='ot'>
                                                        <span className="material-icons material-icons-outlined txt-24">grading</span>
                                                    </a>
                                                    <ReactTooltip place="bottom" id='ot' aria-haspopup='false' >
                                                        <span>Permiso de Trabajo asignada</span>
                                                    </ReactTooltip>
                                                </button>
                                                {tengoRolMaximaPrioridad() && <button className="btns btns-link text-muted mr-0" type="button" onClick={() => this.editarCandado(candado)}>
                                                    <a data-tip data-for='Actualizar-candado'>
                                                        <span className="material-icons material-icons-outlined txt-24">edit</span>
                                                    </a>
                                                    <ReactTooltip place="bottom" id='Actualizar-candado' aria-haspopup='false' >
                                                        <span>actualizar</span>
                                                    </ReactTooltip>
                                                </button>}
                                            </Card.Footer>
                                        </Card>
                                    </Col>
                                ))
                                    : <Col xs={12} className="text-center">
                                        <h3>No hay candados encontrados</h3>
                                    </Col>
                            }
                        </Row>}
                    </div>
                </Container>
                {this.state.alert}
            </div>
        );
    }
}

function mapState(state) {

    const { user } = state.authentication;
    const { userCandados } = state.users;
    const listadoDeOrdenesDeTrabajo = state.ot.listado;
    const listadoDeEquipos = state.equipos.listado;
    return { user, userCandados, listadoDeOrdenesDeTrabajo, listadoDeEquipos };
}

const actionCreators = {
    enviarError: alertActions.enviarError,
    enviarExito: alertActions.enviarExito,
    traerOts: otActions.traerOT,
}

const connectedBuscadorCandado = connect(mapState, actionCreators)(BuscadorCandado);
export { connectedBuscadorCandado as BuscadorCandado };