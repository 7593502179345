import React from 'react';
import { connect } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import Select from 'react-select';
import { Col, Row, Container, Modal } from 'react-bootstrap';
import { alertActions, tengoRolMaximaPrioridad, traerPalabrasTabla, utilActions } from '../../_actions';
import MaterialTable from '@material-table/core';
import { ThemeProvider, createTheme } from '@mui/material';
import { exportToCSV, Role, SitioDP, tengoRol, TIPO_EXPORT } from '../../_helpers';
import { sitioService } from '../../_services';
import { buscadorConstants } from '../../_constants';
import ReactTooltip from 'react-tooltip';
import { ClockLoader } from 'react-spinners';
import {SitioDialogs} from './SitioDialogs';

const tableRef3 = React.createRef();
const bagRef = React.createRef();
const _ = require('lodash');

class BuscadorSitio extends React.Component {

    constructor(props) {
        super(props);
        this.state.nuevo = _.clone(buscadorConstants.infoTablaDefault);
    }

    state = {
        loading: false,
        nuevaBusqueda: false,
        sitio: {},
        seleccionados: [],
        openDialog: false,
        sitioEditar: {},      
        showModal: false,
        ordenTrabajoData: null,          
    }


    actualizar = () => {
        if (tableRef3.current) {
            tableRef3.current.state.query.page = 0;
            tableRef3.current.dataManager.currentPage = 0;
            tableRef3.current.onQueryChange();
        }
    }

    handleClose = () => {
        this.setState((prevState) => {
            return {
                sitio: {},
                listado: undefined,
                alert: undefined
            }
        });
    }

    componentDidMount() {
    }

    buscarData = (query) => {
        return new Promise((resolve, reject) => {

            if (this.state.nuevaBusqueda) query.page = 0

            let dataSearch = {
                searchValue: query.search,
                page: query.page,
                pageSize: query.pageSize,
                sortOrders: [
                    {
                        field: query.orderBy ? query.orderBy : 'nombre',
                        direction: query.orderDirection ? query.orderDirection : 'ASC'
                    }
                ],
                options: this.state.values
            };

            sitioService.buscar(dataSearch)
                .then(
                    data => {
                        this.setState({ nuevaBusqueda: false });
                        resolve({
                            pageSize: query.pageSize,
                            data: data.content ? data.content : [],
                            page: query.page,
                            totalCount: data ? data.totalElements : 0
                        })
                    },
                    error => {
                        this.props.enviarError(error);
                    }
                );
        })
    }

    handleExportExcel(fileName) {
        this.buscarData(this.state.nuevo, true).then(
            data => {
                exportToCSV(utilActions.listaParaExport(data, TIPO_EXPORT.SITIO), fileName)
            },
            error => {
                console.log("Error", error)
            }
        );
    }

    exportarPDF = () => {
        let lookupquery = _.clone(this.state.nuevo);
        lookupquery.orderBy = tableRef3.current.state.query.orderBy;
        lookupquery.orderDirection = tableRef3.current.state.query.orderDirection;

        this.buscarData(lookupquery, true).then(
            data => {
                this.setState(() => {
                    return { showPDF: true, data: data };
                });
            },
            error => {
                console.log("Error", error)
            }
        );
    }

    handleExportPDF(filename) {
        this.setState({
            camposBusqueda: {
                values: bagRef.current.values
            }

        }, this.exportarPDF);
    }

    handleDialogOpen = () => {
        this.setState({ openDialog: true });
    }

    handleDialogClose = () => {
        this.setState({ openDialog: false, sitioEditar: {} });
    }

    agregarSitio = () => {
        this.handleDialogOpen();
    }

    editarSitio = (sitio) => {
        this.setState({
            sitioEditar: {
                id: sitio.id,
                nombre: sitio.nombre,
                cliente: sitio.cliente,
                fechaInicio: sitio.fechaInicio?.split('T')[0] || new Date().toISOString().split('T')[0],
                imagen: sitio.imagen,
            }
        })
        this.handleDialogOpen();
    }

    verInscripcion(row) {
        console.log("row", row)
        const ot = this.props.listadoDeOrdenesDeTrabajo?.filter(ot => ot.sitioId === row.id)
        console.log("ot", ot)
        this.setState({ ordenTrabajoData: ot, showModal: true });
    }
    handleCloseModal = () => {
        this.setState({ showModal: false });
    }

    render() {

        const { loading } = this.state;

        const soyAdmin = tengoRol([Role.ADMIN]);

        const header = [
            { title: 'Nombre', field: 'nombre' },
            {
                title: '', cellStyle: { textAlign: 'right' },
                render: row =>
                    <div className="d-iflex text-right">
                        <button className="btns btns-link text-muted mr-0" type="button" onClick={() => this.verInscripcion(row)}>
                            <a data-tip data-for='ot'>
                                <span className="material-icons material-icons-outlined txt-24">grading</span>
                            </a>
                            <ReactTooltip place="bottom" id='ot' aria-haspopup='false' >
                                <span>Permiso de Trabajo asignada</span>
                            </ReactTooltip>
                        </button>
                        {tengoRolMaximaPrioridad() && <button className="btns btns-link text-muted mr-0" type="button" onClick={() => this.editarSitio(row)}>
                            <a data-tip data-for='Actualizar-sitio'>
                                <span className="material-icons material-icons-outlined txt-24">edit</span>
                            </a>
                            <ReactTooltip place="bottom" id='Actualizar-sitio' aria-haspopup='false' >
                                <span>actualizar</span>
                            </ReactTooltip>
                        </button>}                         
                    </div>
            }
        ];

        const options = {
            search: true,
            sorting: true,
            fontSize: 6,
            pageSize: this.state.nuevo.limit ? this.state.nuevo.limit : this.state.nuevo.infoTabla.limit,
            filtering: false,
            selection: false,
            pageSizeOptions: [5, 10, 25, 30, 50, 75, 100],
            exportAllData: true
        }

        const defaultMaterialTheme = createTheme();

        let inic = {
            nombre: ''
        }
        return (
            <div className="main-content">
                <SitioDialogs 
                    openDialog={this.state.openDialog}
                    handleDialogClose={this.handleDialogClose}
                    sitioEditar={this.state.sitioEditar}
                    listadoDeSitios={this.state.listado}
                    mensajeExito={this.props.enviarExito}
                    mensajeError={this.props.enviarError}
                    />
                <Modal show={this.state.showModal} onHide={this.handleCloseModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Información de los Permisos de trabajo</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.state.ordenTrabajoData?.length > 0 ? this.state.ordenTrabajoData.map((ordenTrabajo, index) => (
                            <div key={index}>
                                <h5>Permiso de Trabajo {index + 1}</h5>
                                <p><strong>Nombre:</strong> {ordenTrabajo.nombre}</p>
                                <p><strong>Descripción:</strong> {ordenTrabajo.descripcion}</p>
                                <p><strong>Equipo Intervenido:</strong> {ordenTrabajo.equipo_intervenido}</p>
                                <p><strong>Síntoma:</strong> {ordenTrabajo.sintoma}</p>
                                <p><strong>Causa:</strong> {ordenTrabajo.causa}</p>
                                <p><strong>Empresa:</strong> {ordenTrabajo.empresa}</p>
                                <p><strong>Riesgos:</strong> {ordenTrabajo.riesgos}</p>
                                <p><strong>Puntos de Bloqueo:</strong> {ordenTrabajo.puntosDeBloqueo}</p>
                                <p><strong>Fecha de Fin:</strong> {ordenTrabajo.fechaFin.split('T')[0]}</p>
                                <p><strong>Fecha de Inicio:</strong> {ordenTrabajo.fechaInicio.split('T')[0]}</p>
                                <hr />
                            </div>
                        ))
                            : <p>No hay Permisos de trabajo abiertas</p>
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btns btns-secondary" onClick={this.handleCloseModal}>Cerrar</button>
                    </Modal.Footer>
                </Modal>                    
                <Container fluid>
                    <Row>
                        <Col xs={12} lg={12} className="mt-4">
                            <h2><span className="icon-Icon_Location_text_RGB txt-64 text-secundario"></span> Sitios</h2>
                            {/* <h5 className="text-muted ml-5"><i className="fas fa-list-ul text-muted"></i> Listado</h5>                 */}
                        </Col>
                    </Row>
                    <div className="mt-5 th_cielo">
                        <Formik
                            enableReinitialize={true}
                            innerRef={bagRef}
                            initialValues={inic}
                            onSubmit={(values) => {
                                this.setState({
                                    values: values,
                                    nuevaBusqueda: true
                                }, this.actualizar);
                            }}>
                            {({ values, setFieldValue }) => (
                                <Form>
                                    <Row>
                                        <Col lg={12} xs={12}>
                                            <label htmlFor="nombre"> Nombre
                                            </label>
                                            <Field name='nombre' />
                                        </Col>

                                    </Row>
                                    <Row className="justify-content-center mb-4 mt-5">
                                        <Col xs={12} lg={3}>
                                            <button className="btn-block btns-primary btns" type="submit"><i className="fas fa-search"></i> Aplicar filtros y Buscar </button>
                                        </Col>
                                        <Col xs={12} lg={3}>
                                            {tengoRolMaximaPrioridad() && <button className="btn-block btns-info btns" onClick={this.agregarSitio}><i className="fas fa-plus"></i> Agregar Sitio </button>}
                                        </Col>
                                    </Row>
                                </Form>

                            )}
                        </Formik>
                        {/* <Row className="justify-content-center">
                            <Col xs={12} lg={3}>
                                {tengoRolMaximaPrioridad() && <button className="btn-block btns-primary btns" onClick={this.agregarSitio}><i className="fas fa-plus"></i> Agregar Sitio </button>}
                            </Col>
                        </Row> */}
                    </div>
                    <hr></hr>
                    <div className="th_cielo">
                        <Row className="mt-5 th_info Table-column-sticky-pp">
                            <Col>
                                <ThemeProvider theme={defaultMaterialTheme}>
                                    <MaterialTable
                                        title=''
                                        tableRef={tableRef3}
                                        columns={header}
                                        options={options}
                                        data={query =>
                                            this.buscarData(query, false)
                                        }
                                        actions={[
                                            {
                                                icon: () => <>
                                                    <button data-tip data-for='excel' disabled={loading} className="btns btn-csv-exp btns-recursos">
                                                        <span className={loading ? 'd-none ' : 'material-icons'}>cloud_download</span>
                                                        <div className="ml-1 sweet-loading">
                                                            <ClockLoader
                                                                size={25}
                                                                color={"#FFF"}
                                                                loading={loading}
                                                            />
                                                        </div>
                                                    </button>
                                                    <ReactTooltip place="bottom" id='excel' aria-haspopup='false' >
                                                        <span>{loading ? 'Descargando... ' : 'Exportar a Excel'}</span>
                                                    </ReactTooltip>
                                                </>,
                                                onClick: () => !loading ? this.handleExportExcel("sitios.xlsx") : '',
                                                isFreeAction: true
                                            }

                                        ]}
                                        localization={traerPalabrasTabla('Sitio')}
                                        detailPanel={rowData => {
                                            return (
                                                <SitioDP rowData={rowData} />
                                            )
                                        }}
                                        onRowClick={(event, rowData, togglePanel) => togglePanel()}
                                    />
                                </ThemeProvider>
                            </Col>
                        </Row>
                    </div>

                </Container>
            </div>
        );
    }
}

function mapState(state) {

    const { user } = state.authentication;
    const listadoDeSitios = state.sitios.listado;
    const listadoDeOrdenesDeTrabajo = state.ot.listado;
    return { user, listadoDeSitios,listadoDeOrdenesDeTrabajo };
}

const actionCreators = {
    enviarError: alertActions.enviarError,
    enviarExito: alertActions.enviarExito,
}

const connectedBuscadorSitio = connect(mapState, actionCreators)(BuscadorSitio);
export { connectedBuscadorSitio as BuscadorSitio };