import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Select from 'react-select';
import { equipoService } from '../../_services';
import { RIESGOS } from '../../_constants/riesgos.constrans'
import { history } from '../../_helpers';
import { createTheme } from '@mui/material/styles';
import { MuiThemeProvider, Theme } from '@material-ui/core/styles';
import { Button, List, ListItem, ListItemText, ListItemSecondaryAction, IconButton } from '@mui/material';
import { Card, CardContent, CardActions, Typography } from '@mui/material';
import { alertActions } from '../../_actions';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import NoEncryptionGmailerrorredIcon from '@mui/icons-material/NoEncryptionGmailerrorred';
import ReactTooltip from 'react-tooltip';
import { connect } from 'react-redux';
import { equipoActions } from '../../_actions/equipo.actions';
const equipoInicial = {
    nombre: "",
    peligros: [],
    epp: "",
    sitio: {
        id: 0,
        nombre: ""
    }
}

class EquipoDialogs extends React.Component {

    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.handleAddEquipo = this.handleAddEquipo.bind(this);
        this.handleRemoveEquipo = this.handleRemoveEquipo.bind(this);
        this.handleRemovePuntoDeBloqueo = this.handleRemovePuntoDeBloqueo.bind(this);
        this.toggleDialogAbloquear = this.toggleDialogAbloquear.bind(this);
        this.toggleDialogPuntoBloqueo = this.toggleDialogPuntoBloqueo.bind(this);
        this.handleConfirmAddPuntoDeBloqueo = this.handleConfirmAddPuntoDeBloqueo.bind(this);
        this.handleConfirmAddEquipo = this.handleConfirmAddEquipo.bind(this);
    }

    state = {
        confirmDialogOpen: false,
        nuevoEquipo: equipoInicial,
        EquipoDelete: null,
        EquipoBloquearEditIndex: null,
        PuntoDeBloqueoEditIndex: null,
        equiposAbloquear: [],
        equipoNombrePuntoBloqueo: "",
        puntosDeBloqueo: [],
        puntoDeBloqueo: [],
        dialogABloquear: false,
        dialogPuntoBloqueo: false,
        camposVacios: [],
        alertaDeError: ''
    }

    handleInputChange = (event) => {
        const { name, value } = event.target;
        this.setState(prevState => ({
            nuevoEquipo: {
                ...prevState.nuevoEquipo,
                [name]: value
            },
            camposVacios: prevState.camposVacios.filter(campo => campo !== name)
        }));
    }

    handleInputChangePuntoDeBloqueo = (event) => {
        const { name, value } = event.target;
        this.setState(prevState => ({
            puntoDeBloqueo: {
                ...prevState.puntoDeBloqueo,
                [name]: value
            }
        }));
    }

    closeConfirmDialog = () => {
        this.setState({
            confirmDialogOpen: false,
        });
    }
    handleDeleteConfirmation = () => {
        console.log("Eliminando", this.state);
        equipoService.eliminarEquipo(this.state.EquipoDelete)
            .then(() => {
                this.props.mensajeExito("Equipo eliminado correctamente");
                setTimeout(() => {
                    window.location.reload()
                }, 1000);
            })
            .catch(error => {
                this.props.mensajeError(error);
            });
        this.closeConfirmDialog();
        this.props.handleDialogClose();
    }

    handleSubmit = () => {

        const equipoActualizado = {
            ...this.state.nuevoEquipo,
            equiposAbloquear: this.state.equiposAbloquear
        };

        const camposVacios = [];

        if (!equipoActualizado.nombre) {
            camposVacios.push("Nombre");
        }
        if (!equipoActualizado.peligros) {
            camposVacios.push("Peligros");
        }
        if (!equipoActualizado.epp) {
            camposVacios.push("EPP");
        }
        if (equipoActualizado.sitio.id == 0) {
            camposVacios.push("Sitio");
        }
        if (equipoActualizado.equiposAbloquear?.length === 0) {
            camposVacios.push("Equipos a bloquear");
        }

        this.setState({ camposVacios });

        if (camposVacios.length > 0) {
            const alertaError = `Los campos ${camposVacios.join(", ")} son requeridos`;
            this.setState({ alertaDeError: alertaError });
            return;
        }

        if (this.props.equipoEditar.id) {
            equipoService.editarEquipo(equipoActualizado, this.props.equipoEditar.id)
                .then(() => {
                    this.props.mensajeExito("Equipo Actualizado correctamente");
                    setTimeout(() => {
                        window.location.reload();
                    }, 1000);
                })
                .catch(error => {
                    this.props.mensajeError(error);
                });
        } else {
            equipoService.agregarEquipo(equipoActualizado)
                .then(() => {
                    this.props.mensajeExito("Equipo creado correctamente");
                    setTimeout(() => {
                        this.props.reloadWindow === undefined ? window.location.reload() : this.props.traerEquipos();
                    }, 1000);
                })
                .catch(error => {
                    this.props.mensajeError(error);
                });
        }
        this.setState({ equiposAbloquear: [] })
        this.props.handleDialogClose();
    };


    openConfirmDialog = (equipoId) => {
        this.setState({
            confirmDialogOpen: true,
            EquipoDelete: equipoId
        });
    }

    handleSelectChange = (name, selectedOption, isMulti = false) => {
        const selectedValues = isMulti
            ? selectedOption ? selectedOption.map(option => option.value) : []
            : selectedOption ? selectedOption.value : null;

        this.setState(prevState => ({
            nuevoEquipo: {
                ...prevState.nuevoEquipo,
                [name]: selectedValues
            }
        }));
    }

    componentDidUpdate(prevProps) {
        if (this.props.equipoEditar !== prevProps.equipoEditar) {
            const equiposAbloquearDelListado = this.props.listadoDeEquipos?.filter(equipo => equipo.id === this.props.equipoEditar.id)[0]?.equiposAbloquear
            this.setState({
                nuevoEquipo: {
                    ...this.state.nuevoEquipo,
                    ...this.props.equipoEditar
                }
            });
            this.setState({
                equiposAbloquear: equiposAbloquearDelListado
            })
        }
    }
    componentWillUnmount() {
        this.setState({
            nuevoEquipo: equipoInicial,
            equiposAbloquear: [],
            equipoNombrePuntoBloqueo: "",
            puntosDeBloqueo: [],
            puntoDeBloqueo: [],
            dialogABloquear: false,
            dialogPuntoBloqueo: false,
            equipo: null,
        })
    }
    handleAddEquipo(equipoData) {
        // Lógica para añadir un nuevo equipo al estado
    }

    handleRemoveEquipo(equipoIndex) {
        // Lógica para eliminar un equipo del estado
    }

    handleRemovePuntoDeBloqueo(indexPunto) {
        this.setState(prevState => prevState.puntosDeBloqueo.splice(indexPunto, 1))
    }

    handleRemoveEquipoBloqueo(indexEquipoAbloquear) {
        // console.log("index equipo a bloquear", indexEquipoAbloquear)
        this.setState(prevState => prevState.equiposAbloquear.splice(indexEquipoAbloquear, 1))
    }

    toggleDialogAbloquear = () => {
        this.setState(prevState => ({
            dialogABloquear: !prevState.dialogABloquear,
            //puntoDeBloqueo: [],
            PuntoDeBloqueoEditIndex: null,
            EquipoBloquearEditIndex: null,
            equipoNombrePuntoBloqueo: "",
            puntosDeBloqueo: [],

        }));
    }
    toggleDialogAbloquearEdit(index) {
        const nombreEquipo = this.state.equiposAbloquear[index]?.nombre || '';
        const puntosDeBloqueo = this.state.equiposAbloquear[index]?.puntosDeBloqueo || [];
        this.setState(prevState => ({
            dialogABloquear: !prevState.dialogABloquear,
            equipoNombrePuntoBloqueo: nombreEquipo,
            puntosDeBloqueo: puntosDeBloqueo,
            EquipoBloquearEditIndex: index
        }));
    }

    toggleDialogPuntoBloqueo() {
        this.setState(prevState => ({
            dialogPuntoBloqueo: !prevState.dialogPuntoBloqueo,
            PuntoDeBloqueoEditIndex: null,
            //EquipoBloquearEditIndex: null,
            //equipoNombrePuntoBloqueo: "",
            //puntosDeBloqueo: [],
            puntoDeBloqueo: []
        }));
    }
    toggleDialogPuntoBloqueoEdit(index) {
        const puntoDeBloqueo = this.state.puntosDeBloqueo[index] || [];
        this.setState(prevState => ({
            dialogPuntoBloqueo: !prevState.dialogPuntoBloqueo,
            puntoDeBloqueo: {
                nombre: puntoDeBloqueo.nombre || '',
                descripcion: puntoDeBloqueo.descripcion || ''
            },
            PuntoDeBloqueoEditIndex: index
        }));
    }

    handleConfirmAddPuntoDeBloqueo() {
        this.setState(prevState => ({
            puntosDeBloqueo: [...prevState.puntosDeBloqueo, prevState.puntoDeBloqueo],
            puntoDeBloqueo: [],
        }));
        this.toggleDialogPuntoBloqueo();
    }

    handleConfirmEditPuntoDeBloqueo() {
        this.setState(prevState => {
            const puntosDeBloqueo = [...prevState.puntosDeBloqueo];
            puntosDeBloqueo[prevState.PuntoDeBloqueoEditIndex] = prevState.puntoDeBloqueo;
            return {
                puntosDeBloqueo: puntosDeBloqueo,
                puntoDeBloqueo: [],
                PuntoDeBloqueoEditIndex: null
            }
        });
        this.toggleDialogPuntoBloqueo();
    }

    handleConfirmAddEquipo() {
        const nuevoEquipoAbloquear = {
            nombre: this.state.equipoNombrePuntoBloqueo,
            puntosDeBloqueo: this.state.puntosDeBloqueo
        };
        this.setState(prevState => ({
            equiposAbloquear: Array.isArray(prevState.equiposAbloquear) ? [...prevState.equiposAbloquear, nuevoEquipoAbloquear] : [nuevoEquipoAbloquear],
            puntosDeBloqueo: [],
            equipoNombrePuntoBloqueo: ""
        }));

        this.toggleDialogAbloquear(); // Cierra el diálogo
    }

    handleConfirmEditEquipo() {
        console.log("Confirmar Editar Equipo")
        this.setState(prevState => {
            const equiposAbloquear = [...prevState.equiposAbloquear];
            equiposAbloquear[prevState.EquipoBloquearEditIndex] = {
                nombre: this.state.equipoNombrePuntoBloqueo,
                puntosDeBloqueo: this.state.puntosDeBloqueo
            };
            return {
                equiposAbloquear: equiposAbloquear,
                puntosDeBloqueo: [], // Limpiar puntos de bloqueo para el próximo equipo
                equipoNombrePuntoBloqueo: "", // Limpiar nombre para el próximo equipo
                EquipoBloquearEditIndex: null
            }
        });

        this.toggleDialogAbloquear(); // Cierra el diálogo
    }

    handleInputChangeNombreEquipoAbloquear = (event) => {
        this.setState({ equipoNombrePuntoBloqueo: event.target.value });
    }

    render() {
        const { equipoEditar, handleDialogClose, openDialog } = this.props; // datos de la ot que estan en el listado
        const reloadWindow = this.props.reloadWindow === undefined ? true : false
        const sitioOptions = this.props.listadoDeSitios?.map(sitio => {
            return {
                value: { id: sitio.id, nombre: sitio.nombre },
                label: sitio.nombre
            }
        });
        const riesgosOptions = Object.keys(RIESGOS)?.map(key => ({
            value: key,
            label: RIESGOS[key].NAME
        }));

        const defaultRiesgosOptions = Object.keys(RIESGOS)?.map(peligro => {
            if (equipoEditar?.peligros?.includes(peligro))
                return {
                    value: peligro,
                    label: RIESGOS[peligro].NAME
                }
        });
        const theme = createTheme({
            palette: {
                primary: {
                    main: '#1b5e20',
                },
                secondary: {
                    main: '#1b5e20',
                },
            },
        });

        return (
            <> <MuiThemeProvider theme={theme}>
                <Dialog open={openDialog} onClose={handleDialogClose} PaperProps={{
                    sx: {
                        width: "100%",
                        maxWidth: "1000px!important",
                    },
                }} >
                    <DialogTitle>Equipo a intervenir</DialogTitle>
                    <DialogContent>
                        <TextField
                            color="secondary"
                            autoFocus
                            margin="dense"
                            name="nombre"
                            label="Nombre del equipo"
                            type="text"
                            fullWidth
                            onChange={this.handleInputChange}
                            defaultValue={equipoEditar.nombre}
                        />
                        <Select
                            defaultValue={defaultRiesgosOptions}
                            onChange={selectedOption => this.handleSelectChange('peligros', selectedOption, true)}
                            options={riesgosOptions}
                            isSearchable={false}
                            className="react-select-container"
                            placeholder="Elegir Riesgos"
                            classNamePrefix="react-select-menu"
                            isMulti
                            theme={(theme) => ({
                                ...theme,
                                colors: {
                                    ...theme.colors,
                                    primary: 'BlueViolet',
                                },
                            })}
                            styles={{
                                control: (base) => ({
                                    ...base,
                                    backgroundColor: 'white',    //sacar transparencia
                                    opacity: " 1 !important"
                                }),
                            }}
                        />
                        <TextField
                            color="secondary"
                            autoFocus
                            margin="dense"
                            name="epp"
                            label="Descripcion del equipo de proteccion personal"
                            type="text"
                            fullWidth
                            onChange={this.handleInputChange}
                            defaultValue={equipoEditar.epp}
                        />
                        <Select
                            defaultValue={sitioOptions?.find(option => option.value.id === equipoEditar?.sitio?.id)}
                            onChange={selectedOption => this.handleSelectChange('sitio', selectedOption)}
                            options={sitioOptions}
                            isSearchable={false}
                            className="react-select-container"
                            classNamePrefix="react-select-menu"
                            placeholder="Elegir Sitio"
                            theme={(theme) => ({
                                ...theme,
                                colors: {
                                    ...theme.colors,
                                    primary: 'BlueViolet',
                                },
                            })}
                            styles={{
                                control: (base) => ({
                                    ...base,
                                    backgroundColor: 'white',    //sacar transparencia
                                    opacity: " 1 !important",


                                }),
                            }}
                        />
                        {/* Aquí iría tu formulario para añadir nuevos equipos, si es necesario */}
                        <button className="mt-4 btns btns-primario btns" onClick={() => this.toggleDialogAbloquear()}>
                            Agregar Equipo a bloquear
                        </button>
                        {/* Lista de Equipos */}
                        <List>
                            {this.state.equiposAbloquear?.map((equipo, equipoIndex) => (
                                <ListItem key={equipoIndex} divider>
                                    <ListItemText primary={equipo.nombre} />
                                    <ListItemSecondaryAction>
                                        <IconButton edge="end" aria-label="edit" onClick={() => this.toggleDialogAbloquearEdit(equipoIndex)}>
                                            <EditIcon />
                                        </IconButton>
                                        <IconButton edge="end" aria-label="delete" onClick={() => this.handleRemoveEquipoBloqueo(equipoIndex)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            ))}
                        </List>
                        {/* Diálogo para agregar equipo */}
                        <Dialog open={this.state.dialogABloquear} onClose={this.toggleDialogAbloquear}>
                            <DialogTitle>Añadir un nuevo equipo a bloquear</DialogTitle>
                            <DialogContent>
                                <TextField
                                    color="secondary"
                                    autoFocus
                                    margin="dense"
                                    name="nombreEquipoAbloquear"
                                    label="Nombre del equipo a bloquear"
                                    type="text"
                                    fullWidth
                                    onChange={this.handleInputChangeNombreEquipoAbloquear}
                                    value={this.state.equipoNombrePuntoBloqueo}
                                />
                                <button className="mt-4 btns btns-primario btns" onClick={() => this.toggleDialogPuntoBloqueo()}>Agregar Punto de Bloqueo</button>
                                <List>
                                    {this.state.puntosDeBloqueo?.map((puntoDeBloqueo, puntoDeBloqueoIndex) => (
                                        <ListItem key={puntoDeBloqueoIndex} divider style={{ justifyContent: 'space-between', alignItems: 'center' }}>
                                            <ListItemText primary={puntoDeBloqueo.nombre} />
                                            <ListItemSecondaryAction>
                                                <IconButton edge="end" aria-label="edit" onClick={() => this.toggleDialogPuntoBloqueoEdit(puntoDeBloqueoIndex)}>
                                                    <EditIcon />
                                                </IconButton>
                                                <IconButton edge="end" aria-label="delete" onClick={() => this.handleRemovePuntoDeBloqueo(puntoDeBloqueoIndex)}>
                                                    <DeleteIcon />
                                                </IconButton>
                                                {puntoDeBloqueo.candadoId && <><IconButton data-tip data-for='punto-de-bloqueo' edge="end" aria-label="delete" onClick={() => this.handleRemovePuntoDeBloqueo(puntoDeBloqueoIndex)}>
                                                    <NoEncryptionGmailerrorredIcon />
                                                </IconButton>
                                                    {/* <ReactTooltip place="bottom" id='punto-de-bloqueo' aria-haspopup='false' >
                                                        <span>Eliminar Candado del punto de bloqueo</span>
                                                    </ReactTooltip>   }                                           */}
                                                </>}
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    ))}
                                </List>
                                <Dialog open={this.state.dialogPuntoBloqueo} onClose={this.toggleDialogPuntoBloqueo}>
                                    <DialogTitle>Añadir Punto De Bloqueo</DialogTitle>
                                    <DialogContent>
                                        <TextField
                                            color="secondary"
                                            autoFocus
                                            margin="dense"
                                            name="nombre"
                                            label="Nombre del punto de bloqueo"
                                            type="text"
                                            fullWidth
                                            onChange={this.handleInputChangePuntoDeBloqueo}
                                            value={this.state.puntoDeBloqueo.nombre}
                                        />
                                        <TextField
                                            color="secondary"
                                            autoFocus
                                            margin="dense"
                                            name="descripcion"
                                            label="Descripcion del punto de bloqueo"
                                            type="text"
                                            fullWidth
                                            onChange={this.handleInputChangePuntoDeBloqueo}
                                            value={this.state.puntoDeBloqueo.descripcion}
                                        />
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={this.toggleDialogPuntoBloqueo}>Cancelar</Button>
                                        <Button variant="contained" color="secondary" onClick={() => (this.state.PuntoDeBloqueoEditIndex === null) ? this.handleConfirmAddPuntoDeBloqueo() : this.handleConfirmEditPuntoDeBloqueo()}>
                                            {(this.state.PuntoDeBloqueoEditIndex === null) ? <><span className="text-white">CONFIRMAR</span></> : <><span className="text-white">EDITAR</span></>}
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={this.toggleDialogAbloquear}>Cancelar</Button>
                                <Button variant="contained" color="secondary" onClick={() => (this.state.EquipoBloquearEditIndex === null) ? this.handleConfirmAddEquipo() : this.handleConfirmEditEquipo()}>
                                    {(this.state.EquipoBloquearEditIndex === null) ? <><span className="text-white">Agregar equipo</span></> : <><span className="text-white">Editar equipo</span></>}
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </DialogContent>
                    <p className="text-danger">{this.state.alertaDeError}</p>
                    <DialogActions>
                        <Button onClick={handleDialogClose} color="primary">
                            <><span className="text-muted">Cancelar</span></>
                        </Button>
                        <Button onClick={this.handleSubmit} variant="contained" color="secondary" >
                            {equipoEditar.id === undefined ? <><span className="text-white">Agregar</span></> : <><span className="text-white">Actualizar</span></>}
                        </Button>
                        {!(equipoEditar.id === undefined) && <Button onClick={() => this.openConfirmDialog(equipoEditar.id)} color="secondary" variant="outlined">
                            <><span className="text-secondary">Eliminar</span></>
                        </Button>}
                    </DialogActions>
                </Dialog>
                <Dialog open={this.state.confirmDialogOpen} onClose={this.closeConfirmDialog}>
                    <DialogTitle>Confirmar Eliminación</DialogTitle>
                    <DialogContent>
                        ¿Estás seguro de que deseas eliminar este equipo a invervenir?
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.closeConfirmDialog} color="primary">
                            Cancelar
                        </Button>
                        <Button onClick={this.handleDeleteConfirmation} variant="contained" color="secondary">
                            <span className="text-white"> Eliminar</span>
                        </Button>
                    </DialogActions>
                </Dialog>
            </MuiThemeProvider>
            </>
        );
    }
}

//export default EquipoDialogs;
function mapState(state) {

    const { user } = state.authentication;
    const listadoDeSitios = state.sitios.listado;
    const listadoDeOrdenesDeTrabajo = state.ot.listado;
    const listadoDeEquipos = state.equipos.listado;
    return { user, listadoDeSitios, listadoDeOrdenesDeTrabajo, listadoDeEquipos };
}

const actionCreators = {
    mensajeError: alertActions.enviarError,
    mensajeExito: alertActions.enviarExito,
    traerEquipos: equipoActions.traerEquipos,
}

const connectedEquipoDialogs = connect(mapState, actionCreators)(EquipoDialogs);
export { connectedEquipoDialogs as EquipoDialogs };