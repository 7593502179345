import http from '../_helpers/http.service';

export const equipoService = {
        traerEquipos,
        buscar,
        agregarEquipo,
        editarEquipo,
        eliminarEquipo
};

function traerEquipos(data) {
    const url = '/api/equipo';   
    return http.get(url); 
}

function buscar(data) {
    const url = '/api/equipo/buscar';
    return http.post(url,JSON.stringify(data));  
}

function agregarEquipo(data) {
    const url = '/api/equipo';
    const formatData = {
        nombre: data.nombre,
        peligros: data.peligros,
        epp: data.epp,
        sitio: data.sitio,
        equiposAbloquear: data.equiposAbloquear
    };
    return http.post(url,JSON.stringify(formatData));  
}

function editarEquipo(data, id) {
    const url = '/api/equipo/' + id;
    return http.put(url,JSON.stringify(data));  
}

function eliminarEquipo(id) {
    const url = `/api/equipo/${id}`;
    return http.delete(url);
}