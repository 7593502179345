/**
 * 
 * @param {*} hexString String hexadecimal con los datos a convertir a Uint8Array
 * @returns Uint8Array con los datos convertidos
 */

// Función para convertir una cadena hexadecimal a una Uint8Array
export function hexToUint8Array(hexString) {
    const bytes = [];
    for (let i = 0; i < hexString.length; i += 2) {
        bytes.push(parseInt(hexString.substr(i, 2), 16));
    }
    return new Uint8Array(bytes);
}
/**
 * 
 * @param {*} bytes Uint8Array con los datos a convertir a String hexadecimal 
 * @returns String hexadecimal con los datos convertidos
 */
// Función para convertir una Uint8Array a una cadena hexadecimal
export function bytesToHex(bytes) {
    return Array.from(bytes, byte => ('0' + (byte & 0xFF).toString(16)).slice(-2)).join('');
}

/**
 * 
 * @param {*} ms int con el tiempo en milisegundos a esperar
 * @returns 
 */
//funcion para esperar un tiempo ( se suele usar para esperar un tiempo entre comandos, normalmente 500ms )
export function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}