import { userConstants } from '../_constants';
import { userService } from '../_services';
import { alertActions } from './';
import { history } from '../_helpers';

export const userActions = {
    login,
    logout,
    getLogueado,
    traerUsuarios
};

function login(username, password) {
    
    return dispatch => {
        dispatch(request({ username }));
        userService.login(username, password)
            .then(
                user => {  
                    let pagina = '/buscador';
                    history.push(pagina);
                    dispatch(success(user));  
                    dispatch(getLogueado());  
                },
                error => {   
                    dispatch(failure(error));             
                    dispatch(alertActions.enviarError(error));
                    if(error.status !=undefined ){
                        dispatch(alertActions.enviarError({
                            codigo: error.status,
                            message:error.statusText && error.statusText!==""?error.statusText:error.data.message?error.data.message:'No se ha podido autenticar'
                        }));
                    }                    
                   
                }
            );
    };

    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}

function logout() {
    userService.logout();
    return { type: userConstants.LOGOUT };
}

function getLogueado() {
    return dispatch => {
        userService.getLogueado()
            .then(
                user => dispatch(success(user)),
                error => dispatch(failure(error))
            );
    };
    function success(user) { return { type: userConstants.GETLOGUEADO_SUCCESS, user } }
    function failure(error) { return { type: userConstants.GETALL_FAILURE, error } }
}

function traerUsuarios(){
    return dispatch => {
        userService.traerListadoGral()
        .then(
            users => { 
                dispatch(success(users));    
                //history.push('/equipo/buscador');               
            },
            error => {    
                dispatch(alertActions.error(error));
            }
        );
    };

    function success(users) { return { type: userConstants.GETALL_SUCCESS, users: users } }
}
