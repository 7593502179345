import React from 'react';
import { connect } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import Select from 'react-select';
import { Col, Row, Container, Badge, Card, ListGroup } from 'react-bootstrap';
import { alertActions, traerPalabrasTabla, utilActions } from '../../_actions';
import MaterialTable from '@material-table/core';
import { ThemeProvider, createTheme } from '@mui/material';
import { CandadoDP, exportToCSV, prepareDataSearch, Role, tengoRol, TIPO_EXPORT, TIPO_PDF, UserDP } from '../../_helpers';
import { userService } from '../../_services';
import { buscadorConstants } from '../../_constants';
import SweetAlert from 'react-bootstrap-sweetalert';
import ReactTooltip from 'react-tooltip';
import { ClockLoader } from 'react-spinners';
import CountUp from 'react-countup';

const tableRef3 = React.createRef();
const bagRef = React.createRef();
const _ = require('lodash');

class BuscadorHome extends React.Component {

    constructor(props) {
        super(props);
        this.state.nuevo = _.clone(buscadorConstants.infoTablaDefault);
    }

    state = {
        loading: false,
        nuevaBusqueda: false,
        alert: undefined
    }

    actualizar = () => {
        if (tableRef3.current) {
            tableRef3.current.state.query.page = 0;
            tableRef3.current.dataManager.currentPage = 0;
            tableRef3.current.onQueryChange();
        }
    }

    handleClose = () => {
        this.setState((prevState) => {
            return {
                alert: undefined
            }
        });
    }

    componentDidMount() {
    }

    buscarData = (query) => {
        return new Promise((resolve, reject) => {

            if (this.state.nuevaBusqueda) query.page = 0

            userService.buscar(prepareDataSearch(query, 'lastName', this.state.values))
                .then(
                    data => {
                        this.setState({ nuevaBusqueda: false });
                        resolve({
                            pageSize: query.pageSize,
                            data: data.content ? data.content : [],
                            page: query.page,
                            totalCount: data ? data.totalElements : 0
                        })
                    },
                    error => {
                        this.props.enviarError(error);
                    }
                );
        })
    }

    handleExportExcel(fileName) {
        this.buscarData(this.state.nuevo, true).then(
            data => {
                exportToCSV(utilActions.listaParaExport(data, TIPO_EXPORT.INV), fileName)
            },
            error => {
                console.log("Error", error)
            }
        );
    }

    manejarSeleccion = (rows) => {
        this.setState({ seleccionados: rows });
    }

    render() {

        //const { loading } = this.state;

        //const soyAdmin = tengoRol([Role.ADMIN]);
        const usuariosActivos = [
            ...new Set(this.props?.listadoDeOrdenesDeTrabajo?.map(ot => ot.usuarios).flat())
        ].length;
        const usuariosTotales = this.props.listadoDeUsuarios?.length;
        const totalPeligros = this.props.listadoDeEquipos?.map(equipo => equipo.peligros.length).reduce((a, b) => a + b, 0);
        const equiposActivosIds = this.props.listadoDeOrdenesDeTrabajo?.map(ot => ot.equipos).flat();
        const equiposIntervenidos = this.props.listadoDeEquipos?.filter(equipo => equiposActivosIds?.includes(equipo.id));
        const colorOrdenes = this.props.listadoDeOrdenesDeTrabajo?.reduce((obj, ot) => {
            const candados = ot.candados
                .map(candadoId => this.props.listadoDeCandados?.find(candadoOT => candadoOT.id === candadoId))
                .map(candado => candado?.locked === true && candado?.bloqueos?.length > 1);
            const todosVerdaderos = candados.every(val => val === true);
            const algunoVerdadero = candados.some(val => val === true);
            if( candados.length === 0 ) obj[ot.id] = "danger"; // No tiene candados
            else 
            if (todosVerdaderos) {
                obj[ot.id] = "verde-s"; // Todos son true
            } else if (algunoVerdadero) {
                obj[ot.id] = "amarillo-s"; // Al menos uno es true
            } else {
                obj[ot.id] = "danger"; // Todos son false
            }

            return obj;
        }, {});

        // Establece la prioridad de los colores
        const colorPriority = {
            'verde-s': 1,
            'amarillo-s': 2,
            'danger': 3
        };

        // Ordena los Permisos de trabajo por color y luego por id si tienen el mismo color
        const sortedOrdenesDeTrabajo = this.props.listadoDeOrdenesDeTrabajo
            ?.slice(0, 4)
            .sort((a, b) => {
                const priorityA = colorPriority[colorOrdenes[a.id]];
                const priorityB = colorPriority[colorOrdenes[b.id]];
                if (priorityA !== priorityB) {
                    return priorityA - priorityB; // Ordena por prioridad de color
                }
                return a.id - b.id; // Si los colores son iguales, ordena por id
            });

        return (
            <div className="main-content">
                <Container fluid>
                    <Row>
                        <Col xs={12} lg={12} className="mt-4">
                            <h2><span className="icon-Icon_DataVisualization_text_RGB txt-64 text-secundario align-middle"></span>Dashboard</h2>
                            {/* <h5 className="text-muted ml-5"><i className="fas fa-list-ul text-muted"></i> Listado</h5>                 */}
                        </Col>
                    </Row>
                    <Row className="justify-content-center">

                        <Col xs={12} lg={4} className="mt-4">
                            <Card className="shadow mb-5 bg-secundario">
                                {/* <Card.Header className="border-0  text-white font-weight-bold txt-24"> <span className="icon-Icon_Client_man_text_RGB txt-44 text-white  align-middle"></span> Usuarios Activos
                    <div className="cantidad bg-primario">
                       <span className="text-white">5</span> 
                    </div>
                    </Card.Header> */}
                                <Card.Body className="text-center">
                                    <span className="text-white"><span className="icon-Icon_Client_man_text_RGB txt-60 text-white"></span></span>
                                    <span className="txt-60 text-white font-weight-bold">{usuariosActivos}<span className="txt-60 text-white font-weight-light">/{usuariosTotales}</span></span>
                                    <p className="text-white">Usuarios Activos</p>
                                    {/* <ListGroup variant="flush">
                    <ListGroup.Item>Admin</ListGroup.Item>
                    <ListGroup.Item>Liliana</ListGroup.Item>
                    <ListGroup.Item>Pedro</ListGroup.Item>
                    <ListGroup.Item>Santiago</ListGroup.Item>
                    <ListGroup.Item>Luz</ListGroup.Item>
                    </ListGroup> */}
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={12} lg={4} className="mt-4">
                            <Card className="shadow">
                                {/* <Card.Header className="bg-white border-0 text-primario font-weight-bold txt-24"><span className="icon-Icon_Innovation_text_RGB txt-44 text-primario align-middle"></span> Total de Peligros
                <div className="cantidad bg-secundario">
                       <span className="text-white">1</span> 
                    </div>
                </Card.Header> */}
                                <Card.Body className="text-center ">

                                    <div className="text-secundario txt-60 font-weight-bold">
                                        <span className="text-secundario"><span className="icon-Icon_Innovation_text_RGB  txt-60 text-secundario"></span></span>  <CountUp
                                            start={0} end={totalPeligros} duration={3}></CountUp>
                                    </div>
                                    <p className="text-secundario">Total de Peligros</p>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col xs={12} lg={4} className="mt-4">
                            <Card className="shadow mb-5">
                                <Card.Header className="border-0 bg-white text-primario font-weight-bold txt-24"> <span className="icon-Icon_Location_text_RGB txt-44 text-primario align-middle "></span> Sitios Activos
                                </Card.Header>
                                <iframe className="border-0" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13168.556620031686!2d-58.8349281!3d-34.3978088!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bc9de4a133dcfd%3A0xa3be083182d5cfcc!2sTermoel%C3%A9ctrica%20Matheu!5e0!3m2!1ses-419!2sar!4v1693274143176!5m2!1ses-419!2sar" width="100%" height="250" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                            </Card>
                        </Col>
                        <Col xs={12} lg={4} className="mt-4">
                            <Card className="shadow mb-5">
                                <Card.Header className="border-0 bg-white text-primario font-weight-bold txt-24"><span className="icon-Icon_Data_centers_text_RGB txt-44 text-primario align-middle"></span> Equipos intervenidos
                                </Card.Header>
                                <Card.Body>
                                    <ListGroup variant="flush">
                                        {
                                            equiposIntervenidos?.sort((a, b) => a.id - b.id).slice(0, 4).map((equipo, index) => {
                                                return <ListGroup.Item > <span className="material-icons text-muted align-middle">playlist_add_check</span> {equipo.nombre}</ListGroup.Item>
                                            })
                                        }
                                    </ListGroup>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={12} lg={4} className="mt-4">
                            <Card className="shadow">
                                <Card.Header className=" border-0 bg-white text-primario font-weight-bold txt-24"><span className="icon-Icon_Installation_commissioning_text_RGB txt-44 text-primario align-middle"></span>Permisos de trabajo activos
                                </Card.Header>
                                <Card.Body>
                                    <ListGroup variant="flush">
                                        {sortedOrdenesDeTrabajo?.map((ot, index) => {
                                            return (
                                                <ListGroup.Item key={index} className="font-weight-bold">
                                                    <span className={`material-icons text-${colorOrdenes[ot.id]} txt-44 align-middle`}>noise_control_off</span>
                                                    {ot.nombre}
                                                </ListGroup.Item>
                                            );
                                        })}
                                    </ListGroup>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                {this.state.alert}
            </div>
        );
    }
}

function mapState(state) {

    const { user } = state.authentication;
    const listadoDeOrdenesDeTrabajo = state.ot.listado;
    const listadoDeUsuarios = state.users.listado;
    const listadoDeEquipos = state.equipos.listado;
    const listadoDeCandados = state.candados.listado;
    return { user, listadoDeOrdenesDeTrabajo, listadoDeUsuarios, listadoDeEquipos, listadoDeCandados };
}

const actionCreators = {
    enviarError: alertActions.enviarError,
}

const connectedBuscadorHome = connect(mapState, actionCreators)(BuscadorHome);
export { connectedBuscadorHome as BuscadorHome };