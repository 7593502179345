import { candadoConstants } from '../_constants';

export function candados(state = {}, action) {
  switch (action.type) {
    case candadoConstants.GETALL_REQUEST_CANDADO:
      return {
        ...state,
        loading: true
      };
    case candadoConstants.GETALL_SUCCESS_CANDADO:
      return {
        ...state,
        listado: action.candados
      };
    case candadoConstants.GETALL_FAILURE_CANDADO:
      return { 
        ...state,
        error: action.error
      };    
    default:
      return state
  }
}