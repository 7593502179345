import http from '../_helpers/http.service';

export const candadoService = {
        traerListadoGral,
        buscar,
        ejecutarAccion,
        agregarCandado,
        editarCandado,
        eliminarCandado,
        subirImagen,
        asignarCandado
};

function ejecutarAccion(data) {
    const url = '/api/candado/ejecutarAccion';   
    return http.post(url,JSON.stringify(data));  
}

function traerListadoGral() {
    const url = '/api/candado';   
    return http.get(url); 
}

async function buscar(data, usuario) {
    const url = '/api/candado/buscar';
    data.options.nombre = usuario + "/" + data.options.nombre
    console.log("datos a buscar",data)
    return http.post(url,JSON.stringify(data));  
}

function agregarCandado(data){
    const url = '/api/candado';
    return http.post(url,JSON.stringify(data));  
}

function editarCandado(data){
    const url = '/api/candado';
    return http.put(url,JSON.stringify(data));  
}

function eliminarCandado(id){
    const url = `/api/candado/${id}`;
    return http.delete(url);
}
function subirImagen(id, file){
    const url = `/api/candado/subirImagen/${id}`;
    const formData = new FormData();
    formData.append('file', file);

    const config = {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    };

    return http.post(url, formData, config);
}
function asignarCandado(data){
    const dataPuntoDeBloqueo = {
        id: data.puntoId,
        nombre: data.nombre,
        descripcion: data.descripcion,
        candadoId: data.candadoId,
    }
    const url = '/api/candado/asignarCandado';
    return http.post(url,JSON.stringify(dataPuntoDeBloqueo));  
}
