import http from '../_helpers/http.service';

export const sitioService = {
    traerListadoGral,
    buscar,
    crearSitio,
    actualizarSitio,
    deleteSitio,
    subirImagen
};

function traerListadoGral() {
    const url = '/api/sitio';
    return http.get(url);
}

function buscar(data) {
    const url = '/api/sitio/buscar';
    return http.post(url, JSON.stringify(data));
}

function crearSitio(data) {
    const url = '/api/sitio';
    data.fechaInicio = (new Date(data.fechaInicio)).toISOString();
    data.deleted = false;
    const sitioData = { ...data };
    delete sitioData.imagen;
    const sitioJson = JSON.stringify(sitioData);
    
    const formData = new FormData();
    formData.append('sitio', new Blob([sitioJson], { type: 'application/json' }));
    if (data.imagen) {
        formData.append('file', data.imagen, data.imagen.name);
    }
    
    return http.post(url, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
}


function actualizarSitio(data, id) {
    const url = '/api/sitio/' + id;
    console.log(data)
    const enviar = {}
    enviar.nombre = data.nombre;
    enviar.cliente = data.cliente;
    enviar.fechaInicio = (new Date(data.fechaInicio)).toISOString();
    enviar.deleted = false;
    console.log(enviar)
    return http.put(url, enviar);
}
function subirImagen(id, file){
    const url = `/api/sitio/subirImagen/${id}`;
    const formData = new FormData();
    formData.append('file', file);

    const config = {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    };

    return http.post(url, formData, config);
}
function deleteSitio(id) {
    const url = '/api/sitio/' + id;
    return http.delete(url);
}
