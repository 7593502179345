import { equipoConstants } from '../_constants';

export function equipos(state = {}, action) {
  switch (action.type) {
    case equipoConstants.GETALL_REQUEST:
      return {
        ...state,
        loading: true
      };
    case equipoConstants.GETALL_SUCCESS:
      return {
        ...state,
        listado: action.equipos
      };
    case equipoConstants.GETALL_FAILURE:
      return { 
        ...state,
        error: action.error
      };    
    default:
      return state
  }
}