import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Select from 'react-select';
import './css/select.css'
import { candadoService } from '../../_services';
import { alertActions } from '../../_actions';
import { connect } from 'react-redux';
const candadoInicial = {
    nombre: '',
    locked: false,
    equipoId: 1,
    createdById: 1,
    estadoBateria: 'VERDE',
    tiempoUso: 0,
    mac: '',
    key: '',
    password: '',
    serial: '',
    rol: '',
    id: null,
    camposVacios: [],
    alertaDeError: ''
}
class CandadoDialogs extends React.Component {

    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
    }
    state = {
        nuevoCandado: candadoInicial,
        confirmDialogOpen: false,
        candadoToDelete: null
    }
    bateryOptions = [
        { value: 'VERDE', label: 'Verde' },
        { value: 'AMARILLO', label: 'Amarillo' },
        { value: 'ROJO', label: 'Rojo' }
    ];
    lockedOptions = [
        { value: true, label: 'Bloqueado' },
        { value: false, label: 'Desbloqueado' }
    ];


    componentDidUpdate(prevProps) {
        if (this.props.editarCandado !== prevProps.editarCandado) {
            this.setState({
                nuevoCandado: {
                    ...this.state.nuevoCandado,
                    ...this.props.editarCandado
                },
            });
        }
    }

    handleSelectChange = (name, selectedOption) => {
        this.setState(prevState => ({
            nuevoCandado: {
                ...prevState.nuevoCandado,
                [name]: selectedOption.value
            },
            camposVacios: prevState.camposVacios?.filter((campo) => campo !== name),
        }));
    }
    handleSubmit = () => {

        const { nuevoCandado } = this.state;
        const camposVacios = [];

        if (!nuevoCandado.nombre) camposVacios.push('Nombre')
        if (!nuevoCandado.mac) camposVacios.push('Mac')
        if (!nuevoCandado.key) camposVacios.push('Clave de encriptacion')
        if (!nuevoCandado.password) camposVacios.push('Contraseña')
        if (!nuevoCandado.serial) camposVacios.push('Numero de serie')

        this.setState({ camposVacios });

        if (camposVacios.length > 0) {
            const alertaError = `Los campos ${camposVacios.join(", ")} son requeridos`;
            this.setState({ alertaDeError: alertaError });
            return;
        }

        this.state.nuevoCandado.createdById = this.props.user.id;
        this.state.nuevoCandado.rol = this.props.user.roles[0];
        this.state.nuevoCandado.id
            ? candadoService.editarCandado(this.state.nuevoCandado)
                .then(() => {
                    this.props.mensajeExito("Candado actualizado correctamente");
                    setTimeout(() => {
                        window.location.reload()
                    }, 1000);
                })
                .catch(error => {
                    this.props.mensajeError(error);
                })
            : candadoService.agregarCandado(this.state.nuevoCandado)
                .then(() => {
                    this.props.mensajeExito("Candado creado correctamente");
                    setTimeout(() => {
                        window.location.reload()
                    }, 1000);
                })
                .catch(error => {
                    this.props.mensajeError(error);
                });
        this.props.handleDialogClose();
    }
    handleInputChange = (event) => {
        const { name, value } = event.target;
        this.setState(prevState => ({
            nuevoCandado: {
                ...prevState.nuevoCandado,
                [name]: value
            }
        }));
    }

    handleDeleteConfirmation = () => {
        candadoService.eliminarCandado(this.state.candadoToDelete)
            .then(() => {
                this.props.mensajeExito("Candado eliminado correctamente");
                setTimeout(() => {
                    window.location.reload()
                }, 1000);                
            })
            .catch(error => {
                this.props.mensajeError(error);
            });
        this.closeConfirmDialog();
        this.props.handleDialogClose();
    }
    openConfirmDialog = (candadoId) => {
        console.log(this.state.nuevoCandado.id, candadoId)
        this.setState({
            confirmDialogOpen: true,
            candadoToDelete: candadoId
        });
    }

    closeConfirmDialog = () => {
        this.setState({
            confirmDialogOpen: false,
            candadoToDelete: null
        });
    }

    render() {
        const { openDialog, handleDialogClose } = this.props;
        const equipoOptions = this.props.listadoDeEquipos?.map(equipo => {
            return {
                value: equipo.id,
                label: equipo.nombre
            }
        });
        return (
            <>
                <Dialog open={openDialog} onClose={handleDialogClose}>
                    <DialogTitle>{this.state.nuevoCandado.id !== null ? 'Actualizar' : 'Agregar Nuevo'} Candado</DialogTitle>
                    <DialogContent>
                        <TextField
                            color="secondary"
                            autoFocus
                            margin="dense"
                            name="nombre"
                            label="Nombre del Candado"
                            type="text"
                            fullWidth
                            onChange={this.handleInputChange}
                            defaultValue={this.state.nuevoCandado.nombre}
                        />
                        {/* <Select
                            defaultValue={lockedOptions.find(option => option.value === this.state.nuevoCandado.locked)}
                            onChange={selectedOption => handleSelectChange('locked', selectedOption)}
                            options={lockedOptions}
                            isSearchable={false}
                            className="react-select-container"
                            classNamePrefix="react-select"
                            styles={{
                                control: (base) => ({
                                    ...base,
                                    backgroundColor: 'white',    //sacar transparencia
                                    opacity: " 1 !important"
                                }),
                            }}
                        /> */}
                        <Select
                            defaultValue={equipoOptions?.find(option => option.value === this.state.nuevoCandado.equipoId)}
                            onChange={selectedOption => this.handleSelectChange('equipoId', selectedOption)}
                            options={equipoOptions}
                            isSearchable={false}
                            className="react-select-container"
                            classNamePrefix="react-select-menu"
                            placeholder="Elegir Equipo"
                            theme={(theme) => ({
                                ...theme,
                                colors: {
                                ...theme.colors,
                                  primary: 'BlueViolet',
                                },
                              })}
                            styles={{
                                control: (base) => ({
                                    ...base,
                                    backgroundColor: 'white',    //sacar transparencia
                                    opacity: " 1 !important"
                                }),
                            }}
                        />                        
                        <Select
                            defaultValue={this.bateryOptions.find(option => option.value === this.state.nuevoCandado.estadoBateria)}
                            onChange={selectedOption => this.handleSelectChange('estadoBateria', selectedOption)}
                            options={this.bateryOptions}
                            isSearchable={false}
                            className="react-select-container"
                            classNamePrefix="react-select-menu"
                            placeholder="Nivel de batería"
                            theme={(theme) => ({
                                ...theme,
                                colors: {
                                ...theme.colors,
                                  primary: 'BlueViolet',
                                },
                              })}
                            styles={{
                                control: (base) => ({
                                    ...base,
                                    backgroundColor: 'white',    //sacar transparencia
                                    opacity: " 1 !important"
                                }),
                            }}
                        />
                        <TextField
                            color="secondary"
                            autoFocus
                            margin="dense"
                            name="mac"
                            label="Mac del Candado"
                            type="text"
                            fullWidth
                            onChange={this.handleInputChange}
                            defaultValue={this.state.nuevoCandado.mac}
                        />
                        <TextField
                            color="secondary"
                            autoFocus
                            margin="dense"
                            name="key"
                            label="Clave de encriptacion del Candado"
                            type="text"
                            fullWidth
                            onChange={this.handleInputChange}
                            defaultValue={this.state.nuevoCandado.key}
                        />
                        <TextField
                            color="secondary"
                            autoFocus
                            margin="dense"
                            name="password"
                            label="Contraseña del Candado"
                            type="text"
                            fullWidth
                            onChange={this.handleInputChange}
                            defaultValue={this.state.nuevoCandado.password}
                        />
                        <TextField
                            color="secondary"
                            autoFocus
                            margin="dense"
                            name="serial"
                            label="Numero de serie del Candado"
                            type="text"
                            fullWidth
                            onChange={this.handleInputChange}
                            defaultValue={this.state.nuevoCandado.serial}
                        />
                        <p className="text-danger">{this.state.alertaDeError}</p>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleDialogClose} color="primary">
                        <><span className="text-muted">Cancelar</span></>
                        </Button>
                        <Button onClick={this.handleSubmit} variant="contained" color="secondary" >
                            {this.state.nuevoCandado.id === null? <><span className="text-white">Agregar</span></> : <><span className="text-white">Actualizar</span></>}
                        </Button>
                        {!(this.state.nuevoCandado.id === null) && <Button onClick={() => this.openConfirmDialog(this.state.nuevoCandado.id)} color="secondary" variant="outlined">
                        <><span className="text-secondary">Eliminar</span></> 
                        </Button>}
                    </DialogActions>
                </Dialog>

                <Dialog open={this.state.confirmDialogOpen} onClose={this.closeConfirmDialog}>
                    <DialogTitle>Confirmar Eliminación</DialogTitle>
                    <DialogContent>
                        ¿Estás seguro de que deseas eliminar este candado?
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.closeConfirmDialog} color="primary">
                            Cancelar
                        </Button>
                        <Button onClick={this.handleDeleteConfirmation} variant="contained" color="secondary">
                            Eliminar
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    }
}

function mapState(state) {

    const { user } = state.authentication;
    const { userCandados } = state.users;
    const listadoDeOrdenesDeTrabajo = state.ot.listado;
    const listadoDeEquipos = state.equipos.listado;
    return { user, userCandados, listadoDeOrdenesDeTrabajo,listadoDeEquipos };
}

const actionCreators = {
    mensajeError: alertActions.enviarError,
    mensajeExito: alertActions.enviarExito
}

const connectedCandadoDialogs = connect(mapState, actionCreators)(CandadoDialogs);
export { connectedCandadoDialogs as CandadoDialogs };
