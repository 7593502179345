import React from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../_actions';
import {history} from '../../_helpers'; 

class LogoutPage extends React.Component {
    constructor(props) {
        super(props);
        this.props.logout();
        history.push('/login');
    }

    render() {
       
        return (
            <div>           
                 
            </div>
        );
    }
}

function mapState(state) {
    return {};
}

const actionCreators = {
    logout: userActions.logout
};

const connectedLogoutPage = connect(mapState, actionCreators)(LogoutPage);
export { connectedLogoutPage as LogoutPage };