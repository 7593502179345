import { userConstants } from '../_constants';

let user = JSON.parse(localStorage.getItem('users'));
const initialState = user ? { user } : {};

export function users(state = initialState, action) {
  switch (action.type) {
    case userConstants.GETALL_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GETALL_SUCCESS:
      return {
        ...state,
        listado: action.users
      };
    case userConstants.GETALL_FAILURE:
      return { 
        ...state,
        error: action.error
      };   

    case userConstants.ACTUALIZAR_USUARIO_SUCCESS:
      return { 
        ...state,
        user: action.user
      };    
    
      case userConstants.GETLOGUEADO_SUCCESS:
        return {
          ...state,
          userCandados:action.user.misCandados          
        };   
    
      case userConstants.LOGOUT:
        return {};
    default:
      return state
  }
}