
import {ERR, ErrorMessage,Estado, TIPO_EXPORT} from '../_helpers';
import * as Yup from 'yup';

export const utilActions = {
    isEmpty,
    setearValores,
    reducirComboParaTabla,
    aplastarLista,
    listaParaExport
};

function resolve(path, obj) {
    return path.split('.').reduce(function(prev, curr) {
        return prev ? prev[curr] : null
    }, obj || window.self)
}

function isEmpty(obj) {
    if(obj==undefined)return true;
    for(var key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
}

function setearValores(objFinal,objInicial) {
    for(var key in objInicial) {
        if(objInicial.hasOwnProperty(key)){
            objFinal[key] = objInicial[key] && objInicial[key]!==''?objInicial[key]:objFinal[key];
        }            
    }
    return objFinal;
}


function listaParaExport(data,tipoExport){
    let listaFinal= [];
    switch(tipoExport) {
        case TIPO_EXPORT.CANDADO:
            listaFinal = data.data.map(function(item) {
               return item;
            });
         break;
        default:
         break;
    }
    return listaFinal;  
}

function pAr(ar,fields){
    let f = '';

    if(ar && ar.length>0){
        ar.forEach((obj,index)=>{
            fields.forEach((field,index2)=>{
                f = f+obj[field]+',';
            });  
           
        });  
    } 

    return f;
}
function pArPorColuma(newItem,ar,fields,nombreBase){    

    if(ar && ar.length>0){
        ar.forEach((obj,index)=>{
            fields.forEach((field,index2)=>{
                newItem[nombreBase+field+'-'+index] = obj[field];
            });  
           
        });  
    } 
}


function aplastarLista(data){
  
    let dataFlat=[];

    if(data){
        data.forEach((objeto,index)=>{
            let fila={};
            for(var key in objeto) {
                if(key!=='tableData')fila[key]=aplanarDep(objeto,key);                       
            }
            dataFlat.push(fila);
        })   
    }
 
    return dataFlat;    
}

function aplanarDep(objeto,key){
    try{
        if(Array.isArray(objeto[key])) return '-';
        if(objeto[key].descripcion!==undefined)return objeto[key].descripcion;
        if(objeto[key].titulo!==undefined)return objeto[key].titulo;
        return objeto[key]
    }catch(err){
        return objeto[key];
    }
}

function reducirComboParaTabla(dinamicObject,otroFiltro){

    const final = dinamicObject.filter(eq => eq.idNivelDependencia === otroFiltro)

    let obj = final.reduce(function(acc, cur, i) {         
            acc[cur.id] = cur.descripcion;
            return acc;
     
        }, {});
    return obj;    
}

export const traerPalabrasTabla = (object) =>{
    return {
        pagination: {
            labelRowsPerPage: object+' por pagina',
            labelDisplayedRows:object,
            labelDisplayedRows: '{from}-{to} de {count}'
        },
        toolbar: {
            searchTooltip:'Buscar',
            searchPlaceholder:'Escriba el texto... para filtrar',
            nRowsSelected: '{0} fila(s) seleccionadas'
        },
        header: {           
            actions: 'Acciones'
        },
        body: {
            emptyDataSourceMessage: 'No hay nada para mostrar',
            filterRow: {
                filterTooltip: 'Filtrar'
            }
        }
    }
}

