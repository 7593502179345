import CryptoJS from "crypto-js";

/**
 * 
 * @param {*} dataHex String hexadecimal con los datos a cifrar
 * @param {*} keyHex String hexadecimal con la clave de cifrado
 * @returns String hexadecimal con los datos cifrados
 */

// Función para cifrar datos con AES-128 (ECB mode con PKCS7 padding)
export function aes128Encrypt(dataHex, keyHex) {
    // Convierte los valores hexadecimales a objetos WordArray de CryptoJS
    const dataWordArray = CryptoJS.enc.Hex.parse(dataHex);

    // La clave se limitará a 16 bytes (128 bits) sin extender a 32 bytes
    const keyWordArray = CryptoJS.enc.Hex.parse(keyHex);

    // Realiza el cifrado utilizando AES-128 con el modo ECB (Electronic CodeBook) y PKCS7 padding
    const encrypted = CryptoJS.AES.encrypt(dataWordArray, keyWordArray, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7,
        keySize: 128 / 8 // Asegura que se use una clave de 128 bits
    });

    // Convierte el resultado cifrado a un string hexadecimal
    const encryptedHex = encrypted.ciphertext.toString();

    // Trunca el resultado cifrado para obtener solo los primeros 16 bytes (128 bits)
    const truncatedEncryptedHex = encryptedHex.substr(0, 32);

    return truncatedEncryptedHex;
}

/**
 * 
 * @param {*} encryptedHex String hexadecimal con los datos a descifrar
 * @param {*} keyHex String hexadecimal con la clave de descifrado
 * @returns String hexadecimal con los datos descifrados
 */

// Función para desencriptar datos con AES-128 (ECB mode con PKCS7 padding)
export function aes128Decrypt(encryptedHex, keyHex) {
    // Convierte los valores hexadecimales a objetos WordArray de CryptoJS
    const encryptedWordArray = CryptoJS.enc.Hex.parse(encryptedHex);
    const keyWordArray = CryptoJS.enc.Hex.parse(keyHex);
    // Realiza la desencriptación utilizando AES-128 con el modo ECB (Electronic CodeBook) y PKCS7 padding
    const decrypted = CryptoJS.AES.decrypt({
        ciphertext: encryptedWordArray
    },
        keyWordArray, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7,
        iv: null, // Agregar este parámetro como null para evitar problemas con el IV
        keySize: 128 / 8 // Asegura que se use una clave de 128 bits
    });
    // Convierte el resultado desencriptado a un string hexadecimal
    const decryptedHex = decrypted.toString();
    // Trunca el resultado desencriptado para obtener solo los primeros 16 bytes (128 bits)
    const truncatedDecryptedHex = decryptedHex.substr(0, 32);

    return truncatedDecryptedHex;
}