import http from '../_helpers/http.service';

export const ordenDeTrabajoService = {
        buscar,
        agregarOrdenDeTrabajo,
        editarOrdenDeTrabajo,
        eliminarOrdenDeTrabajo,
        traerListaDeOrdenesDeTrabajo,
        traerListaDeOrdenesDeTrabajoPorUsuario,
        traerListadoDeEmpresasDeMantenimiento,
        crearEmpresaDeMantenimiento,
        traerImagenCandado
};

async function buscar(data) {
    const url = '/api/ordenDeTrabajo/buscar';
    return http.post(url,JSON.stringify(data));  
}

function agregarOrdenDeTrabajo(data){
    const url = '/api/ordenDeTrabajo';
    data.fechaInicio = (new Date(data.fechaInicio)).toISOString();
    data.fechaFin = (new Date(data.fechaFin)).toISOString();
    data.equipos = [data.equipos]
    data.riesgos = Array.isArray(data.riesgos) ? data.riesgos.join(",") : "" 
    console.log("data",data.riesgos)
    data.empresa = "Default"
    // console.log("data",data.riesgos.join(","))
    return http.post(url,JSON.stringify(data));  
}

function editarOrdenDeTrabajo(data, id){
    const url = '/api/ordenDeTrabajo/' + id;
    data.fechaInicio = (new Date(data.fechaInicio)).toISOString();
    data.fechaFin = (new Date(data.fechaFin)).toISOString();
    data.equipos = [data.equipos]
    data.riesgos = Array.isArray(data.riesgos) ? data.riesgos.join(",") : "" 
    data.empresa = "Default"
    console.log("RIESGOS ANTES DE ENVIAR",data.riesgos)
    // console.log("data",data.riesgos.join(","))
    return http.put(url,JSON.stringify(data)); 
}

function eliminarOrdenDeTrabajo(id, admin){
    const url = admin ? `/api/ordenDeTrabajo/completo/${id}` :`/api/ordenDeTrabajo/${id}`;
    return http.delete(url);
}

function traerListaDeOrdenesDeTrabajo(){
    const url = '/api/ordenDeTrabajo';
    return http.get(url);
}

function traerListaDeOrdenesDeTrabajoPorUsuario(id){
    const url = `/api/ordenDeTrabajo/user/${id}`;
    return http.get(url);
}

function traerListadoDeEmpresasDeMantenimiento(){
    const url = '/api/ordenDeTrabajo/empresaDeMantenimiento';
    return http.get(url);
}

function crearEmpresaDeMantenimiento(data){
    const url = '/api/ordenDeTrabajo/empresaDeMantenimiento';
    return http.post(url,JSON.stringify(data));
}

function traerImagenCandado(idCandadoPdT){
    const url = '/api/ordenDeTrabajo/imagenCandado/' + idCandadoPdT;
    return http.get(url);
}