import React from 'react';
import { connect } from 'react-redux';
import Alert from 'react-bootstrap/Alert';
import { alertActions } from '../_actions';

class Alerta extends React.Component {
    
    componentDidMount(){
      
        setTimeout(() => {
          this.props.clearAlerts();
        }, 5000);
      
    }

    renderizarError(codigo,data){
        switch (codigo) {
            case 1:
              return <>{data.message?data.message:'Error de sistema'}</>
            case 3:
                return <>{data.message}</>  
            case 401:
              return <>{data.message}</>  
            case 403:
              return <><i className="fas fa-exclamation-circle"></i> {data.message?data.message:''}</>
            case 500:
              return <><i className="fas fa-exclamation-circle"></i> {data.message?data.message:''}</>
            default:
              return null;
          }
    }
    render(){
      
       const {data} = this.props;    
        return (
            <Alert onClose={() => this.props.clearAlerts()} 
            variant={data.type} 
            className={`${data.clase} text-white alert fixed-top-alert`} 
            dismissible >
                {data.codigo?this.renderizarError(data.codigo,data):data.message.codigo?data.message.codigo:data.message}
                
            </Alert>
        );
    }
}

function mapState(state) {
  const { alert } = state;
  return { alert };
}

const actionCreators = {
  clearAlerts: alertActions.clear
};

const connectedAlerta = connect(mapState, actionCreators)(Alerta);
export { connectedAlerta as Alerta };