import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { sitioService } from '../../_services';
import { history } from '../../_helpers';
import { alertActions, sitioActions } from '../../_actions';
import { connect } from 'react-redux';

const sitioInicial = {
    nombre: "",
    cliente: "",
    fechaInicio: (new Date()).toISOString(),
    imagen: "",
}

class SitioDialogs extends React.Component {

    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
    }

    state = {
        confirmDialogOpen: false,
        nuevoSitio: sitioInicial,
        sitioDelete: null,
        imagePreviewUrl: '',
        camposVacios: [],
        alertaDeError: ''
    }

    handleInputChange = (event) => {
        const { name, value } = event.target;
        this.setState(prevState => ({
            nuevoSitio: {
                ...prevState.nuevoSitio,
                [name]: value
            },
            camposVacios: this.state.camposVacios.filter(campo => campo !== name)
        }));
    }
    closeConfirmDialog = () => {
        this.setState({
            confirmDialogOpen: false,
        });
    }
    handleDeleteConfirmation = () => {
        console.log("Eliminando", this.state);
        sitioService.deleteSitio(this.props.sitioEditar.id)
            .then(() => {
                this.props.mensajeExito("Sitio eliminado correctamente");
                setTimeout(() => {
                    window.location.reload()
                }, 1000);                
            })
            .catch(error => {
                this.props.mensajeError(error);
            });
        this.closeConfirmDialog();
        this.props.handleDialogClose();
    }

    handleSubmit = () => {

        const camposVacios = [];

        if (this.state.nuevoSitio.nombre === "") {
            camposVacios.push("Nombre");
        }
        if (this.state.nuevoSitio.cliente === "") {
            camposVacios.push("Cliente");
        }
        if (this.state.nuevoSitio.fechaInicio == sitioInicial.fechaInicio) {
            camposVacios.push("Fecha de Inicio");
        }
        if (this.state.nuevoSitio.imagen === "") {
            camposVacios.push("Imagen");
        }

        this.setState({ camposVacios });

        if (camposVacios.length > 0) {
            const alertaError = `Los campos ${camposVacios.join(", ")} son requeridos`;
            this.setState({ alertaDeError: alertaError });
            return;
        }

        const id = this.props.sitioEditar.id
        const reloadWindow = this.props.reloadWindow === undefined ? true : false;
        this.props.sitioEditar.id
            ? sitioService.actualizarSitio(this.state.nuevoSitio, this.props.sitioEditar.id)
                .then(() => {
                    this.props.mensajeExito("Sitio Actualizado correctamente");
                    sitioService.subirImagen(id, this.state.nuevoSitio.imagen)
                        .then(() => {
                            this.props.mensajeExito("Imagen subida correctamente");
                        })
                        .catch(error => {
                            this.props.mensajeError(error);
                        });
                    setTimeout(() => {
                        window.location.reload()
                    }, 1000);
                })
                .catch(error => {
                    this.props.mensajeError(error);
                })
            : sitioService.crearSitio(this.state.nuevoSitio)
                .then(() => {
                    this.props.mensajeExito("Sitio creado correctamente");
                    setTimeout(() => {
                        reloadWindow ? window.location.reload() : this.props.traerSitios();
                    }, 1000);
                })
                .catch(error => {
                    this.props.mensajeError(error);
                });
        this.props.handleDialogClose();
    }

    openConfirmDialog = (sitioId) => {
        this.setState({
            confirmDialogOpen: true,
            sitioDelete: sitioId
        });
    }

    handleSelectChange = (name, selectedOption, isMulti = false) => {
        const selectedValues = isMulti
            ? selectedOption ? selectedOption.map(option => option.value) : []
            : selectedOption ? selectedOption.value : null;
        console.log('handleSelectChange:', name, selectedValues);  // Agrega esta línea
        this.setState(prevState => ({
            nuevoSitio: {
                ...prevState.nuevoSitio,
                [name]: selectedValues
            }
        }));
    }

    componentDidUpdate(prevProps) {
        if (this.props.sitioEditar !== prevProps.sitioEditar) {
            this.setState({
                nuevoSitio: {
                    ...this.state.nuevoSitio,
                    ...this.props.sitioEditar
                },
                imagePreviewUrl: this.props.sitioEditar.imagen // actualizar imagePreviewUrl
            });
        }
    }


    render() {

        const { sitioEditar, handleDialogClose, openDialog } = this.props; // datos de la ot que estan en el listado

        return (
            <>
                <Dialog open={openDialog} onClose={handleDialogClose} PaperProps={{
                    sx: {
                    width: "100%",
                    maxWidth: "920px!important",
                    },
                }} >
                    <DialogTitle>Sitio</DialogTitle>
                    <DialogContent>
                        <TextField
                            color="secondary"
                            autoFocus
                            margin="dense"
                            name="nombre"
                            label="Nombre del Sitio"
                            type="text"
                            fullWidth
                            onChange={this.handleInputChange}
                            defaultValue={sitioEditar?.nombre}
                        />
                        <TextField
                            color="secondary"
                            autoFocus
                            margin="dense"
                            name="cliente"
                            label="Nombre del cliente"
                            type="text"
                            fullWidth
                            onChange={this.handleInputChange}
                            defaultValue={sitioEditar?.cliente}
                        />
                        <TextField
                            color="secondary"
                            margin="dense"
                            name="fechaInicio"
                            label="Fecha de Inicio de comisión"
                            type="date"
                            fullWidth
                            onChange={this.handleInputChange}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            defaultValue={sitioEditar?.fechaInicio}
                        />
                        <Button className="bg-info"
                            variant="contained"
                            component="label"
                            style={{ marginTop: 10 }}  // Este estilo es opcional
                        >
                            Cargar Imagen
                            <input
                                type="file"
                                hidden
                                onChange={(event) => {
                                    const file = event.target.files[0];
                                    if (file != null) {
                                        this.setState(prevState => ({
                                            nuevoSitio: {
                                                ...prevState.nuevoSitio,
                                                imagen: file
                                            },
                                            imagePreviewUrl: URL.createObjectURL(file)
                                        }));
                                        this.props.sitioEditar.imagen = null;
                                    }
                                }}
                            />
                        </Button>
                        <div className="bg-danger">
                        {this.state.imagePreviewUrl && (
                            <img src={this.props.sitioEditar.imagen ? `data:image/png;base64,${this.state.imagePreviewUrl}` : this.state.imagePreviewUrl} alt="Imagen de previsualización" style={{ width: '100%', height: 'auto' }} />
                        )}
                        </div>
                    </DialogContent>
                    <p className="text-danger">{this.state.alertaDeError}</p>
                    <DialogActions>
                        <Button onClick={handleDialogClose} color="primary">
                        <><span className="text-muted">Cancelar</span></>
                        </Button>
                        <Button onClick={this.handleSubmit} variant="contained" color="secondary" >
                            {sitioEditar?.id === undefined ? <><span className="text-white">Agregar</span></> : <><span className="text-white">Actualizar</span></>}
                        </Button>
                        {!(sitioEditar.id === undefined) && <Button onClick={() => this.openConfirmDialog(sitioEditar.id)} color="secondary" variant="outlined">
                        <><span className="text-secondary">Eliminar</span></> 
                        </Button>}
                    </DialogActions>
                </Dialog>
                <Dialog open={this.state.confirmDialogOpen} onClose={this.closeConfirmDialog}>
                    <DialogTitle>Confirmar Eliminación</DialogTitle>
                    <DialogContent>
                        ¿Estás seguro de que deseas eliminar este sitio?
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.closeConfirmDialog} color="primary">
                            Cancelar
                        </Button>
                        <Button onClick={this.handleDeleteConfirmation} color="secondary">
                            Eliminar
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    }
}

function mapState(state) {

    const { user } = state.authentication;
    const listadoDeSitios = state.sitios.listado;
    const listadoDeOrdenesDeTrabajo = state.ot.listado;
    const listadoDeEquipos = state.equipos.listado;
    return { user, listadoDeSitios,listadoDeOrdenesDeTrabajo,listadoDeEquipos };
}

const actionCreators = {
    mensajeError: alertActions.enviarError,
    mensajeExito: alertActions.enviarExito,
    traerSitios: sitioActions.traerSitios,
}

const connectedSitioDialogs = connect(mapState, actionCreators)(SitioDialogs);
export { connectedSitioDialogs as SitioDialogs };