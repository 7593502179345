import { sitiosConstants } from '../_constants';

export function sitios(state = {}, action) {
  switch (action.type) {
    case sitiosConstants.GETALL_REQUEST_SITIOS:
      return {
        ...state,
        loading: true
      };
    case sitiosConstants.GETALL_SUCCESS_SITIOS:
      return {
        ...state,
        listado: action.sitios
      };
    case sitiosConstants.GETALL_FAILUR_SITIOS:
      return { 
        ...state,
        error: action.error
      };    
    default:
      return state
  }
}