import { otConstants } from '../_constants';
import { ordenDeTrabajoService } from '../_services';
import { alertActions } from './';
import { history } from '../_helpers';

export const otActions = {
    traerOT,
    traerEmpresasDeMantenimiento
};

function traerOT() {
    return dispatch => {
        ordenDeTrabajoService.traerListaDeOrdenesDeTrabajo()
        .then(
            ot => { 
                dispatch(success(ot));  
                //history.push('/sitio/buscador');               
            },
            error => {    
                dispatch(alertActions.error(error));
            }
        );
    };
    function success(ot) { return { type: otConstants.GETALL_SUCCESS_OT, ot: ot } }
}

function traerEmpresasDeMantenimiento() {
    return dispatch => {
        ordenDeTrabajoService.traerListadoDeEmpresasDeMantenimiento()
        .then(
            empresas => { 
                dispatch(success(empresas));            
            },
            error => {    
                dispatch(alertActions.error(error));
            }
        );
    };
    function success(empresas) { return { type: otConstants.GETALL_SUCCESS_EMPRESAS, empresasDeMantenimiento: empresas } }
}