import {  candadoConstants } from '../_constants';
import { candadoService } from '../_services';
import { alertActions } from '.';
import { Role, traerListarolesOrdenadosPrioridad, traerMisRoles } from '../_helpers';

export const candadosActions = {
    traerListadoGral
};

export const tengoRolMaximaPrioridad = () => {
    let tengoMax = false;
    const maximaPrioridad = candadoConstants.MAXIMA_PRIORIDAD;
    const misRoles = traerMisRoles();

    misRoles.forEach(role => {
        if(Role[role]?.prioridad === maximaPrioridad){
            tengoMax = true;
        }
    });

    return tengoMax;
}

//Busco cual es mi rol para este candado
export const buscarMiRolEnCandado = (candado, userCandados) => {
    const userCandado = userCandados.filter(it => it.candado.id === candado.id);   
    return userCandado && userCandado.length >= 1 ?userCandado[0].rol:undefined;
}


export const CApuedeEliminarBloqueoDigital = (candado, userCandados) => {
    let puedo = false;    

    //1 que el candado este efectivamente cerrado y chequeo que posea el candado como candado propio.
    //console.log (candado, userCandados)
    if(candado.locked && userCandados?.some(c => c.candado.id === candado.id)){
        //Para aplicar el bloqueo digital, mi rol sobre el candado debe ser la siguiente en la lista
        const nextRol = buscarProximoRolDesloqueoDig(candado); 
        const miRol = userCandados.filter(c => c.candado.id === candado.id)[0].rol;
       // console.log('nextRol',nextRol, miRol);
        puedo = miRol === nextRol;
    }
    return puedo;
}

export const CApuedeAplicarBloqueoDigital = (candado, userCandados) => {
    let puedo = false;

    //1 que el candado este efectivamente cerrado y chequeo que posea el candado como candado propio.
    if( candado.locked && userCandados?.some(c => c.candado.id === candado.id) ){
        //Para aplicar el bloqueo digital, mi rol sobre el candado debe ser la siguiente en la lista
        const nextRol = buscarProximoRolBloqueoDig(candado);
        const miRol = userCandados.filter(c => c.candado.id === candado.id)[0].rol;
        puedo = miRol === nextRol;
    }
    //console.log (puedo, candado, userCandados)
    return puedo;
}

/**
 * Busca segun los bloqueos digitales, y la cadena valida de LM -> RDE -> EJECUTANTE
 */
export const buscarProximoRolBloqueoDig = (candado) =>{
    const listaPrioridades = traerListarolesOrdenadosPrioridad('ASC');
    //console.log('listaPrioridades ASC',listaPrioridades);
    let rolMinimoBloqueoActual;
    candado.bloqueos.forEach(bloqueo => {
        if(rolMinimoBloqueoActual === undefined || Role[bloqueo.rol].prioridad <= rolMinimoBloqueoActual.prioridad){
            rolMinimoBloqueoActual = Role[bloqueo.rol];
        }
    });

    //El mas bajo de prioridad deberia ser el siiguiente:    

    const index = listaPrioridades.findIndex(it=>it === rolMinimoBloqueoActual?.name);

    //console.log('rolMinimoBloqueoActual',rolMinimoBloqueoActual, index, listaPrioridades.length, listaPrioridades[index+1]);

    if (listaPrioridades.length < (index +1)) return undefined;
    else{
        return listaPrioridades[index+1]; 
    } 
}

/**
 * Busca segun los bloqueos digitales, y la cadena valida de EJECUTANTE -> RDE -> LM
 */
export const buscarProximoRolDesloqueoDig = (candado) =>{
    const listaPrioridades = traerListarolesOrdenadosPrioridad('DESC');
    //console.log('listaPrioridades ASC',listaPrioridades);
    let rolMinimoBloqueoActual;

    candado.bloqueos.forEach(bloqueo => {
        if(rolMinimoBloqueoActual === undefined || Role[bloqueo.rol].prioridad <= rolMinimoBloqueoActual.prioridad){
            rolMinimoBloqueoActual = Role[bloqueo.rol];
        }
    });

    //console.log('rolMinimoBloqueoActual', rolMinimoBloqueoActual);

    return rolMinimoBloqueoActual?.name;
}


function traerListadoGral() {
    return dispatch => {
        candadoService.traerListadoGral()
        .then(
            data => { 
                dispatch(success(data));                   
            },
            error => {    
                dispatch(alertActions.error(error));
            }
        );
    };

    function success(data) { return { type: candadoConstants.GETALL_SUCCESS_CANDADO, candados: data } }
}
