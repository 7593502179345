import { otConstants } from '../_constants';

export function ot(state = {}, action) {
  switch (action.type) {
    case otConstants.GETALL_REQUEST_OT:
      return {
        ...state,
        loading: true
      };
    case otConstants.GETALL_SUCCESS_OT:
      return {
        ...state,
        listado: action.ot
      };
    case otConstants.GETALL_FAILUR_OT:
      return { 
        ...state,
        error: action.error
      };    
      case otConstants.GETALL_SUCCESS_EMPRESAS:
      return {
        ...state,
        empresas: action.empresasDeMantenimiento
      };
    default:
      return state
  }
}