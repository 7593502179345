import { sitiosConstants } from '../_constants';
import { sitioService } from '../_services';
import { alertActions } from './';
import { history } from '../_helpers';

export const sitioActions = {
    traerSitios,
};

function traerSitios() {
    return dispatch => {
        sitioService.traerListadoGral()
        .then(
            sitios => { 
                dispatch(success(sitios));  
                console.log(sitios)  
                //history.push('/sitio/buscador');               
            },
            error => {    
                dispatch(alertActions.error(error));
            }
        );
    };
    function success(sitios) { return { type: sitiosConstants.GETALL_SUCCESS_SITIOS, sitios: sitios } }
}
