export const buscadorConstants = {
    AGENTE_BUSCAR: 'AGENTE_BUSCAR',
    AGENTE_POR_LEGAJO:'AGENTE_POR_LEGAJO',
    LISTADO_DEPENDENCIAS:'LISTADO_DEPENDENCIAS',
    GUARDAR_CAMPOS:'GUARDAR_CAMPOS',
    LISTADO_GENERALES:'LISTADO_GENERALES',
    VACIAR_CAMPOS:'VACIAR_CAMPOS',
    LISTADO_DEPENDENCIAS_GESTOR:'LISTADO_DEPENDENCIAS_GESTOR',
    TRAER_DEPENDENCIA:'TRAER_DEPENDENCIA',
    VACIAR_DEPENDENCIA:'VACIAR_DEPENDENCIA',
    LIMPIAR_DEPENDENCIAS_GESTOR:'LIMPIAR_DEPENDENCIAS_GESTOR',
    LISTADO_DEPENDENCIAS_HIJAS:'LISTADO_DEPENDENCIAS_HIJAS',
    LISTADO_DEPENDENCIAS_HIJAS_MINSUB:'LISTADO_DEPENDENCIAS_HIJAS_MINSUB',
    LISTADO_PENDIENTES:'LISTADO_PENDIENTES',
    REMOVER_MIS_PENDIENTES:'REMOVER_MIS_PENDIENTES',
    TRAER_PRESENTACION:'TRAER_PRESENTACION',
    GRABAR_PRESENTACION:'GRABAR_PRESENTACION',
    GRABAR_DEPENDENCIA:'GRABAR_DEPENDENCIA',
    ACT_DEPENDENCIA:'ACT_DEPENDENCIA',
    LISTADO_GENERAL_RRHH:'LISTADO_GENERAL_RRHH',
    TOOGLE_CARGA:'TOOGLE_CARGA',
    infoTablaDefault:{
        search: '',
        page: 0,
        limit: 10,
        skip:0,
        totalCount: 0,
        orderBy:'',
        order:''
    }
};
