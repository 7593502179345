import { combineReducers } from 'redux';
import { authentication } from './authentication.reducer';
import { users } from './users.reducer';
import { alert } from './alert.reducer';
import { equipos } from './equipo.reducer';
import { sitios } from './sitio.reducer';
import { candados } from './candado.reducer';
import { ot } from './OT.reducer';
const rootReducer = combineReducers({
  authentication,
  users,
  alert,
  equipos,
  sitios,
  candados,
  ot
});

export default rootReducer;
