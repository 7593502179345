import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';

const ConfirmacionDialog = ({ open, onClose, onConfirm, titulo, mensaje }) => {
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>{titulo}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {mensaje}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancelar
                </Button>
                <Button onClick={onConfirm} variant="contained" color="secondary">
                <span className="text-white">Confirmar</span>
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmacionDialog;
