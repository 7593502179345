import { equipoConstants } from '../_constants';
import { equipoService } from '../_services';
import { alertActions } from './';
import { history } from '../_helpers';

export const equipoActions = {
    traerEquipos,
};

function traerEquipos() {
    return dispatch => {
        equipoService.traerEquipos()
        .then(
            equipos => { 
                dispatch(success(equipos));    
                //history.push('/equipo/buscador');               
            },
            error => {    
                dispatch(alertActions.error(error));
            }
        );
    };

    function success(equipos) { return { type: equipoConstants.GETALL_SUCCESS, equipos: equipos } }
}
