import http from '../_helpers/http.service';

export const userService = {
    login,
    logout,
    getLogueado,
    estaLogueado,
    buscar,
    traerListadoGral,
    crear,
    editarUsuario,
    eliminarUsuario
};

function login(username, password) {
    var url = '/auth/authenticate';
    return http.post(url,JSON.stringify({username: username,password: password}))
    .then(user => {        
        localStorage.setItem('user', JSON.stringify(user));
        return user;
    });
}

function buscar(data) {
    const url = '/api/user/buscar';
    return http.post(url,JSON.stringify(data));  
}

function getLogueado() {
    var url = "/api/user/info";
    return http.get(url);
}

function estaLogueado(){    
    
    let user = localStorage.getItem('user');
    if(user!==undefined){
        return true;
    }else{
        return false;
    }
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
}

function traerListadoGral(){
    const url = '/api/user';
    return http.get(url);
}

function crear(data){
    const url = '/api/user/crear';
    return http.post(url,JSON.stringify(data));
}

function editarUsuario(data,id){
    const url = '/api/user/actualizar/'+id;
    return http.post(url,JSON.stringify(data));
}

function eliminarUsuario(id){
    const url = '/api/user/eliminar/'+id;
    return http.post(url, JSON.stringify({id: id}));
}