import { alertConstants } from '../_constants';

export const alertActions = {
    success,
    error,
    clear,
    enviarError,
    enviarExito
};

function success(error) {
    return { type: alertConstants.SUCCESS, error };
}

function error(error) {
    return { type: alertConstants.ERROR, error};
}

function enviarError(error) {             
    return dispatch => {      
        dispatch(errore(error));   
    };

    function errore(error) { return { type: alertConstants.ERROR, error}; }
}

function enviarExito(mensaje) {             
    return dispatch => {      
        dispatch(exito(mensaje));   
    };

    function exito(error) { return { type: alertConstants.SUCCESS, error}; }
}

function clear() {
    return { type: alertConstants.CLEAR };
}