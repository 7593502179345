import React from 'react';
import { connect } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import Select from 'react-select';
import { Col, Row, Container, Badge, Modal, Button } from 'react-bootstrap';
import { alertActions, tengoRolMaximaPrioridad, traerPalabrasTabla, utilActions } from '../../_actions';
import MaterialTable from '@material-table/core';
import { ThemeProvider, createTheme } from '@mui/material';
import { CandadoDP, exportToCSV, prepareDataSearch, Role, tengoRol, TIPO_EXPORT, TIPO_PDF, UserDP } from '../../_helpers';
import { userService } from '../../_services';
import { buscadorConstants } from '../../_constants';
import SweetAlert from 'react-bootstrap-sweetalert';
import ReactTooltip from 'react-tooltip';
import { ClockLoader } from 'react-spinners';
import { UsuarioDialogs } from './UsuarioDialogs';
import {OrdenDeTrabajoDP} from '../../_helpers';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
const tableRef3 = React.createRef();
const bagRef = React.createRef();
const _ = require('lodash');

class BuscadorUsuario extends React.Component {

    constructor(props) {
        super(props);
        this.state.nuevo = _.clone(buscadorConstants.infoTablaDefault);
    }

    state = {
        loading: false,
        nuevaBusqueda: false,
        alert: undefined,
        openDialog: false,
        usuarioEditar: {},
        showModal: false,
        ordenTrabajoData: null,
        showModalHistorical: false,
    }

    actualizar = () => {
        if (tableRef3.current) {
            tableRef3.current.state.query.page = 0;
            tableRef3.current.dataManager.currentPage = 0;
            tableRef3.current.onQueryChange();
        }
    }

    handleClose = () => {
        this.setState((prevState) => {
            return {
                alert: undefined
            }
        });
    }

    componentDidMount() { }

    buscarData = (query) => {
        return new Promise((resolve, reject) => {

            if (this.state.nuevaBusqueda) query.page = 0

            let values = this.state.values ? this.state.values : { estado: { id: '' }, lugar: { id: '' }, nombre: "" }

            userService.buscar(prepareDataSearch(query, 'lastName', values))
                .then(
                    data => {
                        this.setState({ nuevaBusqueda: false });
                        resolve({
                            pageSize: query.pageSize,
                            data: data.content ? data.content : [],
                            page: query.page,
                            totalCount: data ? data.totalElements : 0
                        })
                    },
                    error => {
                        this.props.enviarError(error);
                    }
                );
        })
    }

    handleExportExcel(fileName) {
        this.buscarData(this.state.nuevo, true).then(
            data => {
                exportToCSV(utilActions.listaParaExport(data, TIPO_EXPORT.INV), fileName)
            },
            error => {
                console.log("Error", error)
            }
        );
    }

    manejarSeleccion = (rows) => {
        this.setState({ seleccionados: rows });
    }

    agregarUsuario = () => {
        this.handleDialogOpen();
    }

    handleDialogOpen = () => {
        this.setState({ openDialog: true });
    }

    handleDialogClose = () => {
        this.setState({ openDialog: false, usuarioEditar: {} });
    }

    editarUsuario = (usuario => {
        const usuarioCompleto = this.props.listadoDeUsuarios?.filter(usuarioL => usuarioL.id === usuario.id) || [];
        this.setState({
            usuarioEditar: {
                id: usuarioCompleto[0].id,
                firstName: usuarioCompleto[0].firstName,
                lastName: usuarioCompleto[0].lastName,
                email: usuarioCompleto[0].email,
                password: "",
                roles: usuarioCompleto[0].roles,
            }
        })
        this.handleDialogOpen();
    })

    verInscripcion(row) {
        const ot = this.props.listadoDeOrdenesDeTrabajo?.filter(ot => ot.usuarios.includes(row.id)).map(ot => {
            return {
                id: ot.id,
                nombre: ot.nombre,
                equipo_intervenido: ot.equipo_intervenido,
                fechaFin: ot.fechaFin,
                fechaInicio: ot.fechaInicio,
                equipos: this.props.listadoDeEquipos?.filter(equipo => ot?.equipos?.includes(equipo.id)),
                puntosBloqueo: ot.puntosBloqueo
            }
        })
        this.setState({ ordenTrabajoData: ot, showModal: true });
    }

    verHistorico(row) {
        console.log("row",row.id)
        this.setState({ ordenTrabajoDataUserIdHistorical: row.id, showModalHistorical: true });
    }

    handleCloseModal = () => {
        this.setState({ showModal: false, ordenTrabajoData: null, ordenTrabajoDataUserIdHistorical: null, showModalHistorical: false });
    }
    colorUserOt = (usuarioId) => {
        const ordenesDeTrabajoUsuario = this.props.listadoDeOrdenesDeTrabajo
            ?.filter(ot => ot.usuarios?.includes(usuarioId));
        if (ordenesDeTrabajoUsuario === undefined || ordenesDeTrabajoUsuario.length === 0) {
            return "text-muted";
        } else {
            let todosLosCandadosEstanBloqueados = true;
            let alMenosUnCandadoEstaBloqueado = false;
            ordenesDeTrabajoUsuario.forEach(ot => {
                ot.equipos.forEach(equipoId => {
                    const equipo = this.props.listadoDeEquipos?.find(e => e.id === equipoId);
                    if (equipo && equipo.equiposAbloquear.length > 0) {
                        equipo.equiposAbloquear.forEach(equipoAbloquear => {
                            if (equipoAbloquear.puntosDeBloqueo) {
                                equipoAbloquear.puntosDeBloqueo.forEach(puntoDeBloqueo => {
                                    const candado = this.props.listadoDeCandados?.find(c => c.id === puntoDeBloqueo.candadoId);
                                    if (candado) {
                                        if (candado?.locked === true && candado?.bloqueos?.length > 1) {
                                            alMenosUnCandadoEstaBloqueado = true;
                                        } else {
                                            todosLosCandadosEstanBloqueados = false;
                                        }
                                    }
                                });
                            }
                        });
                    } else {
                        todosLosCandadosEstanBloqueados = false;
                    }
                });
            });
            if (todosLosCandadosEstanBloqueados) {
                return "text-verde-s";
            } else if (alMenosUnCandadoEstaBloqueado) {
                return "text-amarillo-s";
            } else if (todosLosCandadosEstanBloqueados === false && alMenosUnCandadoEstaBloqueado === false) {
                return "text-danger";
            }
            return "text-muted";
        }
    }



    render() {

        const { loading } = this.state;

        const soyAdmin = tengoRol([Role.ADMIN]);

        const header = [
            { title: 'Nombre', field: 'firstName' },
            { title: 'Apellido', field: 'lastName' },
            { title: 'Email', field: 'email' },
            {
                title: '', cellStyle: { textAlign: 'right' },
                render: row =>
                    <div className="d-flex align-items-center justify-content-end">
                        <button className="btns btns-link text-muted mr-0" type="button" onClick={() => this.verHistorico(row)}>
                            <a data-tip data-for='ot-historico'>
                                <WorkHistoryIcon/>
                            </a>
                            <ReactTooltip place="bottom" id='ot-historico' aria-haspopup='false' >
                                <span>Permiso de Trabajo histórico</span>
                            </ReactTooltip>
                        </button>
                        
                        <span className={`material-icons ${this.colorUserOt(row.id)} txt-44 align-middle`}>noise_control_off</span>
                        <button className="btn btn-link text-muted mr-0" type="button" onClick={() => this.verInscripcion(row)}>
                            <a data-tip data-for='ot'>
                                <span className={`material-icons material-icons-outlined txt-24 align-middle`}>grading</span>
                            </a>
                            <ReactTooltip place="bottom" id='ot' aria-haspopup='false' >
                                <span>Permiso de Trabajo abierto</span>
                            </ReactTooltip>
                        </button>
                        {tengoRolMaximaPrioridad() && <button className="btn btn-link text-muted mr-0" type="button" onClick={() => this.editarUsuario(row)}>
                            <a data-tip data-for='Actualizar-usuario'>
                                <span className="material-icons material-icons-outlined txt-24 align-middle">edit</span>
                            </a>
                            <ReactTooltip place="bottom" id='Actualizar-usuario' aria-haspopup='false' >
                                <span>actualizar</span>
                            </ReactTooltip>
                        </button>}
                    </div>
            }
        ];

        const options = {
            search: true,
            sorting: true,
            fontSize: 6,
            pageSize: this.state.nuevo.limit ? this.state.nuevo.limit : this.state.nuevo.infoTabla.limit,
            filtering: false,
            selection: false,
            pageSizeOptions: [5, 10, 25, 30, 50, 75, 100],
            exportAllData: true
        }

        const defaultMaterialTheme = createTheme();

        let inic = {
            lugar: { id: '' },
            nombre: ''
        }

        return (
            <div className="main-content">
                <UsuarioDialogs
                    openDialog={this.state.openDialog}
                    handleDialogClose={this.handleDialogClose}
                    usuarioEditar={this.state.usuarioEditar}
                />

                <Modal show={this.state.showModal} onHide={this.handleCloseModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Información de los Permisos de Trabajo</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.state.ordenTrabajoData?.length > 0 ? this.state.ordenTrabajoData.map((ordenTrabajo, index) => (
                            <div key={index}>
                                <h5>Permiso de Trabajo {index + 1}</h5>
                                <p><strong>Nombre:</strong> {ordenTrabajo.nombre}</p>
                                {ordenTrabajo.equipos.map((equipo, indexEquipo) => (
                                    <div key={indexEquipo}>
                                        <h6>Equipo a intervenir: {equipo.nombre}</h6>
                                        <ul>
                                            {equipo.equiposAbloquear.map((equipoAbloquear, indexAbloquear) => (
                                                <li key={indexAbloquear}>
                                                    Equipo a bloquear: {equipoAbloquear.nombre}
                                                    <ul>
                                                        {equipoAbloquear.puntosDeBloqueo.map((punto, indexPunto) => (
                                                            <li key={indexPunto}>
                                                                Punto de bloqueo: {punto.nombre}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                ))}
                                <p><strong>Fecha de Fin:</strong> {ordenTrabajo.fechaFin.split('T')[0]}</p>
                                <p><strong>Fecha de Inicio:</strong> {ordenTrabajo.fechaInicio.split('T')[0]}</p>
                                <hr />
                            </div>
                        ))
                            : <p>No hay Permisos de trabajo abiertos</p>
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btns btns-secondary" onClick={this.handleCloseModal}>Cerrar</button>
                    </Modal.Footer>
                </Modal>
                <Modal show={this.state.showModalHistorical} onHide={this.handleCloseModal} size='xl'>
                    <Modal.Header closeButton>
                        <Modal.Title>Historico de los Permisos de Trabajo</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.state.ordenTrabajoDataUserIdHistorical && <UserDP userId={this.state.ordenTrabajoDataUserIdHistorical} listadoDeSitios={this.props.listadoDeSitios} listadoDeUsuarios={this.props.listadoDeUsuarios} listadoDeEquipos={this.props.listadoDeEquipos} />}
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btns btns-secondary" onClick={this.handleCloseModal}>Cerrar</button>
                    </Modal.Footer>
                </Modal>
                <Container fluid>
                    <Row>
                        <Col xs={12} lg={12} className="mt-4">
                            <h2><span className="icon-Icon_Client_man_text_RGB txt-64 text-secundario"></span>Usuarios</h2>
                            {/* <h5 className="text-muted ml-5"><i className="fas fa-list-ul text-muted"></i> Listado</h5>                 */}
                        </Col>
                    </Row>
                    <div className="mt-5 th_secundario">
                        <Formik
                            enableReinitialize={true}
                            innerRef={bagRef}
                            initialValues={inic}
                            onSubmit={(values) => {
                                this.setState({
                                    values: values,
                                    nuevaBusqueda: true
                                }, this.actualizar);
                            }}>
                            {({ values, setFieldValue }) => (
                                <Form>
                                    <Row>
                                        <Col lg={12} xs={12}>
                                            <label htmlFor="nombre"> Nombre / Apellido / Email
                                            </label>
                                            <Field name='nombre' />
                                        </Col>

                                    </Row>
                                    <Row className="justify-content-center mb-4 mt-5">
                                        <Col xs={12} lg={3}>
                                            <button className="btn-block btns-primary btns" type="submit"><i className="fas fa-search"></i> Aplicar filtros y Buscar </button>
                                        </Col>
                                        <Col xs={12} lg={3}>
                                            {tengoRolMaximaPrioridad() && <button className="btn-block btns-info btns" onClick={this.agregarUsuario}><i className="fas fa-plus"></i> Agregar Usuario </button>}
                                        </Col>
                                    </Row>
                                </Form>

                            )}
                        </Formik>
                        {/* <Row className="justify-content-center">
                            <Col xs={12} lg={3}>
                                {tengoRolMaximaPrioridad() && <button className="btn-block btns-info btns" onClick={this.agregarUsuario}><i className="fas fa-plus"></i> Agregar Usuario </button>}
                            </Col>
                        </Row> */}
                    </div>
                    <hr></hr>
                    <div className="th_secundario">
                        <Row className="mt-5 Table-column-sticky-pp">
                            <Col>
                                <ThemeProvider theme={defaultMaterialTheme}>
                                    <MaterialTable
                                        title=''
                                        onSelectionChange={(rows) => this.manejarSeleccion(rows)}
                                        tableRef={tableRef3}
                                        columns={header}
                                        options={options}
                                        data={query =>
                                            this.buscarData(query, false)
                                        }
                                        actions={[
                                            {
                                                icon: () => <>
                                                    <button data-tip data-for='excel' disabled={loading} className="btns btn-csv-exp btns-recursos">
                                                        <span className={loading ? 'd-none ' : 'material-icons'}>cloud_download</span>
                                                        <div className="ml-1 sweet-loading">
                                                            <ClockLoader
                                                                size={25}
                                                                color={"#FFF"}
                                                                loading={loading}
                                                            />
                                                        </div>
                                                    </button>
                                                    <ReactTooltip place="bottom" id='excel' aria-haspopup='false' >
                                                        <span>{loading ? 'Descargando... ' : 'Exportar a Excel'}</span>
                                                    </ReactTooltip>
                                                </>,
                                                onClick: () => !loading ? this.handleExportExcel("descarga.xlsx") : '',
                                                isFreeAction: true
                                            }

                                        ]}
                                        localization={traerPalabrasTabla('Usuario')}
                                        detailPanel={rowData => {
                                            return (
                                                <UserDP rowData={rowData} listadoDeSitios={this.props.listadoDeSitios} listadoDeUsuarios={this.props.listadoDeUsuarios} listadoDeEquipos={this.props.listadoDeEquipos}/>
                                            )
                                        }}
                                        onRowClick={(event, rowData, togglePanel) => togglePanel()}
                                    />
                                </ThemeProvider>
                            </Col>
                        </Row>
                    </div>

                </Container>

                {this.state.alert}
            </div>
        );
    }
}

function mapState(state) {

    const { user } = state.authentication;
    const listadoDeUsuarios = state.users.listado;
    const listadoDeOrdenesDeTrabajo = state.ot.listado;
    const listadoDeEquipos = state.equipos.listado;
    const listadoDeCandados = state.candados.listado;
    const listadoDeSitios = state.sitios.listado;
    return { user, listadoDeUsuarios, listadoDeOrdenesDeTrabajo, listadoDeEquipos, listadoDeCandados, listadoDeSitios };
}

const actionCreators = {
    enviarError: alertActions.enviarError,
    enviarExito: alertActions.enviarExito
}

const connectedBuscadorUsuario = connect(mapState, actionCreators)(BuscadorUsuario);
export { connectedBuscadorUsuario as BuscadorUsuario };