import React from 'react';
import { connect } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import Select from 'react-select';
import { Col, Row, Container, Table } from 'react-bootstrap';
import { alertActions, tengoRolMaximaPrioridad, traerPalabrasTabla, utilActions } from '../../_actions';
import MaterialTable from '@material-table/core';
import { ThemeProvider, createTheme } from '@mui/material';
import { exportToCSV, Role, SitioDP, tengoRol, TIPO_EXPORT, traerMisRoles } from '../../_helpers';
import { sitioService } from '../../_services';
import { buscadorConstants } from '../../_constants';
import ReactTooltip from 'react-tooltip';
import { ClockLoader } from 'react-spinners';
import { OrdenDeTrabajoDP } from '../../_helpers';
import { ordenDeTrabajoService } from '../../_services/ordenDeTrabajo.service';
import OTdialogs from './OTdialogs';
import { Select as MUISelect, MenuItem } from '@mui/material';
import { equipoActions } from '../../_actions/equipo.actions';
const tableRef3 = React.createRef();
const bagRef = React.createRef();
const _ = require('lodash');

class BuscadorOT extends React.Component {

    constructor(props) {
        super(props);
        this.state.nuevo = _.clone(buscadorConstants.infoTablaDefault);
        this.handleDialogOpen = this.handleDialogOpen.bind(this);
    }

    state = {
        loading: false,
        nuevaBusqueda: false,
        sitio: {},
        seleccionados: [],
        openDialog: false,
        otEditar: {},
        activeFilter: true
    }

    actualizar = () => {
        if (tableRef3.current) {
            tableRef3.current.state.query.page = 0;
            tableRef3.current.dataManager.currentPage = 0;
            tableRef3.current.onQueryChange();
        }
    }

    handleClose = () => {
        this.setState((prevState) => {
            return {
                sitio: {},
                listado: undefined,
                alert: undefined
            }
        });
    }

    componentDidMount() {
    }

    buscarData = (query) => {
        // console.log("query", query)
        return new Promise((resolve, reject) => {

            if (this.state.nuevaBusqueda) query.page = 0

            let dataSearch = {
                searchValue: query.search,
                page: query.page,
                pageSize: query.pageSize,
                sortOrders: [
                    {
                        field: query.orderBy ? query.orderBy : 'nombre',
                        direction: query.orderDirection ? query.orderDirection : 'ASC'
                    }
                ],
                options: this.state
            };

            ordenDeTrabajoService.buscar(dataSearch)
                .then(
                    data => {
                        this.setState({ nuevaBusqueda: false });
                        data.content = tengoRolMaximaPrioridad() ? data?.content : data.content.filter( PdT => PdT.usuarios?.includes(this.props?.user?.id) )
                        resolve({
                            pageSize: query.pageSize,
                            data: data.content ? data.content : [],
                            page: query.page,
                            totalCount: data ? data.totalElements : 0
                        })
                    },
                    error => {
                        this.props.enviarError(error);
                    }
                );
        })
    }

    handleExportExcel(fileName) {
        this.buscarData(this.state.nuevo, true).then(
            data => {
                exportToCSV(utilActions.listaParaExport(data, TIPO_EXPORT.SITIO), fileName)
            },
            error => {
                console.log("Error", error)
            }
        );
    }

    exportarPDF = () => {
        let lookupquery = _.clone(this.state.nuevo);
        lookupquery.orderBy = tableRef3.current.state.query.orderBy;
        lookupquery.orderDirection = tableRef3.current.state.query.orderDirection;

        this.buscarData(lookupquery, true).then(
            data => {
                this.setState(() => {
                    return { showPDF: true, data: data };
                });
            },
            error => {
                console.log("Error", error)
            }
        );
    }

    handleExportPDF(filename) {
        this.setState({
            camposBusqueda: {
                values: bagRef.current.values
            }

        }, this.exportarPDF);
    }

    agregarOT = () => {
        this.handleDialogOpen();
    }

    handleDialogOpen = () => {
        this.setState({ openDialog: true });
    }

    handleDialogClose = () => {
        this.setState({ openDialog: false, otEditar: {} });
    }

    editarOT = (ot) => {
        this.setState({
            otEditar: {
                id: ot.id,
                nombre: ot.nombre,
                descripcion: ot.descripcion,
                equipo_intervenido: ot.equipo_intervenido,
                sintoma: ot.sintoma,
                causa: ot.causa,
                nombreEmpresaDeMantenimiento: ot.nombreEmpresaDeMantenimiento,
                sitioId: ot.sitioId,
                riesgos: ot.riesgos,
                puntosDeBloqueo: ot.puntosDeBloqueo,
                fechaFin: ot.fechaFin.split('T')[0],
                fechaInicio: ot.fechaInicio.split('T')[0],
                equipos: ot.equipos,
                usuarios: ot.usuarios,
                candados: ot.candados,
                deleted: ot.deleted
            }
        })
        this.handleDialogOpen();
    }

    handleSelectChangeActiveFilter = (event) => {
        this.setState({ activeFilter: event.target.value });
    };

    soyAdmin = () => {
        const roles = traerMisRoles();
        return roles.filter(rol => rol === "ADMIN").length > 0;
    }

    render() {

        const { loading } = this.state;

        //const soyAdmin = tengoRol([Role.ADMIN]);
        const colorOrdenes = this.props.listadoDeOrdenesDeTrabajo?.reduce((obj, ot) => {
            const candados = ot.candados
                .map(candadoId => this.props.listadoDeCandados?.find(candadoOT => candadoOT.id === candadoId))
                .map(candado => candado?.locked === true && candado?.bloqueos?.length > 1);
            const todosVerdaderos = candados.every(val => val === true);
            const algunoVerdadero = candados.some(val => val === true);

            if( candados.length === 0 ) obj[ot.id] = "danger"; // No tiene candados
            else 
            if (todosVerdaderos) {
                obj[ot.id] = "verde-s"; // Todos son true
            } else if (algunoVerdadero) {
                obj[ot.id] = "amarillo-s"; // Al menos uno es true
            } else {
                obj[ot.id] = "danger"; // Todos son false
            }

            return obj;
        }, {});

        const header = [
            { title: 'ID', field: 'id', sorting: false},
            { title: 'Nombre', field: 'nombre', sorting: false },
            { title: 'Empresa de mantenimiento', field: 'nombreEmpresaDeMantenimiento', sorting: false },
            {
                title: '', cellStyle: { textAlign: 'right' },
                render: row =>
                    <div className="d-iflex text-right">
                        <span className={`material-icons text-${colorOrdenes?.[row?.id]} txt-44 align-middle`}>noise_control_off</span>
                        {tengoRolMaximaPrioridad() && <button className="btns btns-link text-muted mr-0" type="button" onClick={() => this.editarOT(row)}>
                            <a data-tip data-for='Actualizar-candado'>
                                <span className="material-icons material-icons-outlined txt-24">edit</span>
                            </a>
                            <ReactTooltip place="bottom" id='Actualizar-candado' aria-haspopup='false' >
                                <span>actualizar</span>
                            </ReactTooltip>
                        </button>}
                    </div>
            }
        ];

        const options = {
            search: false,
            sorting: true,
            fontSize: 6,
            pageSize: this.state.nuevo.limit ? this.state.nuevo.limit : this.state.nuevo.infoTabla.limit,
            filtering: false,
            selection: false,
            pageSizeOptions: [5, 10, 25, 30, 50, 75, 100],
            exportAllData: false
        }

        const optionsFilterActive = [
            { value: true, label: 'Activas' },
            { value: false, label: 'Cerradas' },    
            { value: null, label: 'Eliminadas' }
        ].filter(option => option.value !== null || this.soyAdmin());

        const defaultMaterialTheme = createTheme();

        let inic = {
            nombre: ''
        }

        const customStyles = {
            control: (base) => ({
                ...base,
                minHeight: '34px',
                height: '34px',
                minWidth: '200px',
                borderRadius: '4px',
            }),
            dropdownIndicator: (base) => ({
                ...base,
                padding: '4px',
            }),
        };

        return (
            <div className="main-content">
                <OTdialogs
                    openDialog={this.state.openDialog}
                    handleDialogClose={this.handleDialogClose}
                    listadoDeSitios={this.props.listadoDeSitios}
                    listadoDeEquipos={this.props.listadoDeEquipos}
                    listadoDeUsuarios={this.props.listadoDeUsuarios}
                    listadoDeCandados={this.props.listadoDeCandados}
                    listadoDeOrdenesDeTrabajo={this.props.listadoDeOrdenesDeTrabajo}
                    listadoDeEmpresasDeMantenimiento={this.props.listadoDeEmpresasDeMantenimiento}
                    userCandados={this.props.userCandados}
                    otEditar={this.state.otEditar}
                    mensajeExito={this.props.enviarExito}
                    mensajeError={this.props.enviarError}
                    actualizarEquipos={this.props.traerEquipos}
                />
                <Container fluid>
                    <Row>
                        <Col xs={12} lg={12} className="mt-4">
                            <h2><span className="icon-Icon_Installation_commissioning_text_RGB txt-64 text-secundario"></span> Permisos de trabajo</h2>
                            {/* <h5 className="text-muted ml-5"><i className="fas fa-list-ul text-muted"></i> Listado</h5>                 */}
                        </Col>
                    </Row>
                    <div className="mt-5 th_cielo">
                        <Formik
                            enableReinitialize={true}
                            innerRef={bagRef}
                            initialValues={inic}
                            onSubmit={(values) => {
                                this.setState({
                                    values: values,
                                    nuevaBusqueda: true
                                }, this.actualizar);
                            }}>
                            {({ values, setFieldValue }) => (
                                <Form>
                                    <Row>
                                        <Col lg={6} xs={12}>
                                            <label htmlFor="nombre"> Nombre
                                            </label>
                                            <Field name='nombre' />
                                        </Col>
                                        <Col lg={6} xs={12}>
                                            <label htmlFor="Estado"> Estado
                                            </label>
                                            <Select
                                                className="react-select-container flex-grow-1"
                                                styles={customStyles}
                                                classNamePrefix="select"
                                                defaultValue={optionsFilterActive[0]} // Asegúrate de que 'options' esté definido y contenga las opciones deseadas
                                                isDisabled={false}
                                                isLoading={false}
                                                isRtl={false}
                                                name="action-select"
                                                options={optionsFilterActive}
                                                onChange={(selectedOption) => {
                                                    this.setState({ activeFilter: selectedOption.value });
                                                    tableRef3.current.state.query.page = 0;
                                                    tableRef3.current.dataManager.currentPage = 0;
                                                    tableRef3.current.onQueryChange();
                                                }}
                                                theme={theme => ({
                                                    ...theme,
                                                    borderRadius: 10,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary25: '#00b0bd',
                                                        primary: '#00b0bd',
                                                    },
                                                })}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="justify-content-center mb-4 mt-5">
                                        <Col xs={12} lg={3}>
                                            <button className="btn-block btns-primary btns" type="submit" ><i className="fas fa-search"></i> Aplicar filtros y Buscar </button>
                                        </Col>
                                        <Col xs={12} lg={3}>
                                            {tengoRolMaximaPrioridad() && <button className="btn-block btns-info btns" onClick={this.agregarOT}><i className="fas fa-plus"></i> Agregar Permiso de Trabajo </button>}
                                        </Col>
                                    </Row>
                                </Form>

                            )}
                        </Formik>
                        {/* <Row className="justify-content-center">
                            <Col xs={12} lg={3}>
                                {tengoRolMaximaPrioridad() && <button className="btn-block btns-primary btns" onClick={this.agregarOT}><i className="fas fa-plus"></i> Agregar Permiso de Trabajo </button>}
                            </Col>
                        </Row> */}
                    </div>
                    <hr></hr>
                    <div className="th_secundario">
                        <Row className="mt-5 th_info Table-column-sticky-pp">
                            <Col>
                                <ThemeProvider theme={defaultMaterialTheme}>
                                    <MaterialTable
                                        title=''
                                        tableRef={tableRef3}
                                        columns={header}
                                        options={options}
                                        data={async query => {
                                            const datos = await this.buscarData(query, false);
                                            datos.data = datos.data.filter(ot => ot.deleted === !this.state.activeFilter)
                                            return datos
                                        }
                                        }
                                        actions={[
                                            {
                                                icon: () => (
                                                    !loading && (
                                                        <></>
                                                    )
                                                ),
                                                tooltip: 'Seleccionar acción',
                                                isFreeAction: true,
                                                onClick: () => { }, // Esto puede permanecer vacío si la selección se maneja internamente
                                            }
                                        ]}
                                        detailPanel={rowData => {
                                            return (<>
                                                <OrdenDeTrabajoDP rowData={rowData} listadoDeSitios={this.props.listadoDeSitios} listadoDeUsuarios={this.props.listadoDeUsuarios} listadoDeEquipos={this.props.listadoDeEquipos} />

                                            </>
                                            )
                                        }}
                                        localization={traerPalabrasTabla('Permiso de Trabajo')}
                                        onRowClick={(event, rowData, togglePanel) => togglePanel()}
                                    />
                                </ThemeProvider>
                            </Col>
                        </Row>
                    </div>

                </Container>
            </div>
        );
    }
}

function mapState(state) {

    const { user } = state.authentication;
    const listadoDeSitios = state.sitios.listado;
    const listadoDeEquipos = state.equipos.listado;
    const listadoDeUsuarios = state.users.listado;
    const listadoDeCandados = state.candados.listado;
    const listadoDeOrdenesDeTrabajo = state.ot.listado;
    const listadoDeEmpresasDeMantenimiento = state.ot.empresas;
    const { userCandados } = state.users;
    return { userCandados, user, listadoDeSitios, listadoDeEquipos, listadoDeUsuarios, listadoDeCandados, listadoDeOrdenesDeTrabajo, listadoDeEmpresasDeMantenimiento };
}

const actionCreators = {
    enviarError: alertActions.enviarError,
    enviarExito: alertActions.enviarExito,
    traerEquipos: equipoActions.traerEquipos,
}

const connectedBuscadorOT = connect(mapState, actionCreators)(BuscadorOT);
export { connectedBuscadorOT as BuscadorOT };