import React, { useRef, useEffect } from 'react';
import { Carousel } from 'react-bootstrap';

const useMediaQuery = window.matchMedia("(max-width: 600px)");

const imagesPC = [
  {
    original: process.env.PUBLIC_URL+'/tutorial_candados/1_PC.jpg',
    text: 'Paso 1: Hacer clic en confirmar para abrir el dialogo bluetooth',
  },
  {
    original: process.env.PUBLIC_URL+'/tutorial_candados/2_PC.jpg',
    text: 'Paso 2: Si no aparece escaneando como en la imagen, asegurarse que el bluetooth este encendido',
  },
  {
    original: process.env.PUBLIC_URL+'/tutorial_candados/1_candado_PC.jpeg',
    text: 'Paso 3: Precionar el boton central del candado para despertar el candado y que aparezca en la lista de dispositivos bluetooth',
  },
  {
    original: process.env.PUBLIC_URL+'/tutorial_candados/2_candado_PC.jpeg',
    text: 'Paso 4: Si la luz es azul y parpadea, el candado esta listo para conectarse',
  },
  {
    original: process.env.PUBLIC_URL+'/tutorial_candados/3_PC.jpg',
    text: 'Paso 5: Hacer clic en el candado y emparejarlo con el dispositivo',
  },
];

const imagesMobile = [
  {
    original: process.env.PUBLIC_URL+'/tutorial_candados/1_mobile.jpeg',
    text: 'Paso 1: Hacer clic en confirmar para abrir el dialogo bluetooth',
  },
  {
    original: process.env.PUBLIC_URL+'/tutorial_candados/2_mobile.jpeg',
    text: 'Paso 2: Asegurarse que el bluetooth este encendido',
  },
  {
    original: process.env.PUBLIC_URL+'/tutorial_candados/3_mobile.jpeg',
    text: 'Paso 3: Si aparece como en la imagen esta escaneando',
  },
  {
    original: process.env.PUBLIC_URL+'/tutorial_candados/1_candado.jpeg',
    text: 'Paso 4: Precionar el boton central del candado para despertar el candado y que aparezca en la lista de dispositivos bluetooth',
  },
  {
    original: process.env.PUBLIC_URL+'/tutorial_candados/2_candado.jpeg',
    text: 'Paso 5: Si la luz es azul y parpadea, el candado esta listo para conectarse',
  },
  {
    original: process.env.PUBLIC_URL+'/tutorial_candados/4_mobile.jpeg',
    text: 'Paso 6: Presionar en el candado, luego presionar sincronizar se empareja con el dispositivo',
  },
  {
    original: process.env.PUBLIC_URL+'/tutorial_candados/5_mobile.jpeg',
    text: 'Paso 7: Una vez emparejado, al precionar sincronizar se abrira el candado',
  },
];

const mobileStyles = {
  galleryContainer: {
    width: '100%',
    height: '',
  },
  image: {
    maxHeight: 'calc(100vh - 50px)', 
    width: 'auto',
    margin: 'auto', 
    display: 'block',
  },
};

const CandadoTutorialGalery = ({ children }) => {
  const [hide, setHide] = React.useState(true);
  const [fullScreen, setFullScreen] = React.useState(false);
  const carouselRef = useRef(null);

  useEffect(() => {
    const handleFullScreenChange = () => {
      setFullScreen(!!document.fullscreenElement);
    };

    document.addEventListener('fullscreenchange', handleFullScreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullScreenChange);
    };
  }, []);

  const toggleFullScreen = () => {
    if (!fullScreen) {
      const element = carouselRef.current;

      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen();
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }
  };

  return (
    <>
      {children}
      <button className="btn-block btns-primary btns mt-2 mb-2" onClick={() => setHide(!hide)}>
        { hide ? "Ver" : "Ocultar"} tutorial
      </button>
      {!hide &&  <button className="btn-block btns-primary btns mt-2 mb-2" onClick={toggleFullScreen}>
        {fullScreen ? 'Salir de pantalla completa' : 'Ver pantalla completa'}
      </button>}
      {!hide && (
          <div className={`full-screen-carousel${fullScreen ? ' full-screen-mode' : ''}`} ref={carouselRef}>
            <Carousel>
              {(useMediaQuery.matches ? imagesMobile : imagesPC).map((image, index) => (
                <Carousel.Item key={index}>
                  <img
                    className="d-block w-100"
                    src={image.original}
                    alt={`Slide ${index + 1}`}
                    style={fullScreen ? { ...mobileStyles.image, maxHeight: 'calc(100vh - 50px)' } : mobileStyles.image}
                  />
                  <Carousel.Caption style={{ bottom: '0', marginBottom: '10px', textAlign: 'center' }}>
                    <p>{image.text}</p>
                  </Carousel.Caption>
                </Carousel.Item>
              ))}
            </Carousel>
          </div>
        )}
    </>
  );
};

export default CandadoTutorialGalery;
