import React from 'react';
import { connect } from 'react-redux';
import { Navbar, NavDropdown } from 'react-bootstrap';
import { userActions } from '../../_actions';
import ReactTooltip from 'react-tooltip';

class NavBar extends React.Component {

    state = {
    };

    cerrarSesion = () => {
        this.props.logout();
    }

    render() {
        const { user } = this.props;
        const isLogged = user !== undefined && user !== null;
        return (<>
            <Navbar className="bg-primario" sticky="top" expand="lg">
                <Navbar.Brand className="d-flex align-items-center">
                    <img
                        src={process.env.PUBLIC_URL + '/images/logo.png'}
                        alt="Logo"
                        style={{ maxHeight: '50px', marginRight: '10px' }} // Ajustar según necesidades
                    />
                    <div>
                        <h1 className="mb-0">
                            <small className="text-white txt-12 font-weight-bold">v.2.7</small>
                        </h1>
                        {/* {isLogged && <span className="text-white p-5">{user?.email}</span>} */}
                    </div>
                </Navbar.Brand>
                <>
                    {/*isLogged && <div className="iconos-nav">
                    <a data-tip data-for='sett'>
                    <span className="material-icons material-icons-outlined align-middle">settings</span>
                    <ReactTooltip place="bottom" id='sett' aria-haspopup='false' >
                    <span>Settings</span>
                    </ReactTooltip>
                    </a>
                    <a data-tip data-for='help'>
                     <span className="material-icons material-icons-outlined align-middle ml-4">help_outline</span>
                    <ReactTooltip place="bottom" id='help' aria-haspopup='false' >
                    <span>Ayuda</span>
                    </ReactTooltip>
                    </a>   
        </div>*/}

                    {isLogged && <div className="derecha">
                        <ul className="">
                            <li className="nav-item ml-3 mt-3 d-lg-block d-xl-block d-none txt-19 move">
                                <a className="nav-link font-weight-light text-white" href="#">
                                    <i className="fas fa-user-circle"></i> <span className="font-weight-bold txt-22"> {user.email}</span>
                                    <h6 className="font-weight-light scrolling text-truncate">
                                    </h6></a>
                            </li>
                        </ul>
                    </div>}
                    {isLogged && <div className="drop-nav">
                            <NavDropdown id="basic-nav-dropdown" title={<div><i className="text-secundario fas fa-ellipsis-v"></i></div>}>
                            <div className="d-lg-none">
                            <NavDropdown.Item className="font-weight-bold txt-18 text-secundario "><i className="fas fa-user-circle"></i> {user.email} </NavDropdown.Item>
                            <NavDropdown.Divider />
                            </div>
                            <NavDropdown.Item className="text-dark font-weight-bold txt-18" href="mailto:email@gmail.com"><i className="fas fa-question-circle align-middle text-secundario"></i> Ayuda</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item className="text-dark font-weight-bold txt-18" onClick={() => this.cerrarSesion()} >
                                <i className="fas fa-sign-out-alt align-middle text-secundario"></i> Salir
                            </NavDropdown.Item>
                        </NavDropdown>
                    </div>}
                </>
            </Navbar>
            <div className="text-secundario header-nav">
                {process.env.NODE_ENV !== 'production' &&
                    <div className="ambiente d-none d-xl-block">
                        <span> AMBIENTE: <b>{process.env.NODE_ENV}</b></span>
                    </div>
                }
            </div></>
        );
    }
}

function mapState(state) {
    const { user } = state.authentication;
    return { user };
}

const actionCreators = {
    logout: userActions.logout
}

const connectedNavBar = connect(mapState, actionCreators)(NavBar);
export { connectedNavBar as NavBar };