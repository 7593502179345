import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Select from 'react-select';
import { candadoService, ordenDeTrabajoService } from '../../_services';
import { conectarDispositivo, enviarComandoToken, history, manejarDatosRecibidos, obtenerCaracteristicas, traerMisRoles } from '../../_helpers';
import FormWizard from "react-form-wizard-component";
import CreatableSelect from 'react-select/creatable';
import { SitioDialogs } from '../sitio/SitioDialogs';
import 'react-form-wizard-component/dist/style.css';
import {
    Card,
    Alert,
    CardContent,
    Typography,
    Box,
    List,
    ListItem,
    FormControl,
    InputLabel,
    MenuItem,
    Select as MuiSelect,
    Grid,
} from '@mui/material';
import { tengoRolMaximaPrioridad } from '../../_actions';
import ReactTooltip from 'react-tooltip';
import { EquipoDialogs } from '../equipo/EquipoDialogs';
import { UsuarioDialogs } from '../usuario/UsuarioDialogs';
import { RIESGOS } from '../../_constants/riesgos.constrans';
import ConfirmacionDialog from '../candado/ConfirmacionDialog';
import { EmpresaDeMantenimientoDialogs } from './EmpresaDeMantenimientoDialogs';
const otInicial = {
    nombre: "",
    descripcion: "",
    equipo_intervenido: "",
    sintoma: "",
    causa: "",
    nombreEmpresaDeMantenimiento: "",
    sitioId: 0,
    riesgos: [],
    puntosDeBloqueo: "",
    fechaFin: (new Date()).toISOString(),
    fechaInicio: (new Date()).toISOString(),
    equipos: [],
    usuarios: [],
    candados: []
}

class OTdialogs extends React.Component {

    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.handleDialogCloseSitio = this.handleDialogCloseSitio.bind(this);
    }

    state = {
        confirmDialogOpen: false,
        nuevaOT: otInicial,
        OTDelete: null,
        candadosAsignados: {},
        openDialogSitio: false,
        openDialogUsuario: false,
        openDialogEquipo: false,
        openDialogEmpresaDeMantenimiento: false,
        showConfirmacionDialog: false,
        candadoSeleccionado: null,
        dialogAction: null,
        textDialogConfirm: '',
        titleDialogConfirm: '',      
        camposVacios: [],
        alertaDeError: ''  
    }

    componentDidMount() {
        this.setState({
            confirmDialogOpen: false,
            nuevaOT: { ...otInicial },
            OTDelete: null,
            candadosAsignados: {},
            equipoAbloquear: null,
            camposVacios: [],
            alertaDeError: '' 
        })
    }

    handleInputChange = (event) => {
        const { name, value } = event.target;
        this.setState(prevState => ({
            nuevaOT: {
                ...prevState.nuevaOT,
                [name]: value
            },
            camposVacios: prevState.camposVacios?.filter((campo) => campo !== name),
        }));
    }
    closeConfirmDialog = () => {
        this.setState({
            confirmDialogOpen: false,
        });
    }
    handleDeleteConfirmation = () => {
        ordenDeTrabajoService.eliminarOrdenDeTrabajo(this.state.OTDelete, this.soyAdmin())
            .then(() => {
                this.props.mensajeExito("PdT eliminado correctamente");
                setTimeout(() => {
                    window.location.reload()
                }, 1000);
            })
            .catch(error => {
                this.props.mensajeError(error);
            });
        this.closeConfirmDialog();
        this.props.handleDialogClose();
    }

    handleSubmit = () => {
        console.log("Enviando", this.state.nuevaOT);
        // if( Array.isArray(this.state.nuevaOT?.equipos) ) {
        //     this.props.mensajeError("No tiene equipo asignado")
        //     this.props.handleDialogClose();
        //     return;
        // };
        const { nuevaOT } = this.state;
        const camposVacios = [];
        if (!nuevaOT.nombre) camposVacios.push('Nombre')
        if (!nuevaOT.descripcion) camposVacios.push('Descripcion')
        if (!nuevaOT.sintoma) camposVacios.push('Sintoma')
        if (!nuevaOT.causa) camposVacios.push('Causa')
        if ( nuevaOT.fechaInicio == otInicial.fechaInicio ) camposVacios.push('Fecha de Inicio')
        if ( nuevaOT.fechaFin == otInicial.fechaFin ) camposVacios.push('Fecha de Fin')
        if ( nuevaOT.sitioId == 0) camposVacios.push('Sitio')
        if (!nuevaOT.nombreEmpresaDeMantenimiento) camposVacios.push('Empresa de Mantenimiento')
        if (!nuevaOT.equipos) camposVacios.push('Equipo')
        if (nuevaOT.usuarios?.length == 0 ) camposVacios.push('Usuario')
        if (nuevaOT.candados?.length == 0 ) camposVacios.push('')
        if ( nuevaOT.fechaInicio >= nuevaOT.fechaFin ) camposVacios.push('Nota: La fecha de inicio debe ser mayor a la fecha de fin')
        this.setState({ camposVacios });

        if (camposVacios.length > 0) {
            const mensajeError = nuevaOT.candados?.length === 0
                ? `Falta asignar un candado. ${camposVacios.length > 1 ? `Además, faltan los campos: ${camposVacios.join(", ")}.` : ""}`
                : camposVacios.length === 1 && nuevaOT.fechaInicio >= nuevaOT.fechaFin ? "La fecha de inicio debe ser mayor a la fecha de fin" : `Faltan los campos: ${camposVacios.join(", ")}.`;
        
            this.setState({ alertaDeError: mensajeError });
            return;
        }        

        this.props.otEditar.id
            ? ordenDeTrabajoService.editarOrdenDeTrabajo(this.state.nuevaOT, this.props.otEditar.id)
                .then(() => {
                    this.props.mensajeExito("Permiso de Trabajo Actualizado correctamente");
                    setTimeout(() => {
                        window.location.reload()
                    }, 1000);
                })
                .catch(error => {
                    this.props.mensajeError(error);
                })
            : ordenDeTrabajoService.agregarOrdenDeTrabajo(this.state.nuevaOT)
                .then(() => {
                    this.props.mensajeExito("Permiso de Trabajo creado correctamente");
                    setTimeout(() => {
                        window.location.reload()
                    }, 1000);
                })
                .catch(error => {
                    this.props.mensajeError(error);
                });
        this.props.handleDialogClose();
    }

    openConfirmDialog = (otId) => {
        this.setState({
            confirmDialogOpen: true,
            OTDelete: otId
        });
    }

    handleSelectChange = (name, selectedOption, isMulti = false) => {
        console.log("selectedOption", selectedOption);
        const selectedValues = isMulti
            ? selectedOption ? selectedOption.map(option => option.value) : []
            : selectedOption ? selectedOption.value : null;

        if (name === "equipos") {
            //const equiposCompleto = selectedOption?.map(select => this.props.listadoDeEquipos?.find(equipo => equipo.id === select.value));
            console.log("selectedValues", selectedValues)
            const equipoCompleto = this.props.listadoDeEquipos?.filter(equipo => selectedValues === equipo.id);
            const candados = equipoCompleto?.flatMap(equipo => equipo?.equiposAbloquear?.flatMap(equipoAbloquear => equipoAbloquear.puntosDeBloqueo?.map(punto => punto.candadoId)));

            const candadosUnicos = [...new Set(candados?.filter(candadoId => candadoId != null))];

            const riesgoDeEquipo = this.props.listadoDeEquipos?.find(equipo => equipo.id === selectedValues)?.peligros

            this.setState(prevState => ({
                nuevaOT: {
                    ...prevState.nuevaOT,
                    riesgos: [...new Set([ ...riesgoDeEquipo ])]
                }
            }));
            this.setState(prevState => ({
                nuevaOT: {
                    ...prevState.nuevaOT,
                    ["candados"]: candadosUnicos
                }
            }));
        }
        this.setState(prevState => ({
            nuevaOT: {
                ...prevState.nuevaOT,
                [name]: selectedValues
            }
        }));
    }

    componentDidUpdate(prevProps) {
        if (this.props.otEditar && this.props.otEditar !== prevProps.otEditar) {
            this.setState({
                nuevaOT: {
                    ...otInicial, // Restablece cualquier estado previo a los valores por defecto
                    ...this.props.otEditar, // Sobrescribe con los valores actuales para editar
                    equipos: this.props.otEditar.equipos?.[0] || this.props.otEditar.equipos,
                },
            });
        }        
        if (this.props.otEditar !== prevProps.otEditar) {
            let riesgosOTEditar = this.props.otEditar.riesgos ? this.props.otEditar.riesgos.split(',') : [];
            let riesgosDeEquipos = this.props.otEditar?.equipos?.flatMap(equipoId => {
                const equipo = this.props.listadoDeEquipos?.find(e => e.id === equipoId);
                return equipo?.peligros || [];
            });
            if (riesgosDeEquipos !== undefined) {
                // console.log("riesgosDeEquipos", riesgosDeEquipos);
                this.setState(prevState => ({
                    nuevaOT: {
                        ...prevState.nuevaOT,
                        riesgos: [ riesgosDeEquipos ]
                    }
                }));
            }else{
                this.setState(prevState => ({
                    nuevaOT: {
                        ...prevState.nuevaOT,
                        riesgos: [...new Set([...prevState.nuevaOT?.riesgos, ...riesgosOTEditar])]
                    }
                }));
            }
            this.cargarCandadosDefault();
            if (this.props.otEditar?.equipos) {
                const riesgosDeEquipos = this.props.otEditar.equipos.flatMap(equipoId => {
                    const equipo = this.props.listadoDeEquipos?.find(e => e.id === equipoId);
                    return equipo?.peligros || [];
                });
                //this.actualizarRiesgos(riesgosDeEquipos);
                let riesgosCombinados = [...new Set([...riesgosOTEditar, ...riesgosDeEquipos?.map( riesgo => riesgo )])];
                this.setState(prevState => ({
                    nuevaOT: {
                        ...prevState.nuevaOT,
                        riesgos: riesgosCombinados
                    }
                }));
            }
        }
    }

    handleSelectChangeCandado = (puntoId, event) => {
        const { value } = event.target;
        this.setState(prevState => ({
            candadosAsignados: {
                ...prevState.candadosAsignados,
                [puntoId]: value
            }
        }), () => {
            this.actualizarCandados(puntoId, value);
        });
    }

    actualizarCandados = (puntoId, nuevoCandadoId) => {
        this.setState(prevState => {
            const candadosActuales = new Set(prevState.nuevaOT.candados);

            Object.entries(prevState.candadosAsignados).forEach(([id, candadoId]) => {
                if (id !== puntoId && candadoId === nuevoCandadoId) {
                    candadosActuales.delete(candadoId);
                }
            });
            if (nuevoCandadoId) {
                candadosActuales.add(nuevoCandadoId);
            } else {
                candadosActuales.delete(prevState.candadosAsignados[puntoId]);
            }

            return {
                nuevaOT: {
                    ...prevState.nuevaOT,
                    candados: Array.from(candadosActuales)
                }
            };
        });
    }

    asignarCandado = (puntoId, EliminarPunto = false, candadoDelete = undefined) => {
        const candadoId = this.state.candadosAsignados[puntoId];
        if (!candadoId && !EliminarPunto) return; // Si no hay candado seleccionado, no hacer nada

        candadoService.asignarCandado({ puntoId, candadoId: EliminarPunto ? null : candadoId })
            .then(() => {
                this.props.mensajeExito(EliminarPunto ? "Candado Eliminado" : "Candado asignado correctamente");

                const candadoOptionsActualizadas = this.state.candadoOptions?.filter(candado => candado.value !== candadoId);
                //se trae la nueva lista de equipos actualizado
                this.props.actualizarEquipos();
                //se actualiza el estado
                this.setState(prevState => {
                    let candadosActualizados = new Set(prevState.nuevaOT.candados);

                    if (EliminarPunto) {
                        candadosActualizados = Array.from(candadosActualizados).filter(candado => candado !== candadoDelete);
                    } else {
                        candadosActualizados.add(candadoId);
                    }

                    return {
                        nuevaOT: {
                            ...prevState.nuevaOT,
                            candados: Array.from(candadosActualizados)
                        },
                        candadosAsignados: {
                            ...prevState.candadosAsignados,
                            [puntoId]: '' // Quitar la selección del candado
                        },
                        candadoOptions: candadoOptionsActualizadas
                    };
                });
            })
            .catch(error => {
                this.props.mensajeError(error);
            });
    }

    abrirConfirmacionDialog = (candado) => {
        this.setState({ showConfirmacionDialog: true, candadoSeleccionado: candado, dialogAction: () => this.abrirCandado(candado), textDialogConfirm: "¿Estás seguro que quieres abrir este candado?", titleDialogConfirm: "Abrir Candado" });
    }

    cerrarConfirmacionDialog = () => {
        this.setState({ showConfirmacionDialog: false, candadoSeleccionado: null, dialogAction: null, textDialogConfirm: "", titleDialogConfirm: "" });
    }

    abrirCandado = async (candado, encriptar = true) => {
        try {
            const device = await conectarDispositivo(candado);
            console.log("Pude conectar")
            const { characteristicWrite, characteristicRead } = await obtenerCaracteristicas(device);
            console.log("Obtener caracteristicas OK")
            characteristicRead.startNotifications();
            console.log("Start notifications OK")
            characteristicRead.addEventListener('characteristicvaluechanged', async (event) => {
                await manejarDatosRecibidos(event, encriptar, characteristicWrite, candado);
            });
            console.log("Agregado listener OK")
            await enviarComandoToken(characteristicWrite, encriptar, candado);
            console.log("Enviado comando OK")
        } catch (error) {
            console.log("Error al abrir el candado:", error);
        }
        this.setState({ showConfirmacionDialog: false, candadoSeleccionado: null, dialogAction: null, textDialogConfirm: "", titleDialogConfirm: "" });
    };

    puedeIniciarCerrado = (candado) => {
        if (tengoRolMaximaPrioridad()) {
            return true;
        } else return false;
    }

    handleCreateSitio = () => {
        this.setState({
            openDialogSitio: true
        })
    };

    handleDialogCloseSitio = () => {
        this.setState({
            openDialogSitio: false
        })
    }
    handleDialogCloseUsuario = () => {
        this.setState({
            openDialogUsuario: false
        })
    }
    handleDialogCloseEquipo = () => {
        this.setState({
            openDialogEquipo: false
        })
    }
    handleDialogCloseEmpresaDeMantenimiento = () => {
        this.setState({
            openDialogEmpresaDeMantenimiento: false
        })
    }
    abrirConfirmacionDialog = (candado) => {
        this.setState({ showConfirmacionDialog: true, candadoSeleccionado: candado, dialogAction: () => this.abrirCandado(candado), textDialogConfirm: "¿Estás seguro que quieres abrir este candado?", titleDialogConfirm: "Abrir Candado" });
    }
    cargarCandadosDefault() {
        const { otEditar, listadoDeEquipos } = this.props;
        /* eslint-disable no-unused-expressions */
        otEditar.equipos?.forEach(equipoId => {
            const equipo = listadoDeEquipos?.find(e => e.id === equipoId);
            equipo?.equiposAbloquear?.forEach(equipoAbloquear => {
                equipoAbloquear?.puntosDeBloqueo?.forEach(punto => {
                    this.actualizarCandados(punto.id, punto.candadoId);
                });
            });
        });
    }

    soyAdmin = () => {
        const roles = traerMisRoles();
        return roles.filter(rol => rol === "ADMIN").length > 0;
    }


    render() {
        const { userCandados, otEditar, handleDialogClose, openDialog, listadoDeSitios, listadoDeEquipos, listadoDeUsuarios, listadoDeCandados, mensajeExito, mensajeError, listadoDeOrdenesDeTrabajo, listadoDeEmpresasDeMantenimiento } = this.props; // datos de la ot que estan en el listado
        // console.log("ot candado", this.state.nuevaOT.candados);
        const sitioOptions = listadoDeSitios?.map(sitio => {
            return {
                value: sitio.id,
                label: sitio.nombre
            }
        });

        const empresasDeMantenimientoOptions = listadoDeEmpresasDeMantenimiento?.map(empresa => {
            return {
                value: empresa.nombre,
                label: empresa.nombre
            }
        });

        const equiposUsadosIds = listadoDeOrdenesDeTrabajo?.flatMap(ot => ot.equipos);
        const equipoOptions = listadoDeEquipos?.filter( equipo => (!equiposUsadosIds?.includes(equipo.id) || equipo.id === otEditar.equipos?.[0]) )?.map(equipo => {
            return {
                value: equipo.id,
                label: equipo.nombre,
                puntosDeBloqueo: equipo.puntosDeBloqueo
            }
        });

        const defaultEquipoOptions = otEditar.equipos?.map(equipoId =>
            equipoOptions?.find(option => option.value === equipoId)
        );

        const usuarioOptions = listadoDeUsuarios?.map(usuario => {
            return {
                value: usuario.id,
                label: usuario.firstName + " " + usuario.lastName
            }
        });

        const candadoDefaultOptions = listadoDeCandados?.map(candado => {
            return {
                value: candado.id,
                label: candado.nombre
            }
        });

        const defaultUsuarioOptions = otEditar.usuarios?.map(usuarioId =>
            usuarioOptions?.find(option => option.value === usuarioId)
        );

        const riesgosOptions = Object.keys(RIESGOS)?.map(key => ({
            value: key,
            label: RIESGOS[key].NAME
        }));
        const defaultRiesgosOptions = Object.keys(RIESGOS)?.map(peligro => {
            if (this.state.nuevaOT?.riesgos?.includes(peligro))
                return {
                    value: peligro,
                    label: RIESGOS[peligro].NAME
                }
        });

        const equipoIdElegido = Array.isArray(this.state.nuevaOT?.equipos)
            ? this.state.nuevaOT?.equipos?.[0]
            : this.state.nuevaOT?.equipos;


        const equipoCOMPLETO = listadoDeEquipos?.find(e => e.id === equipoIdElegido);

        const candadosAsignadosAPuntos = listadoDeEquipos
        ?.flatMap(equipo => equipo?.equiposAbloquear
          ?.flatMap(equipoAbloquear => equipoAbloquear.puntosDeBloqueo
            ?.map(punto => punto.candadoId)
            .filter(candadoId => candadoId != null) // Filtra los valores null o undefined
          )
        )
        return (
            <>
                <SitioDialogs
                    handleDialogClose={this.handleDialogCloseSitio}
                    openDialog={this.state.openDialogSitio}
                    sitioEditar={{}}
                    reloadWindow={false}
                />
                <UsuarioDialogs
                    handleDialogClose={this.handleDialogCloseUsuario}
                    openDialog={this.state.openDialogUsuario}
                    usuarioEditar={{}}
                    reloadWindow={false}
                />
                <EquipoDialogs
                    handleDialogClose={this.handleDialogCloseEquipo}
                    openDialog={this.state.openDialogEquipo}
                    equipoEditar={{}}
                    reloadWindow={false}
                />
                <EmpresaDeMantenimientoDialogs
                    handleDialogClose={this.handleDialogCloseEmpresaDeMantenimiento}
                    openDialog={this.state.openDialogEmpresaDeMantenimiento}
                    empresaDeMantenimientoEditar={{}}
                    reloadWindow={false}
                />
                <ConfirmacionDialog
                    open={this.state.showConfirmacionDialog}
                    onClose={this.cerrarConfirmacionDialog}
                    onConfirm={this.state.dialogAction}
                    titulo={this.state.titleDialogConfirm}
                    mensaje={this.state.textDialogConfirm}
                />
                <Dialog open={openDialog} onClose={handleDialogClose}  >
                    <DialogTitle>Permiso de Trabajo</DialogTitle>
                    <DialogContent>
                        <FormWizard
                            color="#8a00e5"
                            onComplete={this.handleSubmit}
                        >
                            <FormWizard.TabContent title="Primer Paso" icon="ti-check">
                                <h3>Información General</h3>
                                <TextField
                                    color="secondary"
                                    autoFocus
                                    margin="dense"
                                    name="nombre"
                                    label="Nombre del Permiso de Trabajo"
                                    type="text"
                                    fullWidth
                                    onChange={this.handleInputChange}
                                    value={this.state.nuevaOT.nombre !== "" ? this.state.nuevaOT.nombre : otEditar.nombre}
                                />
                                <TextField
                                    color="secondary"
                                    autoFocus
                                    margin="dense"
                                    name="descripcion"
                                    label="Descripción del Permiso de Trabajo"
                                    type="text"
                                    fullWidth
                                    onChange={this.handleInputChange}
                                    value={this.state.nuevaOT.descripcion !== "" ? this.state.nuevaOT.descripcion : otEditar.descripcion}
                                />
                                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                    <Select
                                        defaultValue={empresasDeMantenimientoOptions?.find(option => option.value === ( this.state.nuevaOT.nombreEmpresaDeMantenimiento !== 0 ? this.state.nuevaOT.nombreEmpresaDeMantenimiento : otEditar.nombreEmpresaDeMantenimiento ))}
                                        onChange={selectedOption => this.handleSelectChange('nombreEmpresaDeMantenimiento', selectedOption)}
                                        options={empresasDeMantenimientoOptions}
                                        placeholder="Elegir una empresa de mantenimiento"
                                        className="react-select-container flex-grow-1"
                                        classNamePrefix="react-select-menu"
                                        theme={(theme) => ({
                                            ...theme,
                                            colors: {
                                                ...theme.colors,
                                                primary: 'BlueViolet',
                                            },
                                        })}
                                        styles={{
                                            control: (base) => ({
                                                ...base,
                                                backgroundColor: 'white', //quitar transparencia
                                                opacity: "1 !important"
                                            }),
                                        }}
                                    />
                                    <button
                                        style={{
                                            padding: '10px',
                                            fontSize: '1rem',
                                            lineHeight: '1',
                                            borderRadius: '5px',
                                            cursor: 'pointer'
                                        }}
                                        className='btns btns-primario btns'
                                        onClick={ () => this.setState({openDialogEmpresaDeMantenimiento: true}) } // Asegúrate de que esta función maneje la acción del botón correctamente
                                    >
                                        +
                                    </button>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                    <Select
                                        defaultValue={sitioOptions?.find(option => option.value === ( this.state.nuevaOT.sitioId !== 0 ? this.state.nuevaOT.sitioId : otEditar.sitioId ))}
                                        onChange={selectedOption => this.handleSelectChange('sitioId', selectedOption)}
                                        options={sitioOptions}
                                        placeholder="Elegir un sitio"
                                        className="react-select-container flex-grow-1"
                                        classNamePrefix="react-select-menu"
                                        theme={(theme) => ({
                                            ...theme,
                                            colors: {
                                                ...theme.colors,
                                                primary: 'BlueViolet',
                                            },
                                        })}
                                        styles={{
                                            control: (base) => ({
                                                ...base,
                                                backgroundColor: 'white', //quitar transparencia
                                                opacity: "1 !important"
                                            }),
                                        }}
                                    />
                                    <button
                                        style={{
                                            padding: '10px',
                                            fontSize: '1rem',
                                            lineHeight: '1',
                                            borderRadius: '5px',
                                            cursor: 'pointer'
                                        }}
                                        className='btns btns-primario btns'
                                        onClick={this.handleCreateSitio} // Asegúrate de que esta función maneje la acción del botón correctamente
                                    >
                                        +
                                    </button>
                                </div>
                            </FormWizard.TabContent>
                            <FormWizard.TabContent title="Segundo Paso" icon="ti-check">
                                <h3>Detalle del Problema y Cronograma</h3>
                                <TextField
                                    color="secondary"
                                    autoFocus
                                    margin="dense"
                                    name="sintoma"
                                    label="Sintoma"
                                    type="text"
                                    fullWidth
                                    onChange={this.handleInputChange}
                                    value={this.state.nuevaOT.sintoma !== "" ? this.state.nuevaOT.sintoma : otEditar.sintoma}
                                />
                                <TextField
                                    color="secondary"
                                    autoFocus
                                    margin="dense"
                                    name="causa"
                                    label="Causa"
                                    type="text"
                                    fullWidth
                                    onChange={this.handleInputChange}
                                    value={this.state.nuevaOT.causa !== "" ? this.state.nuevaOT.causa : otEditar.causa}
                                />
                                <TextField
                                    color="secondary"
                                    margin="dense"
                                    name="fechaInicio"
                                    label="Fecha de Inicio"
                                    type="date"
                                    fullWidth
                                    onChange={this.handleInputChange}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    //value={this.state.nuevaOT.fechaInicio.split('T')[0]}
                                    value={this.state.nuevaOT.fechaInicio !== otInicial.fechaInicio ? this.state.nuevaOT.fechaInicio : otEditar.fechaInicio }
                                />
                                <TextField
                                    color="secondary"
                                    margin="dense"
                                    name="fechaFin"
                                    label="Fecha de Fin"
                                    type="date"
                                    fullWidth
                                    onChange={this.handleInputChange}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    //value={this.state.nuevaOT.fechaFin.split('T')[0]}
                                    value={this.state.nuevaOT.fechaFin !== otInicial.fechaFin ? this.state.nuevaOT.fechaFin : otEditar.fechaFin }
                                />
                            </FormWizard.TabContent>
                            <FormWizard.TabContent title="Tercer paso" icon="ti-check">
                                <h3>Identificación de Riesgos y Responsables</h3>
                                {/* <TextField
                                    color="secondary"
                                    autoFocus
                                    margin="dense"
                                    name="riesgos"
                                    label="Descripcion de los riesgos"
                                    type="text"
                                    fullWidth
                                    onChange={this.handleInputChange}
                                    defaultValue={otEditar.riesgos}
                                /> */}
                                <Select
                                    defaultValue={defaultRiesgosOptions}
                                    onChange={selectedOption => this.handleSelectChange('riesgos', selectedOption, true)}
                                    options={riesgosOptions}
                                    isSearchable={false}
                                    className="react-select-container"
                                    placeholder="Elegir Riesgos"
                                    classNamePrefix="react-select-menu"
                                    isMulti
                                    theme={(theme) => ({
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            primary: 'BlueViolet',
                                        },
                                    })}
                                    styles={{
                                        control: (base) => ({
                                            ...base,
                                            backgroundColor: 'white',    //sacar transparencia
                                            opacity: " 1 !important"
                                        }),
                                    }}
                                />
                                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                    <Select
                                        defaultValue={JSON.stringify(this.state?.nuevaOT?.usuarios?.flatMap( usuarioId => usuarioOptions?.filter( datoUsuario => datoUsuario.value === usuarioId ) )) !== JSON.stringify(otInicial.usuarios) ? this.state?.nuevaOT?.usuarios?.flatMap( usuarioId => usuarioOptions?.filter( datoUsuario => datoUsuario.value === usuarioId ) ) : defaultUsuarioOptions}
                                        onChange={selectedOption => this.handleSelectChange('usuarios', selectedOption, true)}
                                        options={usuarioOptions}
                                        isMulti
                                        isClearable
                                        isSearchable={true}
                                        placeholder="Elegir usuarios"
                                        className="react-select-container flex-grow-1"
                                        classNamePrefix="react-select-menu"
                                        theme={(theme) => ({
                                            ...theme,
                                            colors: {
                                                ...theme.colors,
                                                primary: 'BlueViolet',
                                            },
                                        })}
                                        styles={{
                                            control: (base) => ({
                                                ...base,
                                                backgroundColor: 'white',
                                                opacity: "1 !important"
                                            }),
                                        }}
                                    />
                                    <button
                                        style={{
                                            padding: '10px',
                                            fontSize: '1rem',
                                            lineHeight: '1',
                                            borderRadius: '5px',
                                            cursor: 'pointer'
                                        }}
                                        className='btns btns-primario btns'
                                        onClick={() => this.setState({ openDialogUsuario: true })} // Asegúrate de que esta función maneje la acción del botón correctamente
                                    >
                                        +
                                    </button>
                                </div>
                            </FormWizard.TabContent>
                            <FormWizard.TabContent title="Cuarto paso" icon="ti-check">
                                <h3>Asignación de Equipos y Candados</h3>
                                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                    <Select
                                        defaultValue={defaultEquipoOptions} // Asegúrate de que defaultEquipoOptions esté formateado correctamente
                                        onChange={selectedOption => this.handleSelectChange('equipos', selectedOption)}
                                        options={equipoOptions} // Asegúrate de que equipoOptions esté formateado correctamente
                                        // isMulti
                                        required                                        
                                        isSearchable={true} // Establece esto en true para permitir la búsqueda y la entrada de texto
                                        placeholder="Elegir equipo"
                                        className="react-select-container flex-grow-1"
                                        classNamePrefix="react-select-menu"
                                        theme={(theme) => ({
                                            ...theme,
                                            colors: {
                                                ...theme.colors,
                                                primary: 'BlueViolet',
                                            },
                                        })}
                                        styles={{
                                            control: (base) => ({
                                                ...base,
                                                backgroundColor: 'white',
                                                opacity: "1 !important"
                                            }),
                                        }}
                                    />
                                    <button
                                        style={{
                                            padding: '10px',
                                            fontSize: '1rem',
                                            lineHeight: '1',
                                            borderRadius: '5px',
                                            cursor: 'pointer'
                                        }}
                                        className='btns btns-primario btns'
                                        onClick={() => this.setState({ openDialogEquipo: true })} // Cambia esto según la acción que debe realizar el botón
                                    >
                                        +
                                    </button>
                                </div>
                                {
                                    // this.state.nuevaOT?.equipos?.map(equipoId => {
                                    //     const equipo = listadoDeEquipos?.find(equipo => equipo.id === this.state.nuevaOT?.equipos);
                                    //     const candadosAsignadosAPuntos = listadoDeEquipos
                                    //         ?.map(equipo =>
                                    //             equipo.equiposAbloquear
                                    //                 ?.map(equipoAbloquear =>
                                    //                     equipoAbloquear.puntosDeBloqueo
                                    //                         ?.map(punto => punto.candadoId)
                                    //                 )
                                    //                 .flat()
                                    //         )
                                    //         .flat();
                                    //     return (
                                    <Card key={equipoIdElegido} sx={{ margin: 2, overflow: 'visible', border: 'none', boxShadow: 'none' }}>
                                        <CardContent>
                                            {/* ...código para renderizar la información del equipo */}
                                            <Typography variant="h5" gutterBottom>
                                                Equipo a intervenir: <span className="text-secundario">{equipoCOMPLETO?.nombre}</span>
                                            </Typography>


                                            {equipoCOMPLETO?.equiposAbloquear?.map((equipoAbloquear, index) => (
                                                <Box key={index} sx={{ mt: 4 }}>
                                                    <Typography variant="subtitle1" gutterBottom>
                                                        Equipo a bloquear <span className="text-secundario">{equipoAbloquear.nombre}</span>
                                                    </Typography>
                                                    {/* ...código para renderizar la información del equipo a bloquear */}
                                                    {equipoAbloquear.puntosDeBloqueo?.map((punto, indexPunto) => {
                                                        const candado = listadoDeCandados.filter(candado => candado.id === punto.candadoId)?.[0];
                                                        return (
                                                            <Grid container key={indexPunto} spacing={2} alignItems="center" justifyContent="center">
                                                                <Grid item xs={12} sm={4}>
                                                                    <Typography sx={{ textAlign: 'right', pr: 2 }}>Punto de bloqueo: <span className="text-secundario font-weight-bold">{punto.nombre}</span></Typography>
                                                                </Grid>
                                                                <Grid item xs={12} sm={4}>
                                                                    <FormControl fullWidth variant="outlined" size="small">
                                                                        <InputLabel id={`select-candado-label-${punto.id}`}>Candado</InputLabel>
                                                                        <MuiSelect
                                                                            labelId={`select-candado-label-${punto.id}`}
                                                                            id={`select-candado-${punto.id}`}
                                                                            value={punto.candadoId || this.state?.candadosAsignados?.[punto.id] || ''}
                                                                            label="Candado"
                                                                            onChange={(e) => this.handleSelectChangeCandado(punto.id, e)}
                                                                        >
                                                                            {candadoDefaultOptions.map((option, indexCandado) => (
                                                                                (punto.candadoId === option.value || !candadosAsignadosAPuntos.includes(option.value)) &&
                                                                                <MenuItem key={indexCandado} value={option.value}>
                                                                                    {option.label}
                                                                                </MenuItem>
                                                                            ))}
                                                                        </MuiSelect>
                                                                    </FormControl>
                                                                </Grid>
                                                                <Grid item xs={12} sm={4}>
                                                                    {!punto.candadoId && <Button onClick={() => this.asignarCandado(punto.id)} variant="outlined" size="small">
                                                                        Asignar
                                                                    </Button>}
                                                                    {punto.candadoId && <Button onClick={() => this.asignarCandado(punto.id, true, punto.candadoId)} variant="outlined" size="small">
                                                                        Eliminar
                                                                    </Button>}
                                                                    {this.puedeIniciarCerrado(candado) && punto.candadoId && <button className="btns btns-link text-muted mr-0" type="button" onClick={() => this.abrirConfirmacionDialog(candado)}>
                                                                        <a data-tip data-for='bluetooth'>
                                                                            <span className="material-icons material-icons-outlined txt-24">bluetooth</span>
                                                                        </a>
                                                                        <ReactTooltip place="bottom" id='bluetooth' aria-haspopup='false' >
                                                                            <span>Abrir candado</span>
                                                                        </ReactTooltip>
                                                                    </button>}
                                                                </Grid>
                                                            </Grid>
                                                        );
                                                    })}
                                                </Box>
                                            ))}
                                        </CardContent>
                                    </Card>
                                    //     )
                                    // })
                                }
                            </FormWizard.TabContent>
                        </FormWizard>
                        <style>{`
                    @import url("https://cdn.jsdelivr.net/gh/lykmapipo/themify-icons@0.1.2/css/themify-icons.css");
                `}</style>
                    </DialogContent>
                    <div className="alerta text-danger">{this.state.alertaDeError}</div>
                    <DialogActions>
                        <Button onClick={handleDialogClose} color="primary">
                            Cancelar
                        </Button>
                        {!(otEditar.id === undefined) && this.soyAdmin() && <Button onClick={() => this.openConfirmDialog(otEditar.id)} color="secondary" variant="outlined">
                            {otEditar?.deleted ? "Eliminar" :  "Cerrar"}
                        </Button>}
                        {!(otEditar.id === undefined) && !this.soyAdmin() && !otEditar?.deleted && <Button onClick={() => this.openConfirmDialog(otEditar.id)} color="secondary" variant="outlined">
                            Cerrar
                        </Button>}
                    </DialogActions>
                </Dialog>
                <Dialog open={this.state.confirmDialogOpen} onClose={this.closeConfirmDialog}>
                    <DialogTitle>Confirmar {this.soyAdmin() ? "Eliminación" : "Cerrar"}</DialogTitle>
                    <DialogContent>
                        ¿Estás seguro que deseas {this.soyAdmin() ? "eliminar" : "cerrar"} este Permiso de Trabajo?
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.closeConfirmDialog} color="primary">
                            Cancelar
                        </Button>
                        <Button onClick={this.handleDeleteConfirmation} color="secondary">
                            {this.soyAdmin() ? "Eliminar" : "Cerrar"}
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    }
}

export default OTdialogs;