import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Select from 'react-select';
import { userService } from '../../_services';
import { RIESGOS } from '../../_constants/riesgos.constrans'
import { history } from '../../_helpers';
import { alertActions, userActions } from '../../_actions';
import { connect } from 'react-redux';
const usuarioInicial = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    roles: [],
    camposVacios: [],
    alertaDeError: ""
}

class UsuarioDialogs extends React.Component {

    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
    }

    state = {
        confirmDialogOpen: false,
        nuevoUsuario: usuarioInicial,
        usuarioDelete: null
    }

    handleInputChange = (event) => {
        const { name, value } = event.target;
        this.setState(prevState => ({
            nuevoUsuario: {
                ...prevState.nuevoUsuario,
                [name]: value
            },
            camposVacios: prevState.camposVacios?.filter((campo) => campo !== name),
        }));
    }
    closeConfirmDialog = () => {
        this.setState({
            confirmDialogOpen: false,
        });
    }
    handleDeleteConfirmation = () => {
        console.log("Eliminando", this.state);
        userService.eliminarUsuario(this.state.usuarioDelete)
            .then(() => {
                this.props.mensajeExito("Usuario eliminado correctamente");
                setTimeout(() => {
                    window.location.reload()
                }, 1000);
            })
            .catch(error => {
                this.props.mensajeError(error);
            });
        this.closeConfirmDialog();
        this.props.handleDialogClose();
    }

    handleSubmit = () => {
        const { nuevoUsuario } = this.state;
        const camposVacios = [];

        if (nuevoUsuario.roles.length === 0) camposVacios.push("Rol");
        if (nuevoUsuario.firstName === "") camposVacios.push("Nombre");
        if (nuevoUsuario.lastName === "") camposVacios.push("Apellido");
        if (nuevoUsuario.email === "") camposVacios.push("Email");
        if (nuevoUsuario.password === "") camposVacios.push("contraseña");

        this.setState({ camposVacios });

        if (camposVacios.length > 0) {
            const alertaError = `Los campos ${camposVacios.join(", ")} son requeridos`;
            this.setState({ alertaDeError: alertaError });
            return;
        }

        const reloadWindow = this.props.reloadWindow === undefined ? true : false;
        this.props.usuarioEditar.id
            ? userService.editarUsuario(this.state.nuevoUsuario, this.props.usuarioEditar.id)
                .then(() => {
                    this.props.mensajeExito("Usuario Actualizado correctamente");
                    setTimeout(() => {
                        window.location.reload()
                    }, 1000);
                })
                .catch(error => {
                    this.props.mensajeError(error);
                })
            : userService.crear(this.state.nuevoUsuario)
                .then(() => {
                    this.props.mensajeExito("Usuario creado correctamente");
                    setTimeout(() => {
                        reloadWindow ? window.location.reload() : this.props.traerUsuarios();
                    }, 1000);
                })
                .catch(error => {
                    this.props.mensajeError(error);
                });
        this.props.handleDialogClose();
    }

    openConfirmDialog = (usuarioId) => {
        this.setState({
            confirmDialogOpen: true,
            usuarioDelete: usuarioId
        });
    }

    handleSelectChange = (name, selectedOption, isMulti = false) => {
        const selectedValues = isMulti
            ? selectedOption ? selectedOption.map(option => option.value) : []
            : selectedOption ? selectedOption.value : null;
        console.log('handleSelectChange:', name, selectedValues);  // Agrega esta línea
        this.setState(prevState => ({
            nuevoUsuario: {
                ...prevState.nuevoUsuario,
                [name]: selectedValues
            }
        }));
    }

    componentDidUpdate(prevProps) {
        if (this.props.usuarioEditar !== prevProps.usuarioEditar) {
            this.setState({
                nuevoUsuario: {
                    ...this.state.nuevoUsuario,
                    ...this.props.usuarioEditar
                }
            });
        }
    }

    inputStyle = (campo) => ({
        borderColor: this.state.camposVacios?.includes(campo) ? "red" : "",
      });


    render() {

        const { usuarioEditar, handleDialogClose, openDialog } = this.props; // datos de la ot que estan en el listado

        const roleOptions = [
            { label: "ADMIN", value: "ADMIN" },
            { label: "USER", value: "USER" },
            { label: "LM", value: "LM" },
            { label: "RDE", value: "RDE" },
            { label: "EJECUTANTE", value: "EJECUTANTE" },
        ]
        //[ADMIN, USER, LM, RDE, EJECUTANTE]

        const usuario = this.props.listadoDeUsuarios?.filter(usuario => usuario.id === usuarioEditar.id) || [];

        const defaultRoles = usuario[0]?.roles?.map(rol => {
            return {
                value: rol,
                label: rol
            }
        });

        return (
            <>
                <Dialog open={openDialog} onClose={handleDialogClose}>
                    <DialogTitle>Usuario</DialogTitle>
                    <DialogContent>
                        <TextField
                            color="secondary"
                            autoFocus
                            margin="dense"
                            name="firstName"
                            label="Nombre del Usuario"
                            type="text"
                            fullWidth
                            required
                            onChange={this.handleInputChange}
                            defaultValue={usuarioEditar.firstName}
                            />
                        <TextField
                            color="secondary"
                            autoFocus
                            margin="dense"
                            name="lastName"
                            label="Apellido del usuario"
                            type="text"
                            fullWidth
                            required
                            onChange={this.handleInputChange}
                            defaultValue={usuarioEditar.lastName}
                        />
                        <TextField
                            color="secondary"
                            autoFocus
                            margin="dense"
                            name="email"
                            label="Email del usuario"
                            type="text"
                            fullWidth
                            required
                            onChange={this.handleInputChange}
                            defaultValue={usuarioEditar.email}
                        />
                        <TextField
                            color="secondary"
                            autoFocus
                            margin="dense"
                            name="password"
                            label="Contraseña del usuario"
                            type="password"
                            fullWidth
                            required
                            onChange={this.handleInputChange}
                            defaultValue={usuarioEditar.password}
                        />
                        <Select
                            defaultValue={defaultRoles}
                            onChange={selectedOption => this.handleSelectChange('roles', selectedOption, true)}
                            options={roleOptions}
                            isSearchable={false}
                            className="react-select-container"
                            placeholder="Elegir Rol"
                            classNamePrefix="react-select-menu"
                            isMulti
                            required
                            theme={(theme) => ({
                                ...theme,
                                colors: {
                                    ...theme.colors,
                                    primary: 'BlueViolet',
                                },
                            })}
                            styles={{
                                control: (base) => ({
                                    ...base,
                                    backgroundColor: 'white',    //sacar transparencia
                                    opacity: " 1 !important"
                                }),
                            }}
                        />
                        <p className="text-danger">{this.state.alertaDeError}</p>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleDialogClose} color="primary">
                            <><span className="text-muted">Cancelar</span></>
                        </Button>
                        <Button onClick={this.handleSubmit} variant="contained" color="secondary" >
                            {usuarioEditar.id === undefined ? <><span className="text-white">Agregar</span></> : <><span className="text-white">Actualizar</span></>}
                        </Button>
                        {!(usuarioEditar.id === undefined) && <Button onClick={() => this.openConfirmDialog(usuarioEditar.id)} color="secondary" variant="outlined">
                            <><span className="text-secondary">Eliminar</span></>
                        </Button>}
                    </DialogActions>
                </Dialog>
                <Dialog open={this.state.confirmDialogOpen} onClose={this.closeConfirmDialog}>
                    <DialogTitle>Confirmar Eliminación</DialogTitle>
                    <DialogContent>
                        ¿Estás seguro de que deseas eliminar este usuario?
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.closeConfirmDialog} color="primary">
                            Cancelar
                        </Button>
                        <Button onClick={this.handleDeleteConfirmation} color="secondary">
                            Eliminar
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    }
}

export default UsuarioDialogs;

function mapState(state) {

    const { user } = state.authentication;
    const listadoDeUsuarios = state.users.listado;
    const listadoDeOrdenesDeTrabajo = state.ot.listado;
    return { user, listadoDeUsuarios, listadoDeOrdenesDeTrabajo };
}

const actionCreators = {
    mensajeError: alertActions.enviarError, // Asegúrate de que 'enviarError' exista en 'alertActions'
    mensajeExito: alertActions.enviarExito,
    traerUsuarios: userActions.traerUsuarios,
}

const connectedUsuarioDialogs = connect(mapState, actionCreators)(UsuarioDialogs);
export { connectedUsuarioDialogs as UsuarioDialogs };
