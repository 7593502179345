export const RIESGOS = {
    ENERGIA_ELECTRICA: {
        NAME: 'ENERGIA ELECTRICA',
        DESCRIPTION: 'electricidad, alta/media/baja tensión',
        EPP: ['guantes dieléctricos', 'calzado dieléctrico', 'protección facial', 'ropa antiarco']
    },
    ENERGIA_NEUMATICA: {
        NAME: 'ENERGIA NEUMÁTICA',
        DESCRIPTION: 'aire comprimido',
        EPP: ['gafas de seguridad', 'protectores auditivos', 'guantes de seguridad']
    },
    ENERGIA_HIDRAULICA: {
        NAME: 'ENERGIA HIDRAULICA',
        DESCRIPTION: 'aceite, sistemas de lubricación',
        EPP: ['guantes de nitrilo', 'gafas de seguridad', 'delantal de seguridad']
    },
    ENERGIA_MECANICA: {
        NAME: 'ENERGIA MECANICA',
        DESCRIPTION: 'equipos rotativos, alternativos, movimiento mecánico',
        EPP: ['protectores auditivos', 'gafas de seguridad', 'calzado de seguridad']
    },
    ENERGIA_QUIMICA: {
        NAME: 'ENERGIA QUIMICA',
        DESCRIPTION: 'inflamables, combustibles líquidos y gaseosos',
        EPP: ['guantes químicos', 'gafas de seguridad', 'respiradores']
    },
    ENERGIA_TERMICA: {
        NAME: 'ENERGIA TERMICA',
        DESCRIPTION: 'vapor, alta presión, alta temperatura',
        EPP: ['guantes térmicos', 'protección facial', 'delantal térmico']
    },
    AGUA_INDUSTRIAL: {
        NAME: 'AGUA INDUSTRIAL',
        DESCRIPTION: 'agua de refrigeración, con contenido de químicos',
        EPP: ['guantes impermeables', 'gafas de protección', 'bata de laboratorio']
    },
    SISTEMA_DETECCION_INCENDIO: {
        NAME: 'SISTEMA DETECCIÓN INCENDIO',
        DESCRIPTION: 'se deja sin protección contra incendios a la planta',
        EPP: ['extintor', 'manta ignífuga', 'detector de humo']
    },
    SUSTANCIAS_TOXICAS: {
        NAME: 'SUSTANCIAS TÓXICAS',
        DESCRIPTION: '...',
        EPP: ['respirador de cartucho', 'guantes químicos', 'gafas de seguridad']
    }
};
