export function tengoRol(roles) {
    let tengoRol=false;

    let user = JSON.parse(localStorage.getItem('user'));
    if(user!==undefined && user.roles)tengoRol = user.roles.some(rol=>roles.includes(rol));

    return tengoRol;
} 

export const traerMisRoles = () => {   

    let user = JSON.parse(localStorage.getItem('user'));
    if(user!==undefined && user.roles)return user.roles;
    return [];
} 

export const traerListarolesOrdenadosPrioridad = (orden) => { 
    let lista = [];
    console.log('orden',orden);
    Object.keys(Role).forEach(function(key) {
        //TODO por ahora saco al admin ya que este va a ser parte de una cadena especial de desbloqueo, dsp vemos como lo manejamos
        if (key !== 'ADMIN')
            lista.push(Role[key]);
    });

    if(orden === 'ASC'){
        return lista.sort((a, b) => a.prioridad < b.prioridad ? 1 : -1).map(it=> it.name);
    }else{
        return lista.sort((a, b) => a.prioridad > b.prioridad ? 1 : -1).map(it=> it.name);
    }
}

export const Role = {
    ADMIN: {name: 'ADMIN', prioridad: 1000},
    LM: {name: 'LM', prioridad: 1000},
    RDE: {name: 'RDE', prioridad: 500},
    EJECUTANTE: {name: 'EJECUTANTE', prioridad: 100}
}

export const ERR = {
    REQ: 'Requerido',
    NUM: "Solo números, decimales separados por '.'",
    MIN: 'Minimo',
    MAX: 'Máximo'
}

export const Estado = {
    ABIERTO: 1,
    CERRADO:2
}

export const TIPO_EXPORT = {
    CANDADO: 1,
    SITIO:2,
    USUARIO:3
}

export const TIPO_ACCION = {
    APLICAR_CERRADO: 'APLICAR_CERRADO',
    APLICAR_BLOQUEO_DIGITAL:'APLICAR_BLOQUEO_DIGITAL',
    ELIMINAR_BLOQUEO_DIGITAL:'ELIMINAR_BLOQUEO_DIGITAL',
    APLICAR_ABIERTO:'APLICAR_ABIERTO',
    ELIMINAR_TODOS_BLOQUEOS_DIGITALES:'ELIMINAR_TODOS_BLOQUEOS_DIGITALES'
}

