import React from 'react';
import { Route, Redirect } from 'react-router-dom';

export const PrivateRoute = ({ component: Component, roles, ...rest }) => (
    <Route {...rest} render={props => {
        let currentUser = JSON.parse(localStorage.getItem('user'));   
        if (!currentUser || !currentUser.roles) {
            return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
        }        
            return <Component {...props} />           
    }} />
)